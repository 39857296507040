import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, Collapse, Button, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';


import './classes.css'
import GoalsActive from '../goals/activeGoals';
import Container from '../../atoms/container'
import Image from '../../atoms/image'
import Spacing from '../../atoms/spacing'
import Text from '../../atoms/text'
import UserCard from '../users/card'
import theme from '../../theme'
import logo from '../../../assets/logo.png'
import { useGoal } from '../../../hooks/goals'
import { useAuth } from '../../../hooks/auth'
import { IAppHeader, IGoal, IRoute, ITeam } from '../../../types';
import NewGoalTips from '../modals/newGoalTips';
import { VERY_PADDING } from '../../../utils/consts';

const AppHeader: FC<IAppHeader> = (params: IAppHeader) => {
    const settingsId = 'settings-button';
    const goalsId = 'goals-button';

    const navigate = useNavigate();
    const location = useLocation();
    const { myGoals } = useGoal();
    const { user, guardian, logout, position, privilege, supervisedTeams } = useAuth();

    const [anchorTeams, setAnchorTeams] = useState<null | HTMLElement>(null);
    const [isDetailTeamOpen, setIsDetailTeamOpen] = useState<boolean>(false);
    const [anchorGoals, setAnchorGoals] = useState<null | HTMLElement>(null);
    const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(null);
    const [activeGoals, setActiveGoals] = useState<number>(0);
    const [openTipsModal, setOpenTipsModal] = useState<boolean>(false);

    const isTeamsMenuOpen = Boolean(anchorTeams)
    const isGoalsMenuOpen = Boolean(anchorGoals)
    const isSettingsOpen = Boolean(anchorSettings)

    useEffect(() => {
        const auxActiveGoals: number = myGoals.filter((item: IGoal) => item.progress < 100).length;
        setActiveGoals(auxActiveGoals);
    }, [myGoals]);

    const handleTeamsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorTeams(event.currentTarget);
    };

    const handleGoalsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorGoals(event.currentTarget);
    };

    const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorSettings(event.currentTarget);
    };

    const handleTeamsMenuClose = () => {
        setAnchorTeams(null);
    };

    const handleGoalsMenuClose = () => {
        setAnchorGoals(null);
    };

    const handleSettingsMenuClose = () => {
        setAnchorSettings(null);
    };

    const createNewGoal = () => {
        navigate('/goals/new');
    }

    return (
        <AppBar color="transparent" position="fixed" elevation={0} sx={{ zIndex: 10 }}>
            <Toolbar style={{ backgroundColor: '#000', maxHeight: 35 }}>
                <Spacing left={10} />
                <Image
                    source={logo}
                    height={80}
                    width={80}
                    onClick={() => window.open('/', '_self')}
                    style={{ cursor: 'pointer' }} />

                <Spacing left={25} />

                <Container fluid sx={{ paddingTop: VERY_PADDING }}>
                    <Text color={theme.palette.background.paper}>Plano de Metas e Performance</Text>
                    <Container fluid flexEnd>
                        <Text size={9} thin color={theme.palette.background.paper} style={{ opacity: 0.9 }}>v{process.env.REACT_APP_VERSION}</Text>
                    </Container>
                </Container>

                <Box sx={{ flexGrow: 1 }} />

                {user && <UserCard fontColor={theme.palette.background.paper} user={user} position={position} />}

                <IconButton id={settingsId} color="secondary" style={{ backgroundColor: 'transparent' }} onClick={handleSettingsMenuOpen}>
                    <ArrowDropDownIcon style={{ fontSize: 19, color: theme.palette.background.paper }} />
                </IconButton>

                <Menu
                    id={goalsId}
                    anchorEl={anchorTeams}
                    anchorOrigin={{
                        vertical: anchorTeams && anchorTeams.hasAttribute('type') ? 'bottom' : 'bottom',
                        horizontal: anchorTeams && anchorTeams.hasAttribute('type') ? 'right' : 'left'
                    }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={isTeamsMenuOpen}
                    onClose={handleTeamsMenuClose}>
                    <Container width='auto' centered>
                        {supervisedTeams.map((item: ITeam, index: number) => {
                            return (
                                <Link key={index} className="app-header-button" to={`/teams/${item.id}`}>
                                    <MenuItem onClick={() => handleTeamsMenuClose()}>
                                        <Container fluid width='100%'>
                                            <Text className="app-header-button" medium size={12}>{item.description}</Text>
                                        </Container>
                                    </MenuItem>
                                </Link>
                            );
                        })}
                    </Container>
                </Menu>
                <Menu
                    id={goalsId}
                    anchorEl={anchorGoals}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={isGoalsMenuOpen}
                    onClose={handleGoalsMenuClose}>
                    <Container width='auto' centered>
                        <Link className="app-header-button" to="/goals">
                            <MenuItem onClick={() => handleGoalsMenuClose()}>
                                <Container fluid width='100%'>
                                    <Text className="app-header-button" medium size={12}>Objetivos gerais</Text>
                                </Container>
                            </MenuItem>
                        </Link>
                        <Link className="app-header-button" to="/my-goals">
                            <MenuItem onClick={() => handleGoalsMenuClose()}>
                                <Container fluid width='100%'>
                                    <Text className="app-header-button" medium size={12}>Meus objetivos</Text>
                                </Container>
                            </MenuItem>
                        </Link>
                    </Container>
                </Menu>
                <Menu
                    id={settingsId}
                    anchorEl={anchorSettings}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={isSettingsOpen}
                    onClose={handleSettingsMenuClose}>
                    <Container width='auto' centered>
                        <Link className="app-header-button" to="/profile">
                            <MenuItem onClick={() => handleSettingsMenuClose()}>
                                <Container fluid width='100%'>
                                    <Text className="app-header-button" medium size={12}>Meu perfil</Text>
                                </Container>
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={() => {
                            setOpenTipsModal(true);
                            handleSettingsMenuClose()
                        }}>
                            <Container fluid width='100%'>
                                <Text medium size={12}>Como montar seu PMP</Text>
                            </Container>
                        </MenuItem>
                        {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/users') >= 0 &&
                            <Link className="app-header-button" to="/users">
                                <MenuItem onClick={() => {
                                    handleSettingsMenuClose()
                                }}>
                                    <Container fluid width='100%'>
                                        <Text className="app-header-button" medium size={12}>Usuários</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals') >= 0 &&
                            <Link className="app-header-button" to="/my-goals">
                                <MenuItem onClick={() => {
                                    handleSettingsMenuClose()
                                }}>
                                    <Container fluid width='100%'>
                                        <Text className="app-header-button" medium size={12}>Meus objetivos</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        {(params.isAdmin) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams/:id') >= 0 &&
                            supervisedTeams.length == 1 &&
                            <Link className="app-header-button" to={`/teams/${supervisedTeams[0].id}`}>
                                <MenuItem onClick={() => handleSettingsMenuClose()}>
                                    <Container fluid width='100%'>
                                        <Text medium size={12}>Objetivos da equipe</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        {(params.isAdmin) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams/:id') >= 0 &&
                            supervisedTeams.length > 1 &&
                            <Container fluid width='100%'>
                                <MenuItem onClick={() => setIsDetailTeamOpen(!isDetailTeamOpen)}>

                                    <Container inline width='100%'>
                                        <Text medium size={12}>Objetivos da equipe</Text>
                                        <ArrowDropDownIcon />
                                    </Container>
                                </MenuItem>
                                <Collapse in={isDetailTeamOpen} timeout="auto" unmountOnExit>
                                    {supervisedTeams.map((item: ITeam, index: number) => {
                                        return (
                                            <Link key={index} className="app-header-button" to={`/teams/${item.id}`}>
                                                <MenuItem onClick={() => {
                                                    setIsDetailTeamOpen(false);
                                                    handleSettingsMenuClose();
                                                }}>
                                                    <Container fluid width='100%'>
                                                        <Text className="app-header-button" medium size={12}>{item.description}</Text>
                                                    </Container>
                                                </MenuItem>
                                            </Link>
                                        );
                                    })}
                                </Collapse>
                            </Container>}
                        {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/goals') >= 0 &&
                            <Link className="app-header-button" to="/goals">
                                <MenuItem onClick={() => handleSettingsMenuClose()}>
                                    <Container fluid width='100%'>
                                        <Text medium size={12}>Objetivos gerais</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams') >= 0 &&
                            <Link className="app-header-button" to="/teams">
                                <MenuItem onClick={() => handleSettingsMenuClose()}>
                                    <Container fluid width='100%'>
                                        <Text medium size={12}>Equipes</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/requests') >= 0 &&
                            <Link className="app-header-button" to="/requests">
                                <MenuItem onClick={() => handleSettingsMenuClose()}>
                                    <Container fluid width='100%'>
                                        <Text medium size={12}>Solicitações</Text>
                                    </Container>
                                </MenuItem>
                            </Link>}
                        <MenuItem onClick={() => {
                            logout();
                            navigate('/');
                        }}>
                            <Container fluid width='100%'>
                                <Text medium size={12}>Sair</Text>
                            </Container>
                        </MenuItem>
                    </Container>
                </Menu>
            </Toolbar>

            <Container inline centered padded height={10} color={theme.palette.gray.main}>
                <Container veryPadded>
                    {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals') >= 0 &&
                        <Button variant="text" color="primary">
                            <Link className="app-header-button" to="/" ><Text className="app-header-button" bold
                                color={location.pathname == '/' || location.pathname.indexOf('/my-goals') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Home</Text></Link>
                        </Button>}
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/users') >= 0 &&
                        <Button variant="text" color="primary">
                            <Link className="app-header-button" to="/users" ><Text className="app-header-button" bold
                                color={location.pathname.indexOf('/users') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Usuários</Text></Link>
                        </Button>
                    }
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/departments') >= 0 &&
                        <Button variant="text" color="primary">
                            <Link className="app-header-button" to="/departments" ><Text className="app-header-button" bold
                                color={location.pathname.indexOf('/departments') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Setores</Text></Link>
                        </Button>
                    }
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams/:id') >= 0 &&
                        <>
                            {supervisedTeams.length == 1 ?
                                <Button variant="text" color="primary">
                                    <Link className="app-header-button" to={`/teams/${supervisedTeams[0].id}`}>
                                        <Text className="app-header-button" bold color={location.pathname.indexOf('/teams/:id') > -1 ? theme.palette.primary.light : theme.palette.common.black}>
                                            Minha Equipe
                                        </Text>
                                    </Link>
                                </Button> :
                                supervisedTeams.length > 1 ?
                                <Button variant="text" color="primary" onClick={(event: React.MouseEvent<HTMLElement>) => handleTeamsMenuOpen(event)}
                                    endIcon={<ArrowDropDownIcon />}>
                                    <Text className="app-header-button" bold color={location.pathname.indexOf('/teams/:id') > -1 ? theme.palette.primary.light : theme.palette.common.black}>
                                        Minhas Equipes
                                    </Text>
                                </Button> : null
                            }
                        </>
                    }
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/goals') >= 0 &&
                        <Button variant="text" color="primary" onClick={(event: React.MouseEvent<HTMLElement>) => handleGoalsMenuOpen(event)}
                            endIcon={<ArrowDropDownIcon />}>
                            <Text className="app-header-button" bold color={location.pathname.indexOf('/goals') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Objetivos</Text>
                        </Button>}
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams') >= 0 &&
                        <Button variant="text" color="primary">
                            <Link className="app-header-button" to="/teams" ><Text className="app-header-button" bold
                                color={location.pathname.indexOf('/teams') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Equipes</Text></Link>
                        </Button>}
                    {(params.isAdmin || params.isHumanResources) && privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/requests') >= 0 &&
                        <Button variant="text" color="primary">
                            <Link className="app-header-button" to="/requests" ><Text className="app-header-button" bold
                                color={location.pathname.indexOf('/requests') > -1 ? theme.palette.primary.light : theme.palette.common.black}>Solicitações</Text></Link>
                        </Button>}
                </Container>

                <Box sx={{ flexGrow: 1 }} />
                <GoalsActive count={activeGoals} />
            </Container>

            <NewGoalTips open={openTipsModal} disabled={guardian == null} onClose={() => setOpenTipsModal(false)}
                onCreateNewGoal={createNewGoal} />
        </AppBar >
    )
}

export const AppHeaderToolbar = styled('div')(({ theme }) => {
    return ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar

        minHeight: 94
    })
})

export default AppHeader;
