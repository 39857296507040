import React, { FC, useEffect, useState } from 'react'
import { Box, Button, IconButton, SelectChangeEvent } from '@mui/material'
import { Check as CheckIcon, Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Confirmation, Container, Image, Information, Input, Page, Select, Spacing, Text, theme, List } from '../../components'
import { useAuth } from '../../hooks/auth';;
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { ISelectValue, IApprovalRequest, IListAction, IUser, IListHeader, ITeam, IGoalUser } from '../../types'
import { useApprovalsRequests } from '../../hooks/approvalsRequests'
import { isApprovalRequest } from '../../types/hooks/approvalsRequests'
import { downloadCSVFromJson } from '../../utils/converters'
import { normalizeString } from '../../utils/stringFormatters'

const filterStatusItems: ISelectValue[] = [
    { label: 'Edição', value: '0' },
    { label: 'Exclusão', value: '1' },
    { label: 'Todos', value: '2' }
];

const PER_ROWS = 15;

const ApprovalsRequests: FC = () => {
    const { user } = useAuth();
    const { requests, loading, fetchRequests, searchRequest, setSearchRequest, filterStatus, setFilterStatus, paginate, setPaginate, updateApprovalRequest } = useApprovalsRequests();

    const [informationModal, setInformationModal] = useState<boolean>(false);
    const [informationModalTitle, setInformationModalTitle] = useState<string>('');
    const [informationModalMessage, setInformationModalMessage] = useState<string>('');
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [confirmationTitle, setConfirmationTitle] = useState<string>('');
    const [confirmationMessage, setConfirmationMessage] = useState<string>('');
    const [selectedRequestId, setSelectedRequestId] = useState<string | undefined>(undefined);
    const [selectedRequestApproved, setSelectedRequestApproved] = useState<boolean | undefined>(undefined);

    const [filteredRequests, setFilteredRequests] = useState<IApprovalRequest[]>([]);

    useEffect(() => {
        fetchRequests();
    }, [])

    useEffect(() => {
        filterRequests()
    }, [requests])

    useEffect(() => {
        filterRequests(searchRequest);
    }, [filterStatus, paginate, searchRequest])

    useEffect(() => {
        if (!informationModal)
            setInformationModalMessage('');
    }, [informationModal])

    useEffect(() => {
        if (!confirmationModal) {
            setConfirmationTitle('');
            setConfirmationMessage('');
            setSelectedRequestId(undefined);
            setSelectedRequestApproved(undefined);
        }
    }, [confirmationModal])

    const filterRequests = (_search: string = '') => {
        setFilteredRequests([])
        let auxRequests = [...requests]

        setTimeout(() => {
            if (_search != '') {
                auxRequests = auxRequests.filter((item: IApprovalRequest) =>
                    (item.user && item.user.profile && item.user.profile.surname && normalizeString(item.user.profile.surname).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.user && item.user.position && normalizeString(item.user.position.description).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.goal && item.goal.name && normalizeString(item.goal.name).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (normalizeString(item.request).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredRequests([...auxRequests]);
        }, 0)
    }

    const perPageToNumber = () => {
        return PER_ROWS;
    }

    function getInitialIndexPaginate() {
        const perPage: number = perPageToNumber();
        return paginate * perPage;
    }

    function getFinalIndexPaginate() {
        const perPage: number = perPageToNumber();
        return ((paginate + 1) * perPage) + 1 >= requests.length ? requests.length : (paginate + 1) * perPage;
    }

    const convertDataToItem = (_data: IApprovalRequest) => {
        let surname: string = 'Desconhecido';
        let position: string = 'Desconhecido';
        let goalName: string = 'Desconhecido';
        let request: string = 'Desconhecido';
        let responsibleId: string | undefined = undefined;
        let approved: boolean | undefined = undefined;
        let typeDesc: string = 'Desconhecido';

        if (_data && _data.user && _data.user.profile && _data.user.profile.surname)
            surname = _data.user.profile.surname;
        if (_data && _data.user && _data.user.position)
            position = _data.user.position.description;
        if (_data && _data.goal && _data.goal.name)
            goalName = _data.goal.name;
        if (_data && _data.request)
            request = _data.request;
        if (_data && _data.responsibleId)
            responsibleId = _data.responsibleId;
        if (_data && _data.approved != undefined)
            approved = _data.approved;
        if (_data && _data.typeDesc != undefined)
            typeDesc = _data.typeDesc;

        return { surname, position, goalName, request, responsibleId, approved, typeDesc }
    }

    const headers: IListHeader[] = [
        { label: "Nome", fieldName: "surname", xs: 2, isString: true, sortable: true },
        { label: "Cargo", fieldName: "position", xs: 2, isString: true, sortable: true },
        { label: "Nome do objetivo", fieldName: "goalName", xs: 2, isString: true, sortable: true },
        { label: "Justificativa", fieldName: "request", xs: 3, isString: true, sortable: true },
        { label: 'Tipo Solicitação', fieldName: "typeDesc", xs: 2, isString: true, sortable: true },
        {
            label: "Ações",
            fieldName: "approved",
            xs: 1,
            isString: true,
            text: (_value: string | number | boolean | null) => {
                if (typeof _value === 'boolean') {
                    if (_value == true)
                        return 'Aprovada'
                    else return 'Recusada'
                }
                return 'Desconhecido'
            },
            fontColor: (_value: string | number | boolean | null) => {
                if (typeof _value === 'boolean') {
                    if (_value == true)
                        return theme.palette.success.main;
                    else return theme.palette.error.main;
                }
                return theme.palette.common.black;
            },
            showActionIfFieldIsNull: 'responsibleId',
            actions: (_id: string) => [
                <IconButton key={0} size="small" color="success" style={{ height: '18px', backgroundColor: 'transparent' }}
                    onClick={() => approvesRequest(_id)}>
                    <CheckIcon fontSize='small' />
                </IconButton>,
                <IconButton key={1} size="small" color="error" style={{ height: '18px', backgroundColor: 'transparent' }}
                    onClick={() => declinesRequest(_id)}>
                    <CloseIcon fontSize='small' />
                </IconButton>,
            ],
        },
    ];

    const approvesRequest = async (_id: string) => {
        if (user) {
            setConfirmationModal(true);
            setConfirmationTitle('Aprovar solicitação');
            setConfirmationMessage('Após aprovar a solicitação, o usuário receberá um email com o resultado e poderá modificar o objetivo desejado.');
            setSelectedRequestId(_id);
            setSelectedRequestApproved(true);
        }

    }

    const declinesRequest = async (_id: string) => {
        if (user) {
            setConfirmationModal(true);
            setConfirmationTitle('Negar solicitação');
            setConfirmationMessage('Após negar a solicitação, o usuário receberá um email com o resultado e manterá o status atual do objetivo.');
            setSelectedRequestId(_id);
            setSelectedRequestApproved(false);
        }
    }

    const onConfirmModalClick = () => {
        if (user && typeof selectedRequestId === 'string' && typeof selectedRequestApproved === 'boolean') {
            updateApprovalRequest(selectedRequestId, selectedRequestApproved, user.id)
            setConfirmationModal(false);
        }
    }

    const renderItem = (_data: IUser | IApprovalRequest | ITeam | IGoalUser) => {
        if (isApprovalRequest(_data)) {
            const auxRequest: IApprovalRequest = _data;

            return (
                <Container key={auxRequest.id} fluid color={theme.palette.grayLight.main} borderBottomRadius={PADDING} padded>
                    <Text size={15} bold>Justificativa:</Text>
                    <Text size={15}>{auxRequest.request}</Text>
                </Container>);
        }
        return null;
    }


    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>Solicitações de Edição e Exclusão</Text>
                    <Spacing top={10} />
                    <Text size={18} center>
                        Consulte e resolva as solicitações de edição/exclusão de objetivos e adição de membros em equipes. Você também pode conferir as solicitações ja resolvidas.
                    </Text>
                    <Text size={13}>Clique em uma justificativa para expandir a descrição.</Text>
                </Container>

                <Container inline color={theme.palette.tertiary.main}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Container inline horizontalCentered sx={{ paddingTop: PADDING / 2, paddingRight: VERY_PADDING, paddingBottom: PADDING / 2 }}>
                        <Text bold size={13} color={theme.palette.background.paper}>Exportar para:</Text>
                        <Spacing left={10} />
                        {/* <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}><Text bold size={13} color={theme.palette.background.paper}>PDF</Text></Button>
                        <Spacing left={5} /> */}
                        <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}
                            onClick={() => downloadCSVFromJson('solicitacoes.csv', requests)}><Text bold size={13} color={theme.palette.background.paper}>Excel</Text></Button>
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween flexStart width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchRequest"
                                autoFocus
                                value={searchRequest}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchRequest(event.target.value)}
                                placeHolder='Digite o nome ou cargo do usuário, nome do objetivo ou justificativa'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />
                        <Container inline horizontalCentered width="100%" flexEnd >
                            <Text bold size={16}>Filtros</Text>
                            <Spacing left={PADDING} />
                            <Select
                                value={filterStatus}
                                onChange={(event: SelectChangeEvent<string | number | null>) => setFilterStatus(event.target.value as string)}
                                items={filterStatusItems.map((item: ISelectValue) => ({
                                    label: item.label,
                                    value: item.value
                                }))}
                                backgroundColor={theme.palette.background.default}
                                sx={{ maxHeight: '38px' }} />
                        </Container>
                    </Container>

                    <List
                        headers={headers}
                        data={filteredRequests}
                        loading={loading}
                        total={requests.length}
                        perPage={PER_ROWS}
                        paginate={paginate}
                        onPreviousPage={() => setPaginate(paginate - 1)}
                        onNextPage={() => setPaginate(paginate + 1)}
                        dataToItem={(_data: IUser | IApprovalRequest | ITeam) => {
                            if (isApprovalRequest(_data))
                                return convertDataToItem(_data)
                            return undefined
                        }}
                        detail={renderItem}
                        onRefresh={() => fetchRequests()}
                    />
                </Container>
            </Container>

            <Information
                open={informationModal}
                title={informationModalTitle}
                message={informationModalMessage}
                onClose={() => setInformationModal(false)}
                onConfirm={() => setInformationModal(false)} />
            <Confirmation
                open={confirmationModal}
                title={confirmationTitle}
                message={confirmationMessage}
                onClose={() => setConfirmationModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setConfirmationModal(false)}
                onConfirm={() => onConfirmModalClick()}
                confirmLabel="Confirmar" />
        </Page >
    )
}

export default ApprovalsRequests;