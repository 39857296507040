import { AxiosResponse } from "axios";

import api from "./";
import { IRoute, IUser, IPosition, IPrivilege } from "../types";
import { IPasswordRecovery } from "../types/hooks/auth";

export const login = async (_email: string, _passw: string) => {
  const response: AxiosResponse = await api.post("/v1_0/auth/login", {
    email: _email,
    password: _passw,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const token = response.data.token;

      const user: IUser = {
        id: response.data.user.id,
        email: response.data.user.email,
        profile: {
          id: response.data.user.profile.id,
          firstName: response.data.user.profile.firstName,
          lastName: response.data.user.profile.lastName,
          surname: response.data.user.profile.surname,
          avatarUri: response.data.user.profile.avatarUri,
        },
        activeGoals: response.data.user.activeGoals,
        lastAccess: response.data.user.lastAccess,
        createdAt: response.data.user.createdAt,
        updatedAt: response.data.user.updatedAt,
      };

      return {
        token,
        user,
      };
    } else if (status == 401) {
      if ((response as any).response.data.error == "Email or password invalid")
        throw new Error("Email ou senha inválidos");
    }
  }

  throw new Error("Não foi possível efetuar o login");
};

export const getGuardian = async (_userId: string) => {
  const response: AxiosResponse = await api.get(
    `/v1_0/users/guardian/${_userId}`
  );

  if (response && response.request) {
    const status = response.request.status;

    if (status == 200 && response.data) {
      const user: IUser = {
        id: response.data.id,
        email: response.data.email,
        profile: {
          id: response.data.profile.id,
          firstName: response.data.profile.firstName,
          lastName: response.data.profile.lastName,
          surname: response.data.profile.surname,
          avatarUri: response.data.profile.avatarUri,
        },
        position: {
          id: response.data.position.id,
          departmentId: response.data.position.departmentId,
          description: response.data.position.description,
          departmentDescription: response.data.position.departmentDescription,
        },
        activeGoals: response.data.activeGoals,
        lastAccess: response.data.lastAccess,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };

      return user;
    }
  }

  throw new Error("Não foi possível econtrar o guardião");
};

export const getPrivilege = async (_userId: string) => {
  const response: AxiosResponse = await api.get(
    `/v1_0/privileges/by_user_id/${_userId}`
  );

  if (response && response.request) {
    const status = response.request.status;

    const routes: IRoute[] = [];
    if (response.data.routes) {
      for (let i = 0; i < response.data.routes.length; i++) {
        const route = response.data.routes[i];
        routes.push({
          id: route.id,
          path: route.path,
        });
      }
    }

    const privilege: IPrivilege = {
      id: response.data.id,
      description: response.data.description,
      routes,
    };

    return privilege;
  }

  throw new Error("Não foi possível encontrar o privilégio do usuário");
};

export const getPosition = async (_userId: string) => {
  const response: AxiosResponse = await api.get(
    `/v1_0/positions/by_user_id/${_userId}`
  );

  if (response && response.request) {
    const status = response.request.status;

    const position: IPosition = {
      id: response.data.id,
      description: response.data.description,
      departmentId: response.data.departmentId,
      departmentDescription: response.data.departmentDescription,
    };

    return position;
  }

  throw new Error("Não foi possível encontrar o setor do usuário");
};

export const checkPassword = async (_email: string, _password: string) => {
  const response: AxiosResponse = await api
    .post(`/v1_0/auth/verify_password`, {
      email: _email,
      password: _password,
    })
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.data) {
    return response.data;
  }

  return false;
};

export const updatePassword = async (
  _email: string,
  _currentPass: string,
  _newPass: string
) => {
  const response = await api
    .put(`/v1_0/auth/update_password`, {
      email: _email,
      oldPassword: _currentPass,
      newPassword: _newPass,
    })
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.data) {
    return true;
  }

  return false;
};

export const recoveryPassword = async (_email: string) => {
  const response = await api
    .post(`/v1_0/auth/forgot_password`, { email: _email })
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) return true;
    else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if (
        (response as any).response.data.error == "E-mail not found on database"
      )
        throw new Error("E-mail não encontrado na nossa base");
      throw new Error((response as any).response.data as string);
    }
  }

  return false;
};

export const fetchPasswordWithToken = async (_token: string) => {
  const response = await api
    .get(`/v1_0/auth/forgot_password/${_token}`)
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data: IPasswordRecovery = {
        id: (response as any).data.id,
        userId: (response as any).data.userId,
        token: (response as any).data.token,
        used: (response as any).data.used,
        createdAt: (response as any).data.createdAt,
        updatedAt: (response as any).data.updatedAt,
      };
      return data;
    }
  }

  return undefined;
};

export const updatePasswordWithToken = async (
  _token: string,
  _password: string
) => {
  const response = await api
    .put(`/v1_0/auth/forgot_password/${_token}`, { password: _password })
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) return true;
    else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if (
        (response as any).response.data.error == "E-mail not found on database"
      )
        throw new Error("E-mail não encontrado na nossa base");
      throw new Error((response as any).response.data as string);
    }
  }

  return false;
};
