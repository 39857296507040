import { AxiosResponse } from "axios";

import api from ".";

const baseUrl: string = "teams_has_supervisors";

export const createTeamHasSupervisor = async (
  _userId: string,
  _teamId: string
) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    userId: _userId,
    teamId: _teamId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error(
    "Não foi possível criar a vinculação de supervisor com equipe"
  );
};
