import React, { FC } from 'react'
import { Avatar } from '@mui/material';
import { AccountCircleOutlined as AccountIcon } from '@mui/icons-material';

import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import { IUserCardBasic } from '../../../../types/components/molecules/users';
import { API_URL } from '../../../../services';
import { PADDING } from '../../../../utils/consts';

const UserCard: FC<IUserCardBasic> = (params: IUserCardBasic) => {
    const avatarSize = params.avatarSize || 40;
    let name = params.user.surname ? params.user.surname.trim() : 'Desconhecido';
    if (name == 'Desconhecido' && params.user && params.user.firstName)
        name = params.user.firstName;

    return (
        <Container fluid={params.vertical} inline={!params.vertical} centered sx={{ minWidth: 100 }}>
            {params.user.avatarUri == '' &&
                <AccountIcon
                    style={{ fontSize: avatarSize, color: params.fontColor }} />}
            {params.user.avatarUri != '' &&
                <Avatar
                    src={`${API_URL}/v1_0/users_profiles/images/${params.user.avatarUri}`}
                    sx={{ width: avatarSize, height: avatarSize }} />}

            {!params.vertical && <Spacing left={PADDING} />}
            <Container fluid centered style={{ paddingTop: PADDING }}>
                {params.user && <Text bold size={13} center color={params.fontColor}>{name}</Text>}
            </Container>
        </Container>
    )
}

export default UserCard;