import React, { FC, useEffect, useState } from 'react'
import { Collapse, Grid } from '@mui/material'
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import moment from 'moment'

import '../classes.css'
import Container from '../../../atoms/container'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import theme from '../../../theme'
import { IListHeader, IListColumn, } from '../../../../types'
import { VERY_PADDING } from '../../../../utils/consts';

const ListItem: FC<IListColumn> = (params: IListColumn) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const fontSize: number = 13;    

    useEffect(() => {
        setIsOpen(false);
    }, [params.data])

    let borderColor = 'transparent';
    if (params.colorKey) {
        const colorKeyHeader = params.headers.find(item => item.fieldName == params.colorKey);
        if (colorKeyHeader && colorKeyHeader.fieldName && colorKeyHeader.fontColor)
            borderColor = colorKeyHeader.fontColor((params.data as any)[colorKeyHeader.fieldName]);
        if (borderColor == theme.palette.text.primary)
            borderColor = 'transparent';
    }

    return (
        <>
            <Grid className="user-grid-item" spacing={1} container direction="row" justifyContent="space-between" alignItems="center"
                style={{
                    height: 'auto',
                    marginTop: 5,
                    borderLeft: `solid 5px ${borderColor}`,
                    cursor: params.detail ? 'pointer' : 'default'
                }}>
                {params.headers.map((header: IListHeader, index: number) => {
                    let convertedItem = params.dataToItem(params.data);
                    if (convertedItem == undefined) return null;

                    if (header.actions && header.showActionIfFieldIsNull && convertedItem[header.showActionIfFieldIsNull] == undefined)
                        return (
                            <Grid key={index} item xs={header.xs} justifyContent="flex-end" alignItems="flex-end"

                                style={{ minHeight: '40px', paddingBottom: VERY_PADDING, borderBottom: `solid 1px ${theme.palette.grayLight.main}` }}>
                                <Container inline flexStart>
                                    {header.actions && header.actions(params.data.id).map(item => item)}
                                </Container>
                            </Grid>)

                    if (header.fieldName == null) return;
                    let value = (convertedItem as any)[header.fieldName]
                    let fontColor = theme.palette.text.primary;
                    if (header.fontColor)
                        fontColor = header.fontColor(value);
                    if (header.text)
                        value = header.text(value)

                    return (
                        <Grid key={index} item xs={header.xs} justifyContent="space-between" alignItems="center"
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ minHeight: '40px', paddingBottom: VERY_PADDING, borderBottom: `solid 1px ${theme.palette.grayLight.main}` }}>
                            <Container inline horizontalCentered>
                                {index == 0 && <ArrowRightIcon color="primary" sx={{ width: 19, height: 19, position: 'absolute' }} />}
                                {index == 0 && <Spacing left={VERY_PADDING} />}

                                {header.isString && typeof value == 'string' &&
                                    <Text color={fontColor} semiBold size={fontSize} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{value || ''}</Text>}
                                {header.isDate &&
                                    <Text color={fontColor} semiBold size={fontSize}>{moment(value).format("DD/MM/YY")}</Text>}
                                {header.isDateTime &&
                                    <Text color={fontColor} semiBold size={fontSize}>{moment(value).format("DD/MM/YY HH:mm:ss")}</Text>}
                                {header.isNumber &&
                                    <Text center color={fontColor} semiBold size={fontSize}>{value}</Text>}
                            </Container>
                        </Grid>
                    )
                })}
            </Grid>

            {params.detail && <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Container fluid width="100%" height="auto">
                    {params.detail(params.data)}
                </Container>
            </Collapse>}
        </>
    )
}

export default ListItem