// console.warn = () => { };
import React from 'react';
import { ThemeProvider } from '@mui/material/styles'
import { Toaster } from 'react-hot-toast'

import './App.css';
import Theme from './components/theme'
import { FeedbackProvider } from './hooks'
import { AuthProvider } from './hooks/auth'
import Routes from './routes'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <FeedbackProvider>
          <Routes />
        </FeedbackProvider>
      </AuthProvider>

      <Toaster />
    </ThemeProvider>
  )
}

export default App;
