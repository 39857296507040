import React, { FC, useEffect, useState } from 'react'
import { IconButton, SelectChangeEvent } from '@mui/material'
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

import '../classes.css'
import Container from '../../../atoms/container'
import Modal from '../../../atoms/modal'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import Input from '../../../atoms/input'
import { useDepartments } from '../../../../hooks/departments'
import theme from '../../../theme'
import { IDepartment, IDepartmentNew, IDepartmentNewModal } from '../../../../types'
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts'

const DepartmentsNewModal: FC<IDepartmentNewModal> = (params: IDepartmentNewModal) => {
    const isEdit = params.departmentToEdit != undefined;

    const { createNewDepartment, editDepartment, fetchDepartment } = useDepartments();

    const [description, setDescription] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (params.departmentToEdit) {
            setDescription(params.departmentToEdit.description);
        }
    }, [params.departmentToEdit]);

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
        setDescriptionError(null);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (description == '') {
            setDescriptionError('Insira o nome do setor');
            isValid = false;
        }

        if (isValid) {
            setDescriptionError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            if (isEdit && params.departmentToEdit) {
                const payload: IDepartmentNew = {
                    description
                }
                const editedDepartment: IDepartment = await editDepartment(params.departmentToEdit.id, payload);
            }
            else {
                const newDepartment: IDepartment = await createNewDepartment(description);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit)
                informationMessage = 'O novo setor foi cadastrado com sucesso.';

            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setDescription('');
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid color={theme.palette.background.default} hasShadow borderRadius={BORDER_RADIUS} centered
                sx={{ minWidth: '40%' }} >
                <Container inline color={theme.palette.grayLight.light} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container style={{ flexGrow: 1 }} />
                    <PersonAddAlt1Icon color="secondary" />
                    <Spacing left={PADDING} />
                    <Text size={16} bold color={theme.palette.primary.light}>{!isEdit ? 'Cadastrar novo setor' : 'Editar setor'}</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <IconButton size="small" style={{}} onClick={close}>
                        <CloseIcon fontSize="small" style={{ color: theme.palette.primary.light }} />
                    </IconButton>
                </Container>

                <Text size={16} semiBold>{!isEdit ? 'Insira os dados do novo setor' : 'Edite os dados do setor se precisar'}</Text>

                <Container className="department-new-modal ss-container" fluid spacedBetween centered veryPadded
                    width={'100%'}
                    height={'100%'}>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Nome</Text>
                            <Spacing top={5} />
                            <Input
                                id="description"
                                autoFocus
                                required
                                value={description}
                                onChange={handleChangeDescription}
                                placeHolder='Insira o nome do setor'
                                error={descriptionError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {descriptionError && <Text size={13} color={theme.palette.error.main} >{descriptionError}</Text>}
                        </Container>
                    </Container>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.primary.light, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Enviar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default DepartmentsNewModal;