import React, { FC, useEffect, useState } from 'react'
import { Box, Divider, Grid, IconButton, LinearProgress, } from '@mui/material'
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Refresh as RefreshIcon } from '@mui/icons-material'

import ListItem from './item';
import Container from '../../atoms/container';
import Spacing from '../../atoms/spacing';
import Text from '../../atoms/text';
import theme from '../../theme';
import { IApprovalRequest, IGoalUser, IList, IListHeader, ITeam, IUser } from '../../../types';
import { PADDING, VERY_PADDING } from '../../../utils/consts';

const fontSize: number = 12;

const List: FC<IList> = (params: IList) => {
    const [data, setData] = useState<any[]>([]);
    const [currentSort, setCurrentSort] = useState<string>('');
    const [currentSortType, setCurrentSortType] = useState<'asc' | 'desc'>('asc');
    const [paginate, setPaginate] = useState<number>(0);

    const isSortable: boolean = params.headers.find((item: IListHeader) => item.sortable == true) != undefined;

    useEffect(() => {
        if (isSortable && params.headers.length > 0 && params.headers[0].fieldName)
            sortByColumn(params.headers[0].fieldName, true);
        else
            setData([...params.data]);
    }, [params.data]);

    useEffect(() => {
        if (isSortable)
            sortByColumn(currentSort, true);
    }, [params.perPage, paginate]);


    const sortByColumn = (_fieldName: string, _firstLoad?: boolean) => {
        let auxData: any[] = params.data;

        let auxCurrentSortType = currentSortType;
        if (currentSort != _fieldName) auxCurrentSortType = 'asc';
        else if (!_firstLoad) {
            if (auxCurrentSortType == 'asc') auxCurrentSortType = 'desc';
            else if (auxCurrentSortType == 'desc') auxCurrentSortType = 'asc';
        }

        auxData = [...params.data.sort((a: any, b: any) => {
            const itemA: any = params.dataToItem(a);
            const itemB: any = params.dataToItem(b);

            if (itemA[_fieldName].normalize) {
                if (itemA[_fieldName].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > itemB[_fieldName].normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                    return auxCurrentSortType == 'asc' ? 1 : -1;
                else if (itemA[_fieldName].normalize('NFD').replace(/[\u0300-\u036f]/g, "") < itemB[_fieldName].normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                    return auxCurrentSortType == 'asc' ? -1 : 1;
                return 0;
            }

            if (itemA[_fieldName] > itemB[_fieldName])
                return auxCurrentSortType == 'asc' ? 1 : -1;
            else if (itemA[_fieldName] < itemB[_fieldName])
                return auxCurrentSortType == 'asc' ? -1 : 1;
            return 0;
        })];

        const beginPaginate = paginate * params.perPage;
        if (paginate == 0)
            auxData = [...auxData.slice(0, params.perPage)];
        else
            auxData = [...auxData.slice(beginPaginate, beginPaginate + params.perPage)];

        setData([...auxData]);
        setCurrentSort(_fieldName);
        setCurrentSortType(auxCurrentSortType);
    }

    return (
        <Container fluid width="100%" centered sx={{ paddingBottom: VERY_PADDING }}>
            <LinearProgress color="primary" />
            {params.loading &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>}
            <Spacing top={5} />

            {!params.loading && params.onRefresh && <Container fluid width="100%" flexEnd>
                <IconButton onClick={() => params.onRefresh && params.onRefresh()} color="primary" size="small">
                    <RefreshIcon fontSize="small" />
                </IconButton>
                <Spacing bottom={PADDING} />
            </Container>}

            <Container fluid width="100%" >
                {params.headers.find(item => item.hideLabel) == undefined && <Grid spacing={1} container direction="row" justifyContent="space-between"
                    style={{ height: 40, borderTop: `solid 1px ${theme.palette.grayLight.main}`, borderBottom: `solid 1px ${theme.palette.grayLight.main}` }}>

                    {params.headers.map((header: IListHeader, index: number) => {
                        if (header.hideLabel) return null;

                        return (
                            <Grid key={index} item xs={header.xs} justifyContent='center' alignItems='center'>
                                <Container inline width="100%" horizontalCentered style={{ cursor: header.sortable ? 'pointer' : 'default' }}
                                    onClick={() => {
                                        if (header.fieldName && header.sortable)
                                            sortByColumn(header.fieldName, false);
                                    }}>
                                    {index == 0 && <Spacing left={PADDING} />}
                                    <Text bold size={fontSize} style={{ userSelect: 'none' }} >{header.label}</Text>
                                    <Spacing left={PADDING} />
                                    {header.sortable && currentSort == header.fieldName && currentSortType == 'asc' && <ArrowDownwardIcon style={{ fontSize: 16 }} />}
                                    {header.sortable && currentSort == header.fieldName && currentSortType == 'desc' && <ArrowUpwardIcon style={{ fontSize: 16 }} />}
                                </Container>
                            </Grid>
                        );
                    })}
                </Grid>}
                {params.headers.find(item => item.hideLabel) != undefined && !params.loading && <Divider sx={{ borderColor: theme.palette.grayLight.main }} />}

                {!params.loading && data.map((_data: any, index: number) => {
                    return (
                        <ListItem
                            key={index}
                            headers={params.headers}
                            data={{ ..._data }}
                            colorKey="classificationIa"
                            onAction={params.onAction}
                            dataToItem={params.dataToItem}
                            detail={params.detail} />
                    )
                })}

                <Container inline spacedBetween>
                    <Box style={{ width: '100%' }} />
                    <Container inline centered>
                        <Text size={12} bold style={{ whiteSpace: 'nowrap' }}>
                            {params.total == 0 ? 0 : ((paginate) * params.perPage) + 1}-{((paginate + 1) * params.perPage) > params.total ? params.total : ((paginate + 1) * params.perPage)} de {params.total}
                        </Text>
                        <IconButton onClick={() => setPaginate(paginate - 1)}
                            disabled={paginate == 0}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <IconButton onClick={() => setPaginate(paginate + 1)}
                            disabled={((paginate + 1) * params.perPage) + 1 > params.total}>
                            <ChevronRightIcon />
                        </IconButton>
                    </Container>
                </Container>
            </Container>
        </Container>
    )

}

export default List;
