const colors = {
  primary: "#003459",
  primaryLight: "#3E589C",

  secondary: "#3E589C",
  
  tertiary: "#6AB0B7",

  textColor: "#140204",
  background: "#f1f1f1",
  backgroundLighter: "#FAFAFA",

  info: "#3E589C",
  error: "#FF482F",
  success: "#05FF00",
  warning: "#FAFF00",
  warningDark: "#FFC700",

  gray: "#C4C4C4",
  grayLight: "#E3E3E3",
  grayLighter: "#E9E9E9",
};

export { colors };
