import React, { FC } from 'react'
import { Circle as CircleIcon } from '@mui/icons-material';

import theme from '../../../theme'
import Container from '../../../atoms/container'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import { IGoalsActive } from '../../../../types';

const ActiveGoals: FC<IGoalsActive> = (params: IGoalsActive) => {
    return (
        <Container inline centered>
            <CircleIcon style={{ fontSize: 13, color: params.delayed ? theme.palette.error.main : params.count == 0 ? theme.palette.common.black : theme.palette.success.main }} />
            <Spacing left={5} />

            {params.count == 0 && <Text medium size={14}>Sem objetivos {params.delayed ? 'em atraso' : 'ativos'}</Text>}
            {params.count == 1 && <Text medium size={14}>{params.count} {params.delayed ? 'em atraso' : 'objetivo ativo'}</Text>}
            {params.count > 1 && <Text medium size={14}>{params.count} objetivos {params.delayed ? 'em atraso' : 'ativos'}</Text>}
        </Container>
    );
}

export default ActiveGoals;