import React, { FC, createContext, useContext, useState } from 'react'
import { createDepartment, destroyDepartment, getDepartment, getDepartments, updateDepartment } from '../services/departments';

import { IDepartment, IDepartmentContext, IDepartmentNew } from '../types'

const DepartmentsContext = createContext<IDepartmentContext>({} as IDepartmentContext);

export const DepartmentsProvider: FC<any> = ({ children }) => {
    const [departments, setDepartments] = useState<IDepartment[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchDepartment, setSearchDepartment] = useState<string>('');
    const [departmentsPerRow, setDepartmentsPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchDepartments = async () => {
        setLoading(true);
        try {
            const departments: IDepartment[] = await getDepartments();
            setDepartments([...departments]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const fetchDepartment = async (_id: string) => {
        const user: IDepartment = await getDepartment(_id);
        return user;
    }

    const createNewDepartment = async (_description: string) => {
        const payload: IDepartmentNew = {
            description: _description
        }

        const user = await createDepartment(payload);

        fetchDepartments();
        return user;
    }

    const editDepartment = async (_id: string, _payload: IDepartmentNew) => {
        try {
            const user = await updateDepartment(_id, _payload);

            fetchDepartments();
            return user;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteDepartment = async (_id: string) => {
        const user = await destroyDepartment(_id);
        fetchDepartments();
        return user;
    }

    return (
        <DepartmentsContext.Provider value={{
            departments, loading,
            fetchDepartments, fetchDepartment,
            createNewDepartment, editDepartment, deleteDepartment,
            searchDepartment, setSearchDepartment, departmentsPerRow, setDepartmentsPerRow, paginate, setPaginate
        }}>{children}</DepartmentsContext.Provider>
    )
}

export function useDepartments() {
    const context = useContext(DepartmentsContext);

    if (!context) {
        throw new Error('useDepartments must be used within an DepartmentsProvider')
    }

    return context;
}