import { AxiosResponse } from "axios";

import api from ".";

export const createUserHasPosition = async (
  _userId: string,
  _positionId: string
) => {
  const response: AxiosResponse = await api.post("/v1_0/users_has_positions", {
    userId: _userId,
    positionId: _positionId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error("Não foi possível criar a vinculação de usuário com cargo");
};
