import { AxiosResponse } from "axios";

import api from ".";
import { IPrivilege, IRoute } from "../types";

const baseUrl: string = "privileges";
export const getPrivileges = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privileges: IPrivilege[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const routes: IRoute[] = [];
        if (data.routes) {
          for (let j = 0; j < data.routes.length; j++) {
            const route = data.routes[j];
            routes.push({
              id: route.id,
              path: route.path,
            });
          }
        }

        const privilege: IPrivilege = {
          id: data.id,
          description: data.description,
          routes,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };

        privileges.push(privilege);
      }

      return privileges;
    }
  }

  throw new Error("Não foi possível buscar os privilégios");
};
