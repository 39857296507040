import React, { FC, createContext, useContext, useState } from 'react'
import { createPosition, getPositions } from '../services/positions';

import { IPosition, IPositionContext } from '../types'

const PositionsContext = createContext<IPositionContext>({} as IPositionContext);

export const PositionsProvider: FC<any> = ({ children }) => {
    const [positions, setPositions] = useState<IPosition[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchPosition, setSearchPosition] = useState<string>('');
    const [positionsPerRow, setPositionsPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchPositions = async () => {
        setLoading(true);
        try {
            const positions: IPosition[] = await getPositions();
            setPositions([...positions]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const createNewPosition = async (_departmentId: string, _description: string) => {
        const position = await createPosition(_departmentId, _description);
        return position;
    }

    return (
        <PositionsContext.Provider value={{
            positions, loading, fetchPositions, createNewPosition, searchPosition, setSearchPosition, positionsPerRow, setPositionsPerRow, paginate, setPaginate
        }}>{children}</PositionsContext.Provider>
    )
}

export function usePositions() {
    const context = useContext(PositionsContext);

    if (!context) {
        throw new Error('usePositions must be used within an PositionsProvider')
    }

    return context;
}