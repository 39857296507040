import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, IconButton, InputAdornment, Input, OutlinedInput, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'

import '../classes.css'
import logo from '../../../assets/logo_dark.png'
import { Container, Image, InputPassword, Page, Spacing, Text, theme } from '../../../components'
import { useAuth } from '../../../hooks/auth'
import { login as loginService } from '../../../services/auth'
import { BORDER_RADIUS, PADDING } from '../../../utils/consts'
import { IPasswordRecovery } from '../../../types/hooks/auth'

const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { login, fetchRecoveryPassword, updateRecoveryPassword } = useAuth()
    const navigate = useNavigate();
    const { token } = useParams();

    const [data, setData] = useState<IPasswordRecovery | undefined>(undefined);
    const [loadingData, setLoadingData] = useState<boolean>(true);

    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showPassword);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        if (token) {
            setLoadingData(true);
            const auxData: IPasswordRecovery | undefined = await fetchRecoveryPassword(token);
            setData(auxData);

            setLoadingData(false);
        }
    }

    const onRecoveryPassword = async () => {
        if (!token) return;

        setLoading(true);
        setLoginErrorMessage(null);

        setTimeout(async () => {
            try {
                setLoading(true);

                await updateRecoveryPassword(token, password);
                window.open('/', '_self');
                setLoading(false);
            } catch (_err) {
                if (_err instanceof Error) {
                    console.error(_err)
                    setLoginErrorMessage(_err.message)
                }
                else
                    setLoginErrorMessage('Ocorreu um erro ao efetuar o login')

                setLoading(false);
            }
        }, 1000)
    }


    return (
        <Page color={theme.palette.primary.main}>
            <Container className="background-square-1" />
            <Container className="background-square-2" />

            <Container fluid centered color="#000" height={35}>
                <Text color={theme.palette.background.paper} size={18} bold>Plano de Metas e Performance</Text>
            </Container>

            {!loadingData && data && data.used == true && <Container
                fluid
                width="100%"
                height='100vh'
                centered>

                <Container fluid spacedEvenly hasShadow width='35%' height='75%' color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}
                    style={{
                        maxWidth: '350px',
                        maxHeight: '250px'
                    }}>
                    <Container fluid centered width="100%" >
                        <Text size={17} center>Este token de recuperação de senha já foi utilizado.</Text>
                    </Container>

                    <Container inline spacedAround>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <LoadingButton
                                variant="outlined"
                                size="large"
                                loading={loading}
                                disabled={loading}
                                style={{ minWidth: '120px' }}>
                                <Text size={13} color={theme.palette.primary.main}>Voltar</Text>
                            </LoadingButton>
                        </Link>
                    </Container>
                </Container>
            </Container>}

            {!loadingData && data && data.used == false && <Container
                fluid
                width="100%"
                height='100vh'
                centered>

                <Container fluid spacedEvenly hasShadow width='35%' height='75%' color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}
                    style={{
                        maxWidth: '505px',
                        maxHeight: '350px'
                    }}>
                    <Container fluid centered width="100%" >
                        <Text bold size={20}>Recuperação de senha</Text>
                    </Container>

                    <form onSubmit={handleSubmit(onRecoveryPassword)}>
                        <Container fluid centered veryPadded>
                            <Container width='80%'>
                                <Container fluid >
                                    <Input
                                        id="passw"
                                        className="text-input"
                                        disableUnderline
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        placeholder='Senha'
                                        size='small'
                                        fullWidth
                                        autoComplete='new-password'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    aria-label="passw-togle-visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...register('password', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangePassword(event),
                                            required: 'Informe sua senha'
                                        })}
                                        style={{
                                            backgroundColor: '#E9E9E9',
                                            border: 'solid 0px transparent',
                                            borderRadius: 6.91,
                                            boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                                        }}
                                    />
                                    {errors.password && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.password.message as string)}</Text>}
                                </Container>
                                <Spacing top={25} />

                                <Container fluid >
                                    <Input
                                        id="confirm-passw"
                                        className="text-input"
                                        disableUnderline
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={confirmPassword}
                                        placeholder='Confirme a senha'
                                        size='small'
                                        fullWidth
                                        autoComplete='new-password'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    aria-label="passw-togle-visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleClickShowConfirmPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...register('confirmPassword', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeConfirmPassword(event),
                                            required: 'Confirme sua senha'
                                        })}
                                        style={{
                                            backgroundColor: '#E9E9E9',
                                            border: 'solid 0px transparent',
                                            borderRadius: 6.91,
                                            boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                                        }}
                                    />
                                    {errors.confirmPassword && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.confirmPassword.message as string)}</Text>}
                                </Container>
                                <Spacing top={PADDING} />

                                <Container fluid width='100%' centered >
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{loginErrorMessage || ''}</Text>
                                </Container>

                                <Spacing top={PADDING} />

                                <Container inline spacedAround>
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <LoadingButton
                                            variant="outlined"
                                            size="large"
                                            loading={loading}
                                            disabled={loading}
                                            style={{ minWidth: '120px' }}>
                                            <Text size={13} color={theme.palette.primary.main}>Voltar</Text>
                                        </LoadingButton>
                                    </Link>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={loading}
                                        disabled={loading}
                                        style={{ minWidth: '120px' }}>
                                        <Text size={13} color={theme.palette.background.default}>Enviar</Text>
                                    </LoadingButton>
                                </Container>
                            </Container>
                        </Container>
                    </form>
                </Container>
            </Container >}
        </Page >
    )
}

export default Login