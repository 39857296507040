import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Container, Image, Page, Spacing, Text, theme, UserCard, GoalsListItem } from '../../components'
import { useGoal } from '../../hooks/goals';
import { useUsers } from '../../hooks/users';
import { BORDER_RADIUS, PADDING } from '../../utils/consts'
import { IGoal, IUser } from '../../types'

const GoalDetailed: FC = () => {
    const { id } = useParams();

    const { fetchGoal } = useGoal();
    const { fetchUser } = useUsers();

    const [goal, setGoal] = useState<IGoal | undefined>(undefined);
    const [goalUser, setGoalUser] = useState<IUser | undefined>(undefined);
    const [goalUserGuardian, setGoalUserGuardian] = useState<IUser | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadGoalAndUser();
    }, []);

    useEffect(() => {

    });

    const loadGoalAndUser = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxGoal: IGoal = await fetchGoal(id);
                setGoal(auxGoal);

                const auxUser: IUser = await fetchUser(auxGoal.userId);
                setGoalUser(auxUser);
                if (auxUser.guardianId) {
                    const auxGuardian: IUser = await fetchUser(auxUser.guardianId);
                    setGoalUserGuardian(auxGuardian);
                }
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
        catch (_err) {
            setLoading(false);
        }
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>PMP - Plano de Metas e Performance</Text>
                    <Spacing top={10} />
                    <Text size={18} center>O PMP é utilizado para atingir metas individuais dentro da Pix Force e de desenvolvimento profissional.</Text>
                    <Text size={18} center>Direcionado para a área de atuação, estabelecendo objetivos de curto, médio e longo prazo.
                    </Text>

                    <Spacing top={30} />

                    <Container inline spacedAround width={'100%'}>
                        {loading && <Container fluid centered padded>
                            <CircularProgress size={25} />
                        </Container>}
                        {!loading && goalUser && goalUser.position && <UserCard
                            fontColor={theme.palette.common.black}
                            user={goalUser} showPosition avatarSize={50} position={goalUser.position} />}
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50} />
                        {loading && <Container fluid centered padded>
                            <CircularProgress size={25} />
                        </Container>}
                        {!loading && goalUserGuardian && goalUserGuardian.position && <UserCard fontColor={theme.palette.common.black} user={goalUserGuardian} isGuardian avatarSize={50} position={goalUserGuardian.position} />}
                        {!loading && !goalUserGuardian && <Text bold>Tutor não encontrado</Text>}
                    </Container>
                </Container>

                <Container fluid veryPadded>
                    <Container fluid width="100%" centered>
                        <Text size={25} bold>Objetivo</Text>
                        <Spacing top={PADDING} />
                        {goal && <GoalsListItem
                            key={id}
                            goal={goal}
                            loading={loading}
                            disabled={loading}
                            noAction
                            onEditGoal={(_goalId) => alert(_goalId)}
                            onOpenDeleteConfirmationModal={(_goalId) => alert(_goalId)} />}
                    </Container>
                </Container>
            </Container>


        </Page >
    )
}

export default GoalDetailed;