import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import {
  IGoalNew,
  IGoal,
  ITask,
  IApprovalRequest,
  IGoalsStats,
  IGoalUser,
  IGoalUserItem,
} from "../types";

export const getGoals = async (_userId: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/goals`);

  if (response && response.request) {
    const goals: IGoal[] = [];

    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const goal = convertApiResponseToIGoal(response.data[i]);
          goals.push(goal);
        }
      }
    }

    return goals;
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export const getGoalsByUser = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/goals/users`);

  if (response && response.request) {
    const goals: IGoalUser[] = [];

    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i];

          const goalItems: IGoalUserItem[] = [];
          if (data.goals && data.goals.length) {
            for (let j = 0; j < data.goals.length; j++) {
              const item = data.goals[j];
              const goalItem: IGoalUserItem = {
                id: item.id,
                name: item.name,
                goalType: item.goalType,
                activeTasks: item.activeTasks,
                deadline: item.deadline,
                percentage: item.percentage,
              };
              goalItems.push(goalItem);
            }
          }

          const goal: IGoalUser = {
            id: data.id,
            surname: data.surname,
            positions: data.positions,
            departments: data.departments,
            activeGoals: data.activeGoals,
            lastAccess: data.lastAccess,
            goals: goalItems,
          };

          goals.push(goal);
        }
      }
    }

    return goals;
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export const getGoalsByUserId = async (_userId: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/goals/user_id/${_userId}`);

  if (response && response.request) {
    const goals: IGoal[] = [];

    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const goal = convertApiResponseToIGoal(response.data[i]);
          goals.push(goal);
        }
      }
    }

    return goals;
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export async function getGoalById(_id: string): Promise<IGoal> {
  const response: AxiosResponse = await api.get(`/v1_0/goals/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const goal: IGoal = convertApiResponseToIGoal(response.data);
        return goal;
      }
    }
  }

  throw new Error("Não foi possível buscar os objetivos.");
}

export const createGoal = async (_payload: IGoalNew) => {
  const response: AxiosResponse = await api.post(`/v1_0/goals`, {
    userId: _payload.userId,
    guardianId: _payload.guardianId,
    name: _payload.name,
    description: _payload.description,
    startedAt: moment(_payload.startedAt).format("YYYY-MM-DD"),
    predictedEndType: _payload.predictedEndType.toString(),
    predictedEndValue: _payload.predictedEndValue.toString(),
    goalTypeId: _payload.goalTypeId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const goal: IGoal = convertApiResponseToIGoal(response.data);
        return goal;
      }
    }
  }

  throw new Error("Não foi possível criar um novo objetivo.");
};

export const updateGoal = async (_id: string, _payload: IGoalNew) => {
  const response: AxiosResponse = await api.put(`/v1_0/goals/${_id}`, {
    userId: _payload.userId,
    guardianId: _payload.guardianId,
    name: _payload.name,
    description: _payload.description,
    startedAt: moment(_payload.startedAt).format("YYYY-MM-DD"),
    predictedEndType: _payload.predictedEndType.toString(),
    predictedEndValue: _payload.predictedEndValue.toString(),
    goalTypeId: _payload.goalTypeId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const goal: IGoal = convertApiResponseToIGoal(response.data);
        return goal;
      }
    }
  }

  throw new Error("Não foi possível criar um novo objetivo.");
};

export const deleteGoal = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/goals/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const goal: IGoal = convertApiResponseToIGoal(response.data);
        return goal;
      }
    }
  }

  throw new Error("Não foi possível excluir este objetivo.");
};

export const getGoalsStats = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/goals/stats`);

  if (response && response.request) {
    const goals: IGoal[] = [];

    const status = response.request.status;
    if (status == 200) {
      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };      
      if (response.data) {
        goalsStats.inProgress = response.data.inProgress;
        goalsStats.delayed = response.data.delayed;
      }

      return goalsStats;
    }
  }

  throw new Error("Não foi possível buscar as estatísticas dos objetivos.");
};

export const convertApiResponseToIGoal = (_data: any) => {
  const tasks: ITask[] = [];
  if (_data.tasks)
    for (let i = 0; i < _data.tasks.length; i++) {
      const task = _data.tasks[i];
      tasks.push({
        id: task.id,
        goalId: task.goalId,
        name: task.name,
        deadline: task.deadline,
        percentage: task.percentage,
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
      });
    }

  const approvalsRequests: IApprovalRequest[] = [];
  if (_data.approvalsRequests)
    for (let i = 0; i < _data.approvalsRequests.length; i++) {
      const approvalRequest = _data.approvalsRequests[i];
      approvalsRequests.push({
        id: approvalRequest.id,
        userId: approvalRequest.userId,
        responsibleId: approvalRequest.responsibleId,
        request: approvalRequest.request,
        type: approvalRequest.type,
        approved: approvalRequest.approved,
        reply: approvalRequest.reply,
        viewed: approvalRequest.viewed,
        typeDesc: approvalRequest.typeDesc,
        createdAt: approvalRequest.createdAt,
        updatedAt: approvalRequest.updatedAt,
      });
    }

  const goal: IGoal = {
    id: _data.id,
    userId: _data.userId,
    guardianId: _data.guardianId,
    name: _data.name,
    description: _data.description,
    startedAt: _data.startedAt,
    predictedEndType: _data.predictedEndType,
    predictedEndValue: _data.predictedEndValue,
    predictedEndTypeDesc: _data.predictedEndTypeDesc,
    goalTypeId: _data.goalTypeId,
    goalType: {
      id: _data.goalType.id,
      description: _data.goalType.description,
    },
    progress: _data.progress,
    tasks,
    approvalsRequests,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return goal;
};
