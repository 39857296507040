import React, { FC, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { People as PeopleIcon, Search as SearchIcon } from '@mui/icons-material'

import logoDetail from '../../assets/logo_detail.png'
import {
    AppHeaderToolbar,
    Confirmation,
    Container,
    Image,
    Information,
    Input,
    List,
    Page,
    Spacing,
    TeamListItem,
    TeamNewModal,
    Text,
    theme,
} from '../../components'
import { useTeams } from '../../hooks/teams';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IApprovalRequest, IListHeader, ITeam, IUser } from '../../types'
import { isTeam } from '../../types/hooks/teams'
import { downloadCSVFromJson } from '../../utils/converters'
import { normalizeString } from '../../utils/stringFormatters'

const PER_ROWS: number = 15;

const Teams: FC = () => {
    const { teams, deleteTeam, fetchTeams, loading, searchTeam, setSearchTeam, paginate, setPaginate } = useTeams();

    const [teamToEdit, setTeamToEdit] = useState<ITeam | undefined>(undefined);
    const [newTeamModalVisible, setNewTeamModalVisible] = useState<boolean>(false);

    const [filteredTeams, setFilteredTeams] = useState<ITeam[]>([]);

    const [informationModal, setInformationModal] = useState<boolean>(false);
    const [informationModalTitle, setInformationModalTitle] = useState<string>('');
    const [informationModalMessage, setInformationModalMessage] = useState<string>('');
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [confirmationTitle, setConfirmationTitle] = useState<string>('');
    const [confirmationMessage, setConfirmationMessage] = useState<string>('');
    const [deleteTeamId, setDeleteTeamId] = useState<string | null>(null);


    useEffect(() => {
        fetchTeams();
    }, [])

    useEffect(() => {
        filterTeams()
    }, [teams])

    useEffect(() => {
        filterTeams(searchTeam);
    }, [paginate, searchTeam])

    useEffect(() => {
        if (!informationModal)
            setInformationModalMessage('');
    }, [informationModal])

    useEffect(() => {
        if (!newTeamModalVisible)
            setTeamToEdit(undefined);
    }, [newTeamModalVisible])

    const filterTeams = (_search: string = '') => {
        setFilteredTeams([])
        let auxTeams = [...teams]

        setTimeout(() => {
            if (_search != '') {
                auxTeams = auxTeams.filter((item: ITeam) =>
                    (item.description && normalizeString(item.description).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.supervisors.length > 0 && item.supervisors[0].surname && normalizeString(item.supervisors[0].surname).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredTeams([...auxTeams]);
        }, 0)

    }

    const headers: IListHeader[] = [
        {
            label: "Ações",
            fieldName: "approved",
            xs: 12,
            isString: true,
            text: (_value: string | number | boolean | null) => {
                if (typeof _value === 'boolean') {
                    if (_value == true)
                        return 'Aprovada'
                    else return 'Recusada'
                }
                return 'Desconhecido'
            },
            fontColor: (_value: string | number | boolean | null) => {
                if (typeof _value === 'boolean') {
                    if (_value == true)
                        return theme.palette.success.main;
                    else return theme.palette.error.main;
                }
                return theme.palette.common.black;
            },
            showActionIfFieldIsNull: 'responsibleId',
            actions: (_id: string) => [
                <Container fluid flexStart width={'100%'}>
                    {teams.map((item: ITeam, index: number) => {
                        if (item && item.id == _id)
                            return renderItem(item, index);
                        return null;
                    })}
                </Container>
            ],
            hideLabel: true
        },
    ];

    const convertDataToItem = (_data: ITeam) => {
        let surname: string = 'Desconhecido';
        let position: string = 'Desconhecido';
        let goalName: string = 'Desconhecido';
        let request: string = 'Desconhecido';
        let responsibleId: string | undefined = undefined;
        let approved: boolean | undefined = undefined;

        return { surname, position, goalName, request, responsibleId, approved }
    }

    const renderItem = (_data: ITeam, _index: number) => {
        return <TeamListItem
            key={_index}
            team={_data}
            index={_index}
            onAddNewMember={(_teamId: string) => addNewMember(_teamId)}
            onDeleteTeam={(_teamId: string) => onDeleteTeam(_teamId)} />;
    }

    const addNewMember = (_teamId: string) => {
        const team: ITeam | undefined = teams.find((item: ITeam) => item.id == _teamId);
        if (team) {
            setTeamToEdit(team);
            setTimeout(() => {
                setNewTeamModalVisible(true);
            }, 100);
        }
    }

    const onDeleteTeam = async (_teamId: string) => {
        setDeleteTeamId(_teamId);
        setConfirmationTitle("Atenção");
        setConfirmationMessage("Deseja realmente apagar essa equipe? O processo não pode ser desfeito.");
        setConfirmationModal(true);
    }

    const perPageToNumber = () => {
        return PER_ROWS;
    }

    function getInitialIndexPaginate() {
        const perPage: number = perPageToNumber();
        return paginate * perPage;
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>Equipes</Text>
                    <Spacing top={10} />
                    <Text size={18} center>
                        Crie, consulte e edite as equipes existentes no PMP.
                    </Text>
                    <Text size={13}>
                        Clique em uma equipe para expandir.
                    </Text>
                </Container>
                <Container inline color={theme.palette.tertiary.main}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Container inline horizontalCentered sx={{ paddingTop: PADDING / 2, paddingRight: VERY_PADDING, paddingBottom: PADDING / 2 }}>
                        <Text bold size={13} color={theme.palette.background.paper}>Exportar para:</Text>
                        <Spacing left={10} />
                        {/* <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}><Text bold size={13} color={theme.palette.background.paper}>PDF</Text></Button>
                        <Spacing left={5} /> */}
                        <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}
                            onClick={() => downloadCSVFromJson('equipes.csv', teams)}><Text bold size={13} color={theme.palette.background.paper}>Excel</Text></Button>
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween flexStart width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchRequest"
                                autoFocus
                                value={searchTeam}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTeam(event.target.value)}
                                placeHolder='Digite o nome da equipe ou do supervisor'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />
                        <Container inline horizontalCentered width="100%" flexEnd >
                            <Button
                                variant="contained"
                                style={{ backgroundColor: theme.palette.tertiary.main }}
                                startIcon={<PeopleIcon />}
                                onClick={() => setNewTeamModalVisible(true)}>
                                <Text size={13} color={theme.palette.background.paper}>Cadastrar nova equipe</Text>
                            </Button>
                        </Container>
                    </Container>

                    <List
                        headers={headers}
                        data={filteredTeams}
                        loading={loading}
                        total={teams.length}
                        perPage={PER_ROWS}
                        paginate={paginate}
                        onPreviousPage={() => setPaginate(paginate - 1)}
                        onNextPage={() => setPaginate(paginate + 1)}
                        dataToItem={(_data: IUser | IApprovalRequest | ITeam) => {
                            if (isTeam(_data))
                                return convertDataToItem(_data)
                            return undefined
                        }}
                        onRefresh={() => fetchTeams()}
                    />
                </Container>
            </Container>

            <TeamNewModal
                teamToEdit={teamToEdit}
                open={newTeamModalVisible}
                onClose={() => setNewTeamModalVisible(false)}
                onCancel={() => setNewTeamModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    setInformationModalTitle(_informationTitle);
                    setInformationModalMessage(_informationMessage);
                    setTimeout(() => {
                        setInformationModal(true);
                    }, 100);
                }} />
            <Information
                open={informationModal}
                message={informationModalMessage}
                onClose={() => setInformationModal(false)}
                onConfirm={() => setInformationModal(false)} />
            <Confirmation
                open={confirmationModal}
                title={confirmationTitle}
                message={confirmationMessage}
                onClose={() => setConfirmationModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setConfirmationModal(false)}
                onConfirm={async () => {
                    if (deleteTeamId) {
                        const team: ITeam = await deleteTeam(deleteTeamId);
                        fetchTeams();
                    }
                    setConfirmationModal(false);
                }}
                confirmLabel="Confirmar" />
        </Page >
    )
}

export default Teams;