import { AxiosResponse } from "axios";

import api from "./";
import { IGoalType } from "../types";

export const getGoalsTypes = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/goals_types`);

  if (response && response.request) {
    const status = response.request.status;

    const goalsTypes: IGoalType[] = [];
    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const goalType = response.data[i];
        goalsTypes.push({
          id: goalType.id,
          description: goalType.description,
          createdAt: goalType.createdAt,
          updatedAt: goalType.updatedAt,
        });
      }
    }

    return goalsTypes;
  }

  throw new Error("Não foi possível buscar os tipos de plano");
};
