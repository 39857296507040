import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, CircularProgress, Grid } from '@mui/material'
import { PersonAddAlt1 as PersonAddAlt1Icon, Search as SearchIcon } from '@mui/icons-material'

import logoDetail from '../../assets/logo_detail.png'
import {
    AppHeaderToolbar,
    Container,
    GoalsActive,
    Image,
    Input,
    Page,
    Spacing,
    TeamNewModal,
    Text,
    theme,
    UserCard
} from '../../components'
import { useAuth } from '../../hooks/auth';
import { useInformation } from '../../hooks/information';
import { useTeams } from '../../hooks/teams';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IRoute, ITeam, IUser } from '../../types'

const MyTeam: FC = () => {
    const { privilege } = useAuth();
    const { showInformation } = useInformation();
    const { teams, fetchTeams, members, loadingMembers, fetchTeamMembers } = useTeams();
    const { id } = useParams();

    const [currentTeam, setCurrentTeam] = useState<ITeam | undefined>(undefined);
    const [filteredMembers, setFilteredMembers] = useState<IUser[]>([]);
    const [searchMember, setSearchMember] = useState<string>('');
    const [newMemberModalVisible, setNewMemberModalVisible] = useState<boolean>(false);


    useEffect(() => {
        fetchTeams();
        if (id) fetchTeamMembers(id);
    }, []);

    useEffect(() => {
        if (id) fetchTeamMembers(id);
    }, [id]);

    useEffect(() => {
        if (teams && id) {
            const auxTeam: ITeam | undefined = teams.find((item: ITeam) => item.id == id)
            if (auxTeam)
                setCurrentTeam(auxTeam);
        }
    }, [teams, id])

    useEffect(() => {
        filterMembers()
    }, [members])

    useEffect(() => {
        filterMembers(searchMember);
    }, [searchMember])


    const filterMembers = (_search: string = '') => {
        setFilteredMembers([])
        let auxMembers: IUser[] = [...members];

        setTimeout(() => {
            if (_search != '') {
                auxMembers = auxMembers.filter((item: IUser) =>
                    (item.profile && item.profile.surname && item.profile.surname.toLowerCase().includes(_search.toLowerCase())) ||
                    (item.position && item.position.description.toLowerCase().includes(_search.toLowerCase())) ||
                    (item.position && item.position.departmentDescription.toLowerCase().includes(_search.toLowerCase())))
            }

            setFilteredMembers([...auxMembers]);
        }, 0)

    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>{currentTeam ? currentTeam.description : 'Equipe'}</Text>
                    <Spacing top={10} />
                    <Text size={18} center>
                        Consulte os membros da equipe e solicite a adição de novos membros
                    </Text>
                </Container>

                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchRequest"
                                autoFocus
                                value={searchMember}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchMember(event.target.value)}
                                placeHolder='Digite o nome, cargo ou departamento do membro'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>

                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />

                        <Container inline horizontalCentered width="100%" flexEnd >
                            {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('@admin_header') >= 0 &&
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: theme.palette.tertiary.main }}
                                    startIcon={<PersonAddAlt1Icon />}
                                    onClick={() => setNewMemberModalVisible(true)}>
                                    <Text size={13} color={theme.palette.background.paper}>Solicitar novo membro</Text>
                                </Button>
                            }
                        </Container>
                    </Container>

                    {loadingMembers &&
                        <Container fluid centered veryPadded height={300}>
                            <CircularProgress size={25} />
                        </Container>}
                    {!loadingMembers && filteredMembers.length == 0 &&
                        <Container fluid centered height={300}>
                            <Text bold size={22} center>Esta equipe não possui nenhum membro.</Text>
                        </Container>
                    }
                    {!loadingMembers && filteredMembers.length > 0 &&
                        <Container fluid padded width="100%" sx={{ paddingTop: VERY_PADDING }} flexStart >
                            <Grid container spacing={4} justifyContent="flex-start" alignItems="flex-start">
                                {filteredMembers.map((item: IUser, index: number) => {
                                    return (
                                        <Grid key={index} item xs={4}>
                                            <Container fluid centered>
                                                <UserCard
                                                    fontColor={theme.palette.common.black}
                                                    avatarSize={60}
                                                    user={item}
                                                    position={item.position ? item.position : null}
                                                    showPosition />
                                                <Container fluid padded flexStart sx={{ paddingLeft: VERY_PADDING * 4 }}>
                                                    <GoalsActive count={item.goalsStats ? item.goalsStats.inProgress : 0} />
                                                    <Spacing left={VERY_PADDING} />
                                                    <GoalsActive count={item.goalsStats ? item.goalsStats.delayed : 0} delayed />
                                                </Container>
                                            </Container>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Container>}
                </Container>
            </Container>

            <TeamNewModal
                teamToEdit={currentTeam}
                open={newMemberModalVisible}
                onClose={() => setNewMemberModalVisible(false)}
                onCancel={() => setNewMemberModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    setNewMemberModalVisible(false)
                    showInformation(_informationMessage);
                }}
                requestMember />
        </Page >
    )
}

export default MyTeam;