export const formatFirstName = (name: string) => {
    return `${name.split(' ')[0]} ${name.split(' ')[1] ? name.split(' ')[1][0] + '.' : ''}`;
  };
  
  export const formatOnlyFirstName = (name: string) => {
    return `${name.split(' ')[0]}`;
  };
  
  export const formatFirstAndSecondName = (name: string) => {
    if (name.split(' ').length == 1) return `${name.split(' ')[0]}`;
  
    if (name.split(' ')[1].toUpperCase() == 'DE')
      return `${name.split(' ')[0]} ${name.split(' ')[1]} ${name.split(' ')[2]}`;
  
    return `${name.split(' ')[0]} ${name.split(' ')[1]}`;
  };
  