import { AxiosResponse } from "axios";

import api from ".";

export const createUserHasGuardian = async (
  _userId: string,
  _guardianId: string
) => {
  const response: AxiosResponse = await api.post("/v1_0/users_has_guardians", {
    userId: _userId,
    guardianId: _guardianId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;
      return id;
    }
  }
  
  throw new Error("Não foi possível criar a vinculação de usuário com tutor");
};
