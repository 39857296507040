import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, LinearProgress } from '@mui/material';
import moment from 'moment'

import { IGoalUserListDetail, IGoalUserItem } from '../../../../types';
import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';
import { getProgressColor } from '../../../../utils/colors';

const GoalUserListDetail: FC<IGoalUserListDetail> = (params: IGoalUserListDetail) => {
    const navigate = useNavigate();

    function getActiveTasksLabel(_activeTasks: number) {
        if (_activeTasks == 0) return 'Nenhuma ação ativa'
        else if (_activeTasks == 1) return '1 ação ativa'
        return `${_activeTasks} ações ativas`
    }

    return (
        <Container fluid color={theme.palette.grayLight.main} borderBottomRadius={BORDER_RADIUS} width="100%">
            <Container fluid centered padded>
                {params.goalUser.goals.length == 0 && <Text size={13} medium>Nenhum objetivo criado.</Text>}

                {params.goalUser.goals.length > 0 && <Text size={12} thin>Clique em um objetivo para detalhar</Text>}
                {params.goalUser.goals.map((item: IGoalUserItem, index: number) => {
                    return (
                        <Container key={index} inline spacedBetween width="100%" veryPadded>
                            <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={2}>
                                    <Text size={12} bold color={theme.palette.info.light} style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={() => navigate(`/goals/${item.id}`)}>Objetivo: {item.name}</Text>
                                </Grid>
                                <Grid item xs={2}>
                                    <Text size={12} bold>{item.goalType}</Text>
                                </Grid>
                                <Grid item xs={2}>
                                    <Text size={12} bold>{getActiveTasksLabel(item.activeTasks)}</Text>
                                </Grid>
                                <Grid item xs={2}>
                                    <Text size={12} bold>Data limite: {moment(item.deadline).format('DD/MM/YYYY').toString()}</Text>
                                </Grid>
                                <Grid item xs={4}>
                                    <Container inline verticalCentered>
                                        <Text size={12} bold>Andamento: </Text>
                                        <Spacing left={PADDING} />
                                        <Container fluid width='200px' verticalCentered>
                                            <LinearProgress
                                                variant="determinate"
                                                value={item.percentage}
                                                style={{
                                                    height: 6,
                                                    borderRadius: BORDER_RADIUS,
                                                    backgroundColor: theme.palette.background.paper,
                                                }}
                                                color={getProgressColor(item.percentage)} />
                                        </Container>
                                        <Spacing left={PADDING} />
                                        <Text size={12} bold>{item.percentage.toFixed(2).replace('.', ',')}% concluído</Text>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Container>
                    );
                })}
            </Container>

        </Container >
    );
}

export default GoalUserListDetail;