import React, { FC, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, SelectChangeEvent } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon, PersonAddAlt1 as PersonAddAlt1Icon, Report as ReportIcon, Search as SearchIcon } from '@mui/icons-material'
import moment from 'moment'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Confirmation, Container, GoalsActive, Image, Information, Input, List, NewUserModal, Page, Select, Spacing, Text, theme, UserCard } from '../../components'
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information'
import { useUsers } from '../../hooks/users';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IApprovalRequest, IGoalUser, IListAction, IListHeader, ISelectValue, ITeam, IUser } from '../../types'
import { isUser } from '../../types/hooks/users'
import { downloadCSVFromJson } from '../../utils/converters'
import { normalizeString } from '../../utils/stringFormatters'

const usersPerRowList: ISelectValue[] = [
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '30', value: '30' },
    { label: '60', value: '60' }];

const Users: FC = () => {
    const { hideConfirmation, showConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const { users, loading, fetchUsers, searchUser, setSearchUser, usersPerRow, setUsersPerRow, paginate, setPaginate, deleteUser, sendNotification } = useUsers();

    const [newUserModalVisible, setNewUserModalVisible] = useState<boolean>(false);
    const [userToEdit, setUserToEdit] = useState<IUser | undefined>(undefined);
    const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);
    const [informationModal, setInformationModal] = useState<boolean>(false);
    const [informationModalTitle, setInformationModalTitle] = useState<string>('');
    const [informationModalMessage, setInformationModalMessage] = useState<string>('');
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);

    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

    useEffect(() => {
        if (!loading) {
            fetchUsers();
        }
    }, []);

    useEffect(() => {
        filterUsers()
    }, [users])

    useEffect(() => {
        filterUsers(searchUser);
    }, [usersPerRow, paginate, searchUser])

    useEffect(() => {
        if (!newUserModalVisible)
            setUserToEdit(undefined);
    }, [newUserModalVisible])

    useEffect(() => {
        if (!informationModal)
            setInformationModalMessage('');
    }, [informationModal])

    const filterUsers = (_search: string = '') => {
        setFilteredUsers([])
        let auxUsers = [...users]

        setTimeout(() => {
            if (_search != '') {
                auxUsers = auxUsers.filter((item: IUser) =>
                    (item.profile.surname && normalizeString(item.profile.surname).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.position && normalizeString(item.position.description).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.teams && item.teams[0] && normalizeString(item.teams[0].description).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredUsers([...auxUsers])
        }, 0)
    }

    const usersListAction = (_userId: string, _actionType: IListAction) => {
        if (_actionType.type == 'delete') {
            setSelectedUserId(_userId);
            setDeleteConfirmationModal(true);
        }
        else if (_actionType.type == 'edit')
            editUser(_userId);
        else if (_actionType.type == 'report')
            reportUser(_userId);
    }


    const onDeleteUser = async () => {
        if (selectedUserId) {
            const userId: string = selectedUserId;

            await deleteUser(userId);

            setDeleteConfirmationModal(false);
            setInformationModalTitle('Exclusão de usuário');
            setInformationModalMessage('Usuário excluído com sucesso.');
            setTimeout(() => {
                setInformationModal(true);
            }, 100);
        }
    }

    const editUser = (_userId: string) => {
        const auxUser: IUser | undefined = users.find((item: IUser) => item.id == _userId);
        if (auxUser) {
            setUserToEdit(auxUser);
            setTimeout(() => {
                setNewUserModalVisible(true);
            }, 500);
        }
    }

    const reportUser = (_userId: string) => {
        const auxUser: IUser | undefined = users.find((item: IUser) => item.id == _userId);
        if (auxUser) {
            let lastAccess: string | undefined = undefined;
            if (auxUser.lastAccess && moment(auxUser.lastAccess).isValid())
                lastAccess = moment(auxUser.lastAccess).subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss').toString()

            showConfirmation('Após enviar a notificação, o usuário receberá um email alertando-o de seu infrequente acesso no sistema.',
                'Enviar notificação', 'Confirmar', 'Cancelar', async () => {
                    const surname: string = auxUser.profile.surname ? auxUser.profile.surname : ''
                    const subject: string = 'Inatividade do uso do PMP';
                    const message: string = `<p>Olá ${surname != '' ? ', ' + surname : ''}</p>
                                    <p>Verificamos a sua infrequência ao utilizar o PMP. Pedimos que verifique os seus objetivos.</p>
                                    <p>É de muita importância para  Pix Force que todos os seus colaboradores estejam em dia com os planos.</p>
                                    <p>Att, RH.</p>`;


                    await sendNotification(_userId, subject, message);
                    hideConfirmation();

                    showInformation('Notificação enviada com sucesso!');
                });
        }
    }

    const perPageToNumber = () => {
        let perPage: number = 5
        try {
            perPage = parseInt(usersPerRow);
        } catch (err) { }

        return perPage
    }

    function getInitialIndexPaginate() {
        const perPage: number = perPageToNumber();
        return paginate * perPage;
    }

    const headers: IListHeader[] = [
        { label: "Nome", fieldName: "surname", xs: 2, isString: true, sortable: true },
        { label: "Setor", fieldName: "position", xs: 2, isString: true, sortable: true },
        { label: "Equipe", fieldName: "team", xs: 2, isString: true, sortable: true },
        { label: "No. de tutorados", fieldName: "tutoredsCount", xs: 2, isNumber: true, sortable: true },
        { label: "Planos ativos", fieldName: "activeGoals", xs: 1, isNumber: true, sortable: true },
        { label: "Último acesso", fieldName: "lastAccess", xs: 2, isString: true, sortable: true },
        {
            label: "Ações", xs: 1,
            isString: true,
            showActionIfFieldIsNull: ' ',
            actions: (_id: string) => [
                <IconButton key={0} size='small' onClick={() => usersListAction(_id, { type: 'edit' })} color="primary" style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <EditIcon />
                </IconButton>,
                <IconButton key={1} size='small' onClick={() => usersListAction(_id, { type: 'report' })} style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <ReportIcon sx={{ color: theme.palette.warningDark.main }} />
                </IconButton>,
                <IconButton key={2} size='small' onClick={() => usersListAction(_id, { type: 'delete' })} color="error" style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <DeleteIcon />
                </IconButton>
            ]
        }
    ];

    const convertDataToItem = (_user: IUser) => {
        let tutoredsCount: number = 0;
        if (_user.tutoreds)
            tutoredsCount = _user.tutoreds.length;
        let lastAccess: string = 'Nenhum acesso';
        if (_user.lastAccess != 'Nenhum acesso')
            if (_user.lastAccess && moment(_user.lastAccess).isValid())
                lastAccess = moment(_user.lastAccess).subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss').toString()

        return {
            "id": _user.id,
            "surname": _user.profile.surname ? _user.profile.surname : '',
            "position": _user.position ? _user.position.description : 'Setor não encontrado',
            "team": (_user.teams && _user.teams.length > 0) ? _user.teams.map((team: ITeam) => team.description).join(', ') : 'Ainda não possui equipe',
            "tutoredsCount": tutoredsCount,
            "activeGoals": _user.activeGoals || 0,
            "lastAccess": lastAccess,
        }
    }

    const renderItem = (_data: IUser | IApprovalRequest | ITeam | IGoalUser) => {
        if (isUser(_data)) {
            const auxRequest: IUser = _data;

            return (
                <Container fluid  color={theme.palette.grayLight.main} borderBottomRadius={PADDING} padded>
                    <Text bold size={13}>Tutorados: </Text>
                    <Spacing top={VERY_PADDING} />
                    {(!auxRequest.tutoreds || (auxRequest.tutoreds && auxRequest.tutoreds.length == 0)) && <Text size={13}>Nenhum tutorado encontrado.</Text>}

                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                        {auxRequest.tutoreds && auxRequest.tutoreds.length > 0 && auxRequest.tutoreds.map((item: IUser, index: number) => (
                            <Grid key={index} item xs={2} style={{ paddingTop: VERY_PADDING }}>
                                <Container fluid centered>
                                    <UserCard
                                        fontColor={theme.palette.common.black}
                                        avatarSize={60}
                                        user={item}
                                        position={item.position ? item.position : null}
                                        showPosition />
                                    <Container fluid padded flexStart sx={{ paddingLeft: VERY_PADDING * 4 }}>
                                        <GoalsActive count={item.goalsStats ? item.goalsStats.inProgress : 0} />
                                        <Spacing left={VERY_PADDING} />
                                        <GoalsActive count={item.goalsStats ? item.goalsStats.delayed : 0} delayed />
                                    </Container>
                                </Container>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            );
        }
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container>
                <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: theme.palette.tertiary.main }}
                    startIcon={<PersonAddAlt1Icon />}
                    onClick={() => setNewUserModalVisible(true)}>
                    <Text size={13} color={theme.palette.background.paper}>Cadastrar novo usuário</Text>
                </Button>
            </Container>

            <Spacing top={19} />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={18}>Usuários cadastrados</Text>
                </Container>
                <Container inline color={theme.palette.tertiary.main}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Container inline horizontalCentered sx={{ paddingTop: PADDING / 2, paddingRight: VERY_PADDING, paddingBottom: PADDING / 2 }}>
                        <Text bold size={13} color={theme.palette.background.paper}>Exportar para:</Text>
                        <Spacing left={10} />
                        {/* <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}><Text bold size={13} color={theme.palette.background.paper}>PDF</Text></Button>
                        <Spacing left={5} /> */}
                        <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}
                            onClick={() => downloadCSVFromJson('usuarios.csv', users)}><Text bold size={13} color={theme.palette.background.paper}>Excel</Text></Button>
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween flexStart width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchUser"
                                autoFocus
                                value={searchUser}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchUser(event.target.value)}
                                placeHolder='Digite o nome ou equipe do usuário'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />
                        <Container inline horizontalCentered width="100%" flexEnd >
                            <Text bold size={16}>Número de registros em exibição </Text>
                            <Spacing left={PADDING} />
                            <Select
                                value={usersPerRow}
                                onChange={(event: SelectChangeEvent<string | number | null>) => setUsersPerRow(event.target.value as string)}
                                placeHolder='Escolha o tipo mais adequado ao seu objetivo'
                                items={usersPerRowList.map((item: ISelectValue) => ({
                                    label: item.label,
                                    value: item.value
                                }))}
                                backgroundColor={theme.palette.background.default}
                                sx={{ maxHeight: '38px' }} />
                        </Container>
                    </Container>

                    <List
                        headers={headers}
                        data={filteredUsers}
                        loading={loading}
                        total={users.length}
                        perPage={parseInt(usersPerRow)}
                        paginate={paginate}
                        onPreviousPage={() => setPaginate(paginate - 1)}
                        onNextPage={() => setPaginate(paginate + 1)}
                        onAction={usersListAction}
                        dataToItem={(_data: IUser | IApprovalRequest | ITeam) => {
                            if (isUser(_data))
                                return convertDataToItem(_data)
                            return undefined
                        }}
                        onRefresh={() => {
                            fetchUsers();
                        }}
                        detail={renderItem}
                    />
                </Container>
            </Container>

            <NewUserModal
                userToEdit={userToEdit}
                open={newUserModalVisible}
                onClose={() => setNewUserModalVisible(false)}
                onCancel={() => setNewUserModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    setInformationModalTitle(_informationTitle);
                    setInformationModalMessage(_informationMessage);
                    setTimeout(() => {
                        setInformationModal(true);
                    }, 100);
                }} />
            <Information
                open={informationModal}
                title={informationModalTitle}
                message={informationModalMessage}
                onClose={() => setInformationModal(false)}
                onConfirm={() => setInformationModal(false)} />
            <Confirmation
                open={deleteConfirmationModal}
                title={"Excluir usuário"}
                message="Ao excluir o usuário não será possível recuperá-lo."
                confirmMessage={`Deseja realmente confirmar a exclusão?`}
                onClose={() => setDeleteConfirmationModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setDeleteConfirmationModal(false)}
                onConfirm={() => onDeleteUser()}
                confirmLabel="Confirma" />
        </Page >
    )
}

export default Users;