import React, { FC, createContext, useContext, useState } from 'react'

import { createUser, updateUser, getUsers, destroyUser, sendEmail, getUser, getInactivityCount } from '../services/users';
import { createUserHasGuardian } from '../services/usersHasGuardians';
import { createUserHasPosition } from '../services/usersHasPositions';
import { createUserHasPrivilege } from '../services/usersHasPrivileges';
import { createUserHasTeam } from '../services/usersHasTeams';
import { IUser, IUserContext, IUserNew } from '../types'

const UsersContext = createContext<IUserContext>({} as IUserContext);

export const UsersProvider: FC<any> = ({ children }) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchUser, setSearchUser] = useState<string>('');
    const [usersPerRow, setUsersPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);
    const [inactiveCount, setInactiveCount] = useState<number>(0);
    const [loadingInactiveCount, setLoadingInactiveCount] = useState<boolean>(false);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const users: IUser[] = await getUsers();
            setUsers([...users]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchUser = async (_id: string) => {
        const user: IUser = await getUser(_id);
        return user;
    }

    const createNewUser = async (name: string, email: string, password: string) => {
        const firstName: string = name.split(' ')[0];
        const names: string[] = name.split(' ');
        const lastName: string = names.splice(1, names.length).join(' ');

        const payload: IUserNew = {
            firstName: firstName,
            lastName: lastName,
            surname: name,
            email,
            password
        }

        const user = await createUser(payload);

        fetchUsers();
        return user;
    }

    const editUser = async (_id: string, _userProfileId: string, _payload: IUserNew) => {
        try {
            const user = await updateUser(_id, _userProfileId, _payload);

            fetchUsers();
            return user;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteUser = async (_id: string) => {
        const user = await destroyUser(_id);
        fetchUsers();
        return user;
    }

    const createLinkWithPosition = async (_userId: string, _positionId: string) => {
        const id: string = await createUserHasPosition(_userId, _positionId);
        return id;
    };

    const createLinkWithTeam = async (_userId: string, _teamId: string) => {
        const id: string = await createUserHasTeam(_userId, _teamId);
        return id;
    };

    const createLinkWithPrivilege = async (_userId: string, _privilegeId: string) => {
        const id: string = await createUserHasPrivilege(_userId, _privilegeId);
        return id;
    };

    const createLinkWithGuardian = async (_userId: string, _guardianId: string) => {
        const id: string = await createUserHasGuardian(_userId, _guardianId);
        return id;
    };

    const sendNotification = async (_userId: string, _subject: string, _message: string) => {
        await sendEmail(_userId, _subject, _message);
    };

    const fetchInactiveCount = async () => {
        try {
            setLoadingInactiveCount(true);
            const auxInactiveCount = await getInactivityCount();
            setInactiveCount(auxInactiveCount);
            setTimeout(() => {
                setLoadingInactiveCount(false);
            }, 1000);
        } catch (_err) {
            setLoadingInactiveCount(false);
        }
    }

    return (
        <UsersContext.Provider value={{
            users, loading, fetchUsers, fetchUser,
            createNewUser, editUser, deleteUser,
            createLinkWithPosition, createLinkWithTeam, createLinkWithPrivilege, createLinkWithGuardian,
            sendNotification,
            inactiveCount, fetchInactiveCount, loadingInactiveCount,
            searchUser, setSearchUser, usersPerRow, setUsersPerRow, paginate, setPaginate
        }}>{children}</UsersContext.Provider>
    )
}

export function useUsers() {
    const context = useContext(UsersContext);

    if (!context) {
        throw new Error('useusers must be used within an UsersProvider')
    }

    return context;
}