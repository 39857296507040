interface IDepartmentNew {
  description: string;
}

interface IDepartment {
  id: string;
  description: string;
  updatedAt?: string;
  createdAt?: string;
}

interface IDepartmentContext {
  departments: IDepartment[];
  fetchDepartments: () => void;
  loading: boolean;

  fetchDepartment: (_id: string) => Promise<IDepartment>;

  createNewDepartment: (_description: string) => Promise<IDepartment>;
  editDepartment: (_id: string, _payload: IDepartmentNew) => Promise<IDepartment>;
  deleteDepartment: (_id: string) => any;

  searchDepartment: string;
  setSearchDepartment: (_value: string) => void;
  departmentsPerRow: string;
  setDepartmentsPerRow: (_value: string) => void;
  paginate: number;
  setPaginate: (_value: number) => void;
}

export function isDepartment(_data: any): _data is IDepartment {
  return "id" in _data && "description" in _data;
}

export type { IDepartmentNew, IDepartment, IDepartmentContext };
