import { IGoalsStats } from "./goals";
import { IPosition } from "./positions";
import { IPrivilege } from "./privileges";
import { ITeam } from "./teams";

interface IUserNew {
  userId?: string;
  userProfileId?: string;
  firstName?: string;
  lastName?: string;
  surname?: string;
  email?: string;
  password?: string;
}

interface IUserProfile {
  id: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  surname?: string;
  avatarUri?: string | null;
}

interface IUser {
  id: string;
  email: string;
  profile: IUserProfile;
  position?: IPosition;
  privilege?: IPrivilege;
  teams?: ITeam[];
  supervisedTeams?: ITeam[];
  tutoreds?: IUser[];
  guardianId?: string;
  activeGoals: number;
  lastAccess: string;
  goalsStats?: IGoalsStats;
  createdAt: string;
  updatedAt: string;
}

export function isUser(_data: any): _data is IUser {
  return "profile" in _data;
}

interface IUserContext {
  users: IUser[];
  fetchUsers: () => void;
  loading: boolean;

  fetchUser: (_id: string) => Promise<IUser>;

  createNewUser: (name: string, email: string, password: string) => Promise<IUser>;
  editUser: (_id: string, _userProfileId: string, _payload: IUserNew) => Promise<IUser>;
  deleteUser: (_id: string) => any;
  createLinkWithPosition: (_userId: string, _positionId: string) => Promise<string>;
  createLinkWithTeam: (_userId: string, _teamId: string) => Promise<string>;
  createLinkWithPrivilege: (_userId: string, _privilegeId: string) => Promise<string>;
  createLinkWithGuardian: (_userId: string, _guardianId: string) => Promise<string>;
  sendNotification: (_userId: string, _subject: string, _message: string) => void;

  inactiveCount: number;
  fetchInactiveCount: () => void;
  loadingInactiveCount: boolean;

  searchUser: string;
  setSearchUser: (_value: string) => void;
  usersPerRow: string;
  setUsersPerRow: (_value: string) => void;
  paginate: number;
  setPaginate: (_value: number) => void;
}

export type { IUserNew, IUserProfile, IUser, IUserContext };
