import { theme } from "../components";

export const getProgressColorPalette = (_value: number) => {
  if (_value <= 30) return theme.palette.error.main;
  else if (_value > 30 && _value <= 59) return theme.palette.warning.main;
  else if (_value > 59) return theme.palette.success.main;
  return theme.palette.background.paper;
};

export const getProgressColor = (_value: number) => {
  let color:
    | "error"
    | "warning"
    | "success"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | undefined = undefined;

  if (_value <= 30) color = "error";
  else if (_value > 30 && _value <= 59) color = "warning";
  else if (_value > 59) color = "success";
  return color;
};
