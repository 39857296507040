import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { IconButton, InputAdornment, Input, OutlinedInput, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'

import './classes.css'
import logo from '../../assets/logo_dark.png'
import { Container, Image, InputPassword, Page, Spacing, Text, theme } from '../../components'
import { useAuth } from '../../hooks/auth'
import { useInformation } from '../../hooks/information'
import { BORDER_RADIUS, PADDING } from '../../utils/consts'

const Login: React.FC = () => {
    const { login, requestRecoveryPassword } = useAuth()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const onRecovery = async () => {
        try {
            setLoginErrorMessage(null);
            setLoading(true);

            await requestRecoveryPassword(email);

            showInformation('Solicitação de recuperação de senha enviada com sucesso.', undefined, undefined, () => {
                hideInformation();
                setTimeout(() => {
                    navigate('/');
                }, 100);
            });
            setLoading(false);
        } catch (_err) {
            if (_err instanceof Error) {
                setLoginErrorMessage(_err.message)
            }
            else
                setLoginErrorMessage('Ocorreu um erro ao recuperar a senha')

            setLoading(false);
        }
    }

    return (
        <Page color={theme.palette.primary.main}>
            <Container className="background-square-1" />
            <Container className="background-square-2" />

            <Container fluid centered color="#000" height={35}>
                <Text color={theme.palette.background.paper} size={18} bold>Plano de Metas e Performance</Text>
            </Container>

            <Container
                fluid
                width="100%"
                height='100vh'
                centered>
                <Container fluid spacedEvenly hasShadow width='35%' height='75%' color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}
                    style={{
                        maxWidth: '505px',
                        maxHeight: '350px'
                    }}>
                    <Container fluid centered width="100%">
                        <Text bold size={20}>Recuperação de senha</Text>
                    </Container>

                    <form onSubmit={handleSubmit(onRecovery)}>
                        <Container fluid centered veryPadded>
                            <Container fluid width='80%'>
                                <Text size={15}>Para continuarmos com o processo de recadastramento da senha, por favor, informe o seu e-mail.</Text>
                                <Spacing bottom={PADDING} />

                                <Container fluid >
                                    <TextField
                                        className='text-input'
                                        autoFocus
                                        required
                                        id="email"
                                        placeholder="E-mail"
                                        variant="standard"
                                        value={email}
                                        error={errors.email != undefined}
                                        size='small'
                                        style={{
                                            backgroundColor: '#E9E9E9',
                                            border: 'solid 0px transparent',
                                            borderRadius: 6.91,
                                            boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        {...register('email', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event),
                                            required: 'Informe seu e-mail',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Informe um endereço de e-mail válido',
                                            },
                                        })} />
                                    {errors.email && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.email.message as string)}</Text>}
                                </Container>

                                {loginErrorMessage && <Container fluid width='100%' centered >
                                    <Spacing top={PADDING} />
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{loginErrorMessage}</Text>
                                </Container>}

                                <Spacing top={40} />
                                <Container inline spacedAround>
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <LoadingButton
                                            variant="outlined"
                                            size="large"
                                            loading={loading}
                                            disabled={loading}
                                            style={{ minWidth: '120px' }}>
                                            <Text size={13} color={theme.palette.primary.main}>Voltar</Text>
                                        </LoadingButton>
                                    </Link>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={loading}
                                        disabled={loading}
                                        style={{ minWidth: '120px' }}>
                                        <Text size={13} color={theme.palette.background.default}>Solicitar nova senha</Text>
                                    </LoadingButton>
                                </Container>
                            </Container>
                        </Container>
                    </form>
                </Container>
            </Container>

        </Page>
    )
}

export default Login