import React, { FC, createContext, useContext, useState } from 'react'
import { getTeams, createTeam, getTeamMembers, destroyTeam } from '../services/teams';
import { sendEmailToHumanResources } from '../services/users';

import { ITeam, ITeamContext, ITeamNew, IUser } from '../types'

const TeamsContext = createContext<ITeamContext>({} as ITeamContext);

export const TeamsProvider: FC<any> = ({ children }) => {
    const [teams, setTeams] = useState<ITeam[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTeam, setSearchTeam] = useState<string>('');
    const [teamsPerRow, setTeamsPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
    const [members, setMembers] = useState<IUser[]>([]);

    const fetchTeams = async () => {
        setLoading(true);
        try {
            const teams: ITeam[] = await getTeams();
            setTeams([...teams]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const fetchTeamMembers = async (_id: string) => {
        setLoadingMembers(true);
        try {
            const auxMembers: IUser[] = await getTeamMembers(_id);
            setMembers([...auxMembers]);

            setTimeout(() => {
                setLoadingMembers(false);
            }, 1000);
        } catch (_err) {
            setLoadingMembers(false);
        }
    }

    const createNewTeam = async (_name: string, _supervisorId: string, _membersId: string[]) => {
        const payload: ITeamNew = {
            name: _name,
            supervisorId: _supervisorId,
            membersId: _membersId
        }

        const team = await createTeam(payload);

        return team;
    }

    const deleteTeam = async (_id: string) => {
        const team: ITeam = await destroyTeam(_id);
        return team;
    }

    const requestNewMember = async (_userSurname: string, _teamName: string, _memberSurnames: string[], _positions: string[], _departments: string[]) => {
        const subject: string = `Solicitação de novo membro para equipe ${_teamName}`;
        let message: string = `
        <p>Você recebeu uma nova solicitação de inclusão de novo membro.</p>
        <br/>
        <p>Solicitante: ${_userSurname}.</p>
        <p>Equipe: ${_teamName}.</p>
        <p>Membros:</p>`;
        for (let i = 0; i < _memberSurnames.length; i++) {
            message += `<p><strong>${_memberSurnames[i]} - ${_positions[i]} - ${_departments[i]}</strong></p>`
        }
        message += `
        <br/>
        <p>E-mail enviado pelo sistema PMP.</p>
        `;

        await sendEmailToHumanResources(subject, message);
    }

    return (
        <TeamsContext.Provider value={{
            teams, loading, fetchTeams,
            members, loadingMembers, fetchTeamMembers,
            createNewTeam, deleteTeam, searchTeam, setSearchTeam, teamsPerRow, setTeamsPerRow, paginate, setPaginate,
            requestNewMember
        }}>{children}</TeamsContext.Provider>
    )
}

export function useTeams() {
    const context = useContext(TeamsContext);

    if (!context) {
        throw new Error('useTeams must be used within an TeamsProvider')
    }

    return context;
}