import axios, { AxiosResponse } from "axios";

export const API_URL =
  ((window as any)._env_ && (window as any)._env_.REACT_APP_API_URL) ||
  process.env.REACT_APP_API_URL ||
  "http://localhost:5000/";

const api = axios.create();
api.defaults.baseURL = API_URL;

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    let statusCode = error?.response?.status || 401;
    let statusText = error?.response?.data || "Sua sessão expirou";

    if (statusCode == 401 || statusCode == 422) {
      localStorage.clear();
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
      localStorage.clear();
      window.location.reload();
    }

    return Promise.resolve(error);
  }
);

export default api;
