import React from "react";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

import { colors } from "./styles";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
        light: colors.primaryLight,
      },
      secondary: { main: colors.secondary },
      tertiary: { main: colors.tertiary },
      background: {
        default: colors.background,
        paper: colors.backgroundLighter,
      },
      info: { main: colors.info },
      error: { main: colors.error },
      success: { main: colors.success },
      warning: { main: colors.warning },
      warningDark: { main: colors.warningDark },

      gray: { main: colors.gray },
      grayLight: { light: colors.grayLighter, main: colors.grayLight },
    },
    typography: {
      fontFamily: ["Nunito"].join(","),
    },
  },
  ptBR
);

export default theme;
