import React, { FC } from 'react'
import { Autocomplete as MuiAutoComplete, TextField } from '@mui/material'

import { IAutocomplete } from '../../../types';

const Autocomplete: FC<IAutocomplete> = (params: IAutocomplete) => {
    const onChange = (event: any, values: any) => {
        if (params.onChange)
            params.onChange(values as string);
    }

    return (
        <MuiAutoComplete
            className='autocomplete'
            id={params.id}
            freeSolo={!params.requiredExists}
            onChange={onChange}
            disableClearable
            defaultValue={params.defaultValue}
            placeholder={params.placeHolder}
            options={params.options}
            disabled={params.disabled}
            sx={{ paddingRight: 2 }}
            renderInput={(_params: any) => (
                <TextField
                    {..._params}
                    className='text-input'
                    id={params.id}
                    autoFocus={params.autoFocus}
                    required={params.required}
                    placeholder={params.placeHolder}
                    value={params.value}
                    error={params.error}
                    disabled={params.disabled}
                    onChange={params.onChange}
                    variant="standard"
                    size='small'
                    style={{
                        backgroundColor: params.backgroundColor,
                        border: 'solid 0px transparent',
                        borderRadius: 6.91,
                        boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                        minHeight: params.multiline ? '50px' : 'auto',
                        ...params.sx
                    }}
                    InputProps={{
                        ..._params.InputProps,
                        type: 'search',
                        disableUnderline: true,
                        startAdornment: params.startAdornment,
                        endAdornment: params.endAdornment,
                    }}
                />
            )}
        />
    )
}

export default Autocomplete;