import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, IconButton, InputAdornment, Input, OutlinedInput, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'

import './classes.css'
import logo from '../../assets/logo_dark.png'
import { Container, Image, InputPassword, Page, Spacing, Text, theme } from '../../components'
import { useAuth } from '../../hooks/auth'
import { login as loginService } from '../../services/auth'
import { BORDER_RADIUS } from '../../utils/consts'

const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { login } = useAuth()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const onLogin = async () => {
        setLoading(true);
        setLoginErrorMessage(null);

        setTimeout(async () => {
            try {
                const res = await loginService(email, password);
                if (res && res.token) {
                    login(res.user, res.token)
                }

                setLoading(false);
            } catch (_err) {
                if (_err instanceof Error) {
                    console.error(_err)
                    setLoginErrorMessage(_err.message)
                }
                else
                    setLoginErrorMessage('Ocorreu um erro ao efetuar o login')

                setLoading(false);
            }
        }, 1000)
    }

    return (
        <Page color={theme.palette.primary.main}>
            <Container className="background-square-1" />
            <Container className="background-square-2" />

            <Container fluid centered color="#000" height={35}>
                <Text color={theme.palette.background.paper} size={18} bold>Plano de Metas e Performance</Text>
            </Container>

            <Container
                fluid
                width="100%"
                height='100vh'
                centered>

                <Container fluid spacedEvenly hasShadow width='35%' height='75%' color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}
                    style={{
                        maxWidth: '505px',
                        maxHeight: '550px'
                    }}>
                    <Container fluid centered width="100%" >
                        <Image
                            source={logo}
                            height={200 / 1.6268}
                            width={200} />

                        <Spacing top={10} />
                        <Text bold size={20}>Bem vindo ao portal PMP!</Text>
                    </Container>

                    <form onSubmit={handleSubmit(onLogin)}>
                        <Container fluid centered veryPadded>
                            <Container width='80%'>
                                <Container fluid >
                                    <TextField
                                        className='text-input'
                                        autoFocus
                                        required
                                        id="email"
                                        placeholder="E-mail"
                                        variant="standard"
                                        value={email}
                                        error={errors.email != undefined}
                                        size='small'
                                        style={{
                                            backgroundColor: '#E9E9E9',
                                            border: 'solid 0px transparent',
                                            borderRadius: 6.91,
                                            boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        {...register('email', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event),
                                            required: 'Informe seu e-mail',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Informe um endereço de e-mail válido',
                                            },
                                        })} />
                                    {errors.email && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.email.message as string)}</Text>}
                                </Container>

                                <Spacing top={25} />

                                <Container fluid >
                                    <Input
                                        id="passw"
                                        className="text-input"
                                        disableUnderline
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        placeholder='Senha'
                                        size='small'
                                        fullWidth
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    aria-label="passw-togle-visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...register('password', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangePassword(event),
                                            required: 'Informe sua senha'
                                        })}
                                        style={{
                                            backgroundColor: '#E9E9E9',
                                            border: 'solid 0px transparent',
                                            borderRadius: 6.91,
                                            boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                                        }}
                                    />
                                    {errors.password && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.password.message as string)}</Text>}
                                </Container>
                                <Spacing top={10} />

                                <Container fluid width='100%' centered >
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{loginErrorMessage || ''}</Text>
                                </Container>

                                <Container fluid width='100%' flexEnd>
                                    <Link to="/forgot-password">
                                        <Button variant="text"
                                            disableRipple
                                            disableTouchRipple
                                            disableFocusRipple
                                            style={{ backgroundColor: 'transparent' }}><Text color={theme.palette.info.light} style={{ textDecoration: 'none' }}>Recuperar senha</Text></Button>
                                    </Link>
                                </Container>
                            </Container>

                            <Container fluid centered >
                                <Spacing top={40} />
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ width: '270px', height: '56px' }}>
                                    <Text color={theme.palette.background.default}>Entrar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </form>
                </Container>
            </Container >

        </Page >
    )
}

export default Login