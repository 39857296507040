import { AxiosResponse } from "axios";

import api from ".";

const baseUrl: string = "users_has_teams";

export const createUserHasTeam = async (_userId: string, _teamId: string) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    userId: _userId,
    teamId: _teamId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error("Não foi possível criar a vinculação de usuário com equipe");
};

export const deleteUserHasTeam = async (_teamId: string, _userId: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_teamId}/${_userId}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error("Não foi possível apagar a vinculação de usuário com equipe");
};
