import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { ITask, ITaskNew } from "../types";

export const getTasks = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/tasks`);

  if (response && response.request) {
    const status = response.request.status;

    const tasks: ITask[] = [];
    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const task = response.data[i];
        tasks.push({
          id: task.id,
          goalId: task.goalId,
          name: task.name,
          deadline: task.deadline,
          percentage: task.percentage,
          createdAt: task.createdAt,
          updatedAt: task.updatedAt,
        });
      }
    }

    return tasks;
  }

  throw new Error("Não foi possível buscar os tipos de plano");
};

export const createTasks = async (_payload: ITaskNew) => {
  const response: AxiosResponse = await api.post(`/v1_0/tasks`, {
    goalId: _payload.goalId,
    name: _payload.name,
    deadline: moment(_payload.deadline).format("YYYY-MM-DD"),
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const task: ITask = {
        id: response.data.id,
        goalId: response.data.goalId,
        name: response.data.name,
        deadline: response.data.deadline,
        percentage: response.data.percentage,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };
      return task;
    }
  }

  throw new Error("Não foi possível criar uma nova ação.");
};

export const updateTasks = async (_id: string, _payload: ITaskNew) => {
  const response: AxiosResponse = await api.put(`/v1_0/tasks/${_id}`, {
    goalId: _payload.goalId,
    name: _payload.name,
    deadline: moment(_payload.deadline).format("YYYY-MM-DD"),
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const task: ITask = {
        id: response.data.id,
        goalId: response.data.goalId,
        name: response.data.name,
        deadline: response.data.deadline,
        percentage: response.data.percentage,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };
      return task;
    }
  }

  throw new Error("Não foi possível criar uma nova ação.");
};

export const increaseTaskPercentage = async (
  _id: string,
  _percentage: number = 25
) => {
  const response: AxiosResponse = await api.put(
    `/v1_0/tasks/${_id}/increase_percentage/${_percentage}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const task: ITask = {
        id: response.data.id,
        goalId: response.data.goalId,
        name: response.data.name,
        deadline: response.data.deadline,
        percentage: response.data.percentage,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };
      return task;
    }
  }

  throw new Error("Não foi possível aumentar o progresso da ação.");
};
