import React, { FC, useState } from 'react'
import { Collapse, IconButton, LinearProgress } from '@mui/material';
import {
    Circle as CircleIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    LibraryBooks as LibraryBooksIcon
} from '@mui/icons-material';
import moment from 'moment'

import { GoalsListItemTask } from '../../..';
import Container from '../../../atoms/container'
import Text from '../../../atoms/text'
import Spacing from '../../../atoms/spacing'
import theme from '../../../theme'
import { IApprovalRequest, IGoalsListItem, ITask } from '../../../../types'
import { getProgressColor, getProgressColorPalette } from '../../../../utils/colors';
import { BORDER_RADIUS } from '../../../../utils/consts';

const GoalsListItem: FC<IGoalsListItem> = (params: IGoalsListItem) => {
    const { goal } = params;

    const [tasksExpanded, settTasksExpanded] = useState<boolean>(false);

    const predictedEndTypeToMoment = (_predictedEndType: number) => {
        if (_predictedEndType == 0)
            return 'days'
        else if (_predictedEndType == 1)
            return 'weeks'
        else if (_predictedEndType == 2)
            return 'months'
        return 'days'
    }


    const activeTasks = () => {
        let auxActiveGoals: number = 0;
        auxActiveGoals = goal.tasks.filter((item: ITask) => item.percentage < 100).length;
        return auxActiveGoals;
    }

    return (
        <Container fluid width='100%' style={{ minHeight: 100, paddingLeft: 10, paddingTop: 5, paddingRight: 10 }}  >
            <Container
                color={theme.palette.grayLight.main}
                width='100%'
                borderTopRadius={BORDER_RADIUS}>
                <Spacing top={8} />
                <Container
                    className="on-hover"
                    inline centered style={{ cursor: 'pointer' }}
                    onClick={() => settTasksExpanded(!tasksExpanded)}>
                    <Container style={{ flexGrow: 1 }} />
                    <Text bold size={17} style={{ position: 'absolute', textDecoration: 'underline' }}>{goal.name}</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <Container fluid centered borderRadius={10} color={theme.palette.primary.light} style={{ paddingLeft: 10, paddingTop: 2, paddingRight: 10, paddingBottom: 2 }}>
                        <Text light size={12} color={theme.palette.background.paper}>{goal.goalType.description}</Text>
                    </Container>
                    <IconButton size='small' onClick={() => settTasksExpanded(!tasksExpanded)} color="primary">
                        {!tasksExpanded && <ExpandMoreIcon />}
                        {tasksExpanded && <ExpandLessIcon />}
                    </IconButton>
                </Container>
                <Container inline spacedBetween centered style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Container inline centered>
                        <CircleIcon style={{ fontSize: 13, color: getProgressColorPalette(goal.progress) }} />
                        <Spacing left={3} />
                        <Text thin size={13} >{goal.progress.toFixed(2).replace('.', ',')}% concluído</Text>
                    </Container>

                    {activeTasks() == 0 && <Text thin size={13} >Nenhuma tarefa ativa no objetivo</Text>}
                    {activeTasks() == 1 && <Text thin size={13} >{activeTasks()} tarefa ativa no objetivo</Text>}
                    {activeTasks() > 1 && <Text thin size={13} >{activeTasks()} tarefas ativas no objetivo</Text>}

                    <Text thin size={13} >Início: {moment(goal.startedAt).format('DD/MM/YYYY')}</Text>

                    <Text thin size={13}>
                        Fim previsto: {moment(goal.startedAt).add(goal.predictedEndValue, predictedEndTypeToMoment(goal.predictedEndType)).format('DD/MM/YYYY')}
                    </Text>

                    {!params.noAction && <Container inline>
                        <IconButton disabled={params.disabled} color="info" size="small"
                            onClick={() => params.onEditGoal(goal.id)}>
                            <EditIcon fontSize="small" style={{ fontSize: theme.palette.background.paper }} />
                        </IconButton>
                        <IconButton
                            disabled={params.disabled} color="error" size="small"
                            onClick={() => params.onOpenDeleteConfirmationModal(goal.id)}>
                            <DeleteIcon fontSize="small" style={{ fontSize: theme.palette.background.paper }} />
                        </IconButton>
                    </Container>}
                </Container>

                {!params.noAction && goal.approvalsRequests.find((item: IApprovalRequest) => item.approved && !item.viewed) &&
                    <Container fluid padded>
                        {goal.approvalsRequests.map((item: IApprovalRequest, index: number) => (item.approved && !item.viewed &&
                            <Container key={index} inline>
                                <Text thin size={13}>
                                    Solicitações aceitas: {item.typeDesc}
                                </Text>
                                {item.reply && <Text thin size={13} style={{ paddingLeft: 10 }}>
                                    Resposta: {item.reply}
                                </Text>}
                            </Container>
                        ))}
                    </Container>
                }
            </Container>


            <Collapse in={tasksExpanded}>
                <Container
                    fluid
                    color={theme.palette.gray.main}
                    width='100%'
                    borderBottomRadius={BORDER_RADIUS}>
                    <Container fluid veryPadded>

                        <Container inline style={{ alignItems: 'flex-start' }}>
                            <LibraryBooksIcon style={{ fontSize: 15, color: theme.palette.primary.light }} />
                            <Spacing left={3} />
                            <Text size={13} color={theme.palette.primary.light}>Ações</Text>
                        </Container>
                        <Spacing bottom={8} />

                        {goal.tasks.map((item: ITask, index: number) => (
                            <GoalsListItemTask key={index} task={item} loading={params.loading} disabled={params.disabled} noAction={params.noAction} />
                        ))}
                    </Container>
                </Container>
            </Collapse>

            <Collapse in={!tasksExpanded} >
                <Container
                    fluid
                    color={theme.palette.gray.main}
                    width='100%'
                    borderBottomRadius={BORDER_RADIUS}>
                    <Container fluid veryPadded>
                        <Container inline style={{ alignItems: 'flex-start' }}>
                            <Spacing top={8} />
                            <Text size={13}>Andamento geral do objetivo</Text>
                        </Container>

                        <Container inline style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Text thin size={12}>Andamento</Text>
                            <Container style={{ flexGrow: 1 }} />
                            <Spacing left={8} />
                            <Container fluid width={'85%'}>
                                <LinearProgress
                                    variant="determinate"
                                    value={goal.progress}
                                    style={{
                                        height: 6,
                                        borderRadius: BORDER_RADIUS,
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                    color={getProgressColor(goal.progress)} />
                            </Container>
                            <Container style={{ flexGrow: 1 }} />
                            <Spacing left={8} />
                            <Text thin size={12}>{goal.progress.toFixed(2).replace('.', ',')}% concluído</Text>
                        </Container>
                        <Spacing top={8} />
                    </Container>
                </Container>
            </Collapse>


        </Container>
    )
}

export default GoalsListItem;