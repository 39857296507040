import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppRoutes from './app'
import AuthRoutes from './auth'
import { Confirmation, Information } from '../components'
import { useAuth } from '../hooks/auth'
import { useConfirmation } from '../hooks/confirmation'
import { useInformation } from '../hooks/information'
import { Splash } from '../pages'
import { REDSOFT_TOKEN, REDSOFT_USER } from '../plugins/localStorage.consts'
import { IUser } from '../types'

const Routes: React.FC = () => {
    const { token, login } = useAuth();
    const { informationOpen, informationTitle, informationMessage, informationOnConfirmClick, hideInformation } = useInformation();
    const {
        confirmationOpen, confirmationTitle, confirmationMessage,
        confirmationConfirmLabel, confirmationCancelLabel,
        confirmationOnConfirmClick, confirmationOnCancelClick, hideConfirmation } = useConfirmation();

    const [loading, setLoading] = useState<boolean>(true)
    const [confirmationLoadingAction, setConfirmationLoadingAction] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem(REDSOFT_TOKEN)
        const userStorage = localStorage.getItem(REDSOFT_USER)
        if (userStorage && token) {
            const userParsed = JSON.parse(userStorage)
            const user: IUser = {
                id: userParsed.id,
                email: userParsed.email,
                profile: userParsed.profile,
                // privilege: userParsed.privilege,
                // position: userParsed.position,
                // teams: userParsed.teams || [],
                // guardianId: userParsed.guardianId,
                activeGoals: userParsed.activeGoals,
                lastAccess: userParsed.lastAccess,
                createdAt: userParsed.createdAt,
                updatedAt: userParsed.updatedAt
            }
            login(user, token)
        }

        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])

    if (loading) return <Splash />

    return (
        <BrowserRouter>
            {token === '' && <AuthRoutes />}
            {token !== '' && <AppRoutes />}

            <Confirmation
                open={confirmationOpen}
                title={confirmationTitle}
                message={confirmationMessage}
                onClose={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                cancelLabel={confirmationCancelLabel ? confirmationCancelLabel : "Cancelar"}
                onCancel={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                loading={confirmationLoadingAction}
                onConfirm={async () => {
                    setConfirmationLoadingAction(true);
                    if (confirmationOnConfirmClick != undefined) {
                        await confirmationOnConfirmClick();
                    }
                    else hideConfirmation();
                    setConfirmationLoadingAction(false);
                }}
                confirmLabel={confirmationConfirmLabel ? confirmationConfirmLabel : "Confirmar"} />
            <Information
                open={informationOpen}
                title={informationTitle}
                message={informationMessage}
                onClose={() => hideInformation()}
                onConfirm={() => {
                    if (informationOnConfirmClick) informationOnConfirmClick();
                    else hideInformation();
                }} />
        </BrowserRouter>
    );
}

export default Routes