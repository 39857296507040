import { AxiosResponse } from "axios";

import api from ".";

export const createSession = async (_userId: string, token: string) => {
  const response: AxiosResponse = await api.post("/v1_0/sessions", {
    userId: _userId,
    token,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error("Não foi possível criar a sessão");
};
