import React, { FC, createContext, useContext, useState } from 'react'
import { getPrivileges } from '../services/privileges';

import { IPrivilege, IPrivilegeContext } from '../types'

const PrivilegesContext = createContext<IPrivilegeContext>({} as IPrivilegeContext);

export const PrivilegesProvider: FC<any> = ({ children }) => {
    const [privileges, setPrivileges] = useState<IPrivilege[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchPrivilege, setSearchPrivilege] = useState<string>('');
    const [privilegesPerRow, setPrivilegesPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchPrivileges = async () => {
        setLoading(true);
        try {
            const privileges: IPrivilege[] = await getPrivileges();
            setPrivileges([...privileges]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    return (
        <PrivilegesContext.Provider value={{
            privileges, loading, fetchPrivileges, searchPrivilege, setSearchPrivilege, privilegesPerRow, setPrivilegesPerRow, paginate, setPaginate
        }}>{children}</PrivilegesContext.Provider>
    )
}

export function usePrivileges() {
    const context = useContext(PrivilegesContext);

    if (!context) {
        throw new Error('usePrivileges must be used within an PrivilegesProvider')
    }

    return context;
}