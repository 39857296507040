import { stringify } from 'querystring';
import React, { FC, createContext, useState, useContext } from 'react'

import { useGoal } from './goals'
import { ITask, ITaskContext } from '../types'
import { getTasks, increaseTaskPercentage } from '../services/tasks';

const TaskContext = createContext<ITaskContext>({} as ITaskContext);


export const TaskProvider: FC<any> = ({ children }) => {
    const { updateTask } = useGoal();

    const [tasks, setTasks] = useState<ITask[]>([]);

    const fetchTasks = async () => {
        const auxTask: ITask[] = await getTasks();
        setTasks(auxTask)
    }

    const increaseProgress = async (_id: string, _increase: number = 25) => {
        const udpatedTask: ITask = await increaseTaskPercentage(_id, _increase);
        if (udpatedTask) {
            const auxTasks: ITask[] = tasks;
            for (let i = 0; i < auxTasks.length; i++)
                if (auxTasks[i].id == _id) {
                    auxTasks[i].percentage = udpatedTask.percentage;
                }

            updateTask(udpatedTask)
        }
    }

    return (
        <TaskContext.Provider value={{ tasks, fetchTasks, increaseProgress }}>{children}</TaskContext.Provider>
    );
}

export function useTask() {
    const context = useContext(TaskContext);

    if (!context) {
        throw new Error('useTask must be used within an TaskProvider')
    }

    return context;
}