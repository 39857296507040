import { AxiosResponse } from "axios";

import api from ".";
import { createUserProfile, updateUserProfile } from "./usersProfiles";
import {
  IUserNew,
  IUser,
  ITeam,
  IUserProfile,
  IPrivilege,
  IPosition,
  IGoalsStats,
} from "../types";

const baseUrl: string = "users";

export const createUser = async (_user: IUserNew) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    email: _user.email,
    password: _user.password,
    surname: _user.surname,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;
      _user.userId = id;
      const profile: IUserProfile = await createUserProfile(_user);

      const user: IUser = {
        id: response.data.id,
        email: response.data.email,
        profile,
        teams: [],
        tutoreds: [],
        activeGoals: response.data.activeGoals,
        lastAccess: response.data.lastAccess,
        goalsStats: undefined,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };

      return user;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um usuário");
};

export const updateUser = async (
  _id: string,
  _userProfileId: string,
  _user: IUserNew
) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    email: _user.email,
    password: _user.password == "" ? undefined : _user.password,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;
      _user.userId = id;
      const profile: IUserProfile = await updateUserProfile(
        _userProfileId,
        _user
      );

      const user: IUser = {
        id: response.data.id,
        email: response.data.email,
        profile,
        teams: [],
        tutoreds: [],
        activeGoals: response.data.activeGoals,
        lastAccess: response.data.lastAccess,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };

      return user;
    }
  }

  throw new Error("Não foi possível editar um usuário");
};

export const destroyUser = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um usuário");
};

export const getUsers = async () => {
  const response: AxiosResponse = await api.get("/v1_0/users");

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const users: IUser[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        if (data.profile == undefined) continue;
        const user: IUser = convertApiResponseToIUser(data);

        users.push(user);
      }

      return users;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const getUser = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/users/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IUser = convertApiResponseToIUser(response.data);
      return user;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const sendEmail = async (
  _userId: string,
  _subject: string,
  _message: string
) => {
  const url: string = `/v1_0/users/send_email/${_userId}`;
  const payload = { subject: _subject, message: _message };

  const response: AxiosResponse = await api.post(url, payload);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return true;
    }
  }
  throw new Error("Não foi possível enviar a notificação");
};

export const sendEmailToHumanResources = async (
  _subject: string,
  _message: string
) => {
  const url: string = `/v1_0/users/send_email_to_human_resources`;
  const payload = { subject: _subject, message: _message };

  const response: AxiosResponse = await api.post(url, payload);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return true;
    }
  }

  throw new Error("Não foi possível enviar a notificação");
};

export const getInactivityCount = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/users/inactivity_count`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const inactivityCount: number = response.data.count;
      return inactivityCount;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const convertApiResponseToIUser = (data: any) => {
  const teams: ITeam[] = [];
  if (data.teams) {
    for (let j = 0; j < data.teams.length; j++) {
      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };
      if (data.goalsStats) {
        goalsStats.inProgress = data.goalsStats.inProgress;
        goalsStats.delayed = data.goalsStats.delayed;
      }

      const team = data.teams[j];
      teams.push({
        id: team.id,
        description: team.description,
        supervisors: [],
        members: [],
        goalsStats,
      });
    }
  }

  const supervisedTeams: ITeam[] = [];
  if (data.supervisedTeams) {
    for (let j = 0; j < data.supervisedTeams.length; j++) {
      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };
      if (data.goalsStats) {
        goalsStats.inProgress = data.goalsStats.inProgress;
        goalsStats.delayed = data.goalsStats.delayed;
      }

      const team = data.supervisedTeams[j];
      supervisedTeams.push({
        id: team.id,
        description: team.description,
        supervisors: [],
        members: [],
        goalsStats,
      });
    }
  }
  // const routes: IRoute[] = [];
  // if (data.privilege.routes) {
  //   for (let j = 0; j < data.privilege.routes.length; j++) {
  //     const route = data.privilege.routes[j];
  //     routes.push({
  //       id: route.id,
  //       path: route.path,
  //     });
  //   }
  // }
  let privilege: IPrivilege | undefined = undefined;
  if (data.privilege)
    privilege = {
      id: data.privilege.id,
      description: data.privilege.description,
      // routes,
    };
  let position: IPosition | undefined = undefined;
  if (data.position) {
    position = {
      id: data.position.id,
      departmentId: data.position.departmentId,
      description: data.position.description,
      departmentDescription: data.position.departmentDescription,
    };
  }

  let goalsStats: IGoalsStats | undefined = undefined;
  if (data.goalsStats) {
    goalsStats = {
      inProgress: data.goalsStats.inProgress,
      delayed: data.goalsStats.delayed,
    };
  }

  const tutoreds: IUser[] = [];
  if (data.tutoreds) {
    for (let i = 0; i < data.tutoreds.length; i++) {
      const tutored = data.tutoreds[i];
      tutoreds.push(convertApiResponseToIUser(tutored));
    }
  }

  const user: IUser = {
    id: data.id,
    email: data.email,
    profile: {
      id: data.profile.id,
      firstName: data.profile.firstName,
      lastName: data.profile.lastName,
      surname: data.profile.surname,
      avatarUri: data.profile.avatarUri,
    },
    privilege,
    position,
    teams,
    supervisedTeams,
    guardianId: data.guardianId,
    tutoreds,
    activeGoals: data.activeGoals,
    lastAccess: data.lastAccess,
    goalsStats,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };

  return user;
};
