import React, { FC, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, SelectChangeEvent } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon, PersonAddAlt1 as PersonAddAlt1Icon, Report as ReportIcon, Search as SearchIcon } from '@mui/icons-material'
import moment from 'moment'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Confirmation, Container, Image, Information, Input, List, DepartmentNewModal, Page, Select, Spacing, Text, theme } from '../../components'
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information'
import { useDepartments } from '../../hooks/departments';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IApprovalRequest, IListAction, IListHeader, ISelectValue, ITeam, IDepartment, IGoalUser, IUser } from '../../types'
import { isDepartment } from '../../types/hooks/departments'
import { downloadCSVFromJson } from '../../utils/converters'
import { normalizeString } from '../../utils/stringFormatters'

const departmentsPerRowList: ISelectValue[] = [
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '30', value: '30' },
    { label: '60', value: '60' }];

const Departments: FC = () => {
    const { hideConfirmation, showConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const { departments, loading, fetchDepartments, searchDepartment, setSearchDepartment, departmentsPerRow, setDepartmentsPerRow, paginate, setPaginate, deleteDepartment } = useDepartments();

    const [newDepartmentModalVisible, setNewDepartmentModalVisible] = useState<boolean>(false);
    const [departmentToEdit, setDepartmentToEdit] = useState<IDepartment | undefined>(undefined);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | undefined>(undefined);
    const [informationModal, setInformationModal] = useState<boolean>(false);
    const [informationModalTitle, setInformationModalTitle] = useState<string>('');
    const [informationModalMessage, setInformationModalMessage] = useState<string>('');
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);

    const [filteredDepartments, setFilteredDepartments] = useState<IDepartment[]>([]);

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        filterDepartments()
    }, [departments])

    useEffect(() => {
        filterDepartments(searchDepartment);
    }, [departmentsPerRow, paginate, searchDepartment])

    useEffect(() => {
        if (!newDepartmentModalVisible)
            setDepartmentToEdit(undefined);
    }, [newDepartmentModalVisible])

    useEffect(() => {
        if (!informationModal)
            setInformationModalMessage('');
    }, [informationModal])

    const filterDepartments = (_search: string = '') => {
        setFilteredDepartments([]);
        let auxDepartments = [...departments];
        setTimeout(() => {
            if (_search != '') {
                auxDepartments = auxDepartments.filter((item: IDepartment) =>
                    (item && item.description && normalizeString(item.description).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredDepartments([...auxDepartments])
        }, 0)
    }

    const departmentsListAction = (_departmentId: string, _actionType: IListAction) => {
        if (_actionType.type == 'delete') {
            setSelectedDepartmentId(_departmentId);
            setDeleteConfirmationModal(true);
        }
        else if (_actionType.type == 'edit')
            editDepartment(_departmentId);
    }


    const onDeleteDepartment = async () => {
        if (selectedDepartmentId) {
            const userId: string = selectedDepartmentId;

            await deleteDepartment(userId);

            setDeleteConfirmationModal(false);
            setInformationModalTitle('Exclusão de setor');
            setInformationModalMessage('Setor excluído com sucesso.');
            setTimeout(() => {
                setInformationModal(true);
            }, 100);
        }
    }

    const editDepartment = (_departmentId: string) => {
        const auxDepartment: IDepartment | undefined = departments.find((item: IDepartment) => item.id == _departmentId);
        if (auxDepartment) {
            setDepartmentToEdit(auxDepartment);
            setTimeout(() => {
                setNewDepartmentModalVisible(true);
            }, 500);
        }
    }

    const perPageToNumber = () => {
        let perPage: number = 5
        try {
            perPage = parseInt(departmentsPerRow);
        } catch (err) { }

        return perPage
    }

    function getInitialIndexPaginate() {
        const perPage: number = perPageToNumber();
        return paginate * perPage;
    }

    const headers: IListHeader[] = [
        { label: "Descrição", fieldName: "description", xs: 11, isString: true, sortable: true },
        {
            label: "Ações", xs: 1,
            isString: true,
            showActionIfFieldIsNull: ' ',
            actions: (_id: string) => [
                <IconButton key={0} size='small' onClick={() => departmentsListAction(_id, { type: 'edit' })} color="primary" style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <EditIcon />
                </IconButton>,
                <IconButton key={2} size='small' onClick={() => departmentsListAction(_id, { type: 'delete' })} color="error" style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <DeleteIcon />
                </IconButton>
            ]
        }
    ];

    const convertDataToItem = (_department: IDepartment) => {
        return {
            "id": _department.id,
            "description": _department.description,
        }
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container>
                <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: theme.palette.tertiary.main }}
                    startIcon={<PersonAddAlt1Icon />}
                    onClick={() => setNewDepartmentModalVisible(true)}>
                    <Text size={13} color={theme.palette.background.paper}>Cadastrar novo setor</Text>
                </Button>
            </Container>

            <Spacing top={19} />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={18}>Setores cadastrados</Text>
                </Container>
                <Container inline color={theme.palette.tertiary.main}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Container inline horizontalCentered sx={{ paddingTop: PADDING / 2, paddingRight: VERY_PADDING, paddingBottom: PADDING / 2 }}>
                        <Text bold size={13} color={theme.palette.background.paper}>Exportar para:</Text>
                        <Spacing left={10} />
                        {/* <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}><Text bold size={13} color={theme.palette.background.paper}>PDF</Text></Button>
                        <Spacing left={5} /> */}
                        <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}
                            onClick={() => downloadCSVFromJson('usuarios.csv', departments)}><Text bold size={13} color={theme.palette.background.paper}>Excel</Text></Button>
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween flexStart width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchDepartment"
                                autoFocus
                                value={searchDepartment}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchDepartment(event.target.value)}
                                placeHolder='Digite o nome ou equipe do setor'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />
                        <Container inline horizontalCentered width="100%" flexEnd >
                            <Text bold size={16}>Número de registros em exibição </Text>
                            <Spacing left={PADDING} />
                            <Select
                                value={departmentsPerRow}
                                onChange={(event: SelectChangeEvent<string | number | null>) => setDepartmentsPerRow(event.target.value as string)}
                                placeHolder='Escolha o tipo mais adequado ao seu objetivo'
                                items={departmentsPerRowList.map((item: ISelectValue) => ({
                                    label: item.label,
                                    value: item.value
                                }))}
                                backgroundColor={theme.palette.background.default}
                                sx={{ maxHeight: '38px' }} />
                        </Container>
                    </Container>

                    <List
                        headers={headers}
                        data={filteredDepartments}
                        loading={loading}
                        total={departments.length}
                        perPage={parseInt(departmentsPerRow)}
                        paginate={paginate}
                        onPreviousPage={() => setPaginate(paginate - 1)}
                        onNextPage={() => setPaginate(paginate + 1)}
                        onAction={departmentsListAction}
                        dataToItem={(_data: IUser | IDepartment | IApprovalRequest | ITeam) => {
                            if (isDepartment(_data))
                                return convertDataToItem(_data)
                            return undefined
                        }}
                        onRefresh={() => {
                            fetchDepartments();
                        }}
                    />
                </Container>
            </Container>

            <DepartmentNewModal
                departmentToEdit={departmentToEdit}
                open={newDepartmentModalVisible}
                onClose={() => setNewDepartmentModalVisible(false)}
                onCancel={() => setNewDepartmentModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    setInformationModalTitle(_informationTitle);
                    setInformationModalMessage(_informationMessage);
                    setTimeout(() => {
                        setInformationModal(true);
                    }, 100);
                }} />
            <Information
                open={informationModal}
                title={informationModalTitle}
                message={informationModalMessage}
                onClose={() => setInformationModal(false)}
                onConfirm={() => setInformationModal(false)} />
            <Confirmation
                open={deleteConfirmationModal}
                title={"Excluir setor"}
                message="Ao excluir o setor não será possível recuperá-lo."
                confirmMessage={`Deseja realmente confirmar a exclusão?`}
                onClose={() => setDeleteConfirmationModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setDeleteConfirmationModal(false)}
                onConfirm={() => onDeleteDepartment()}
                confirmLabel="Confirma" />
        </Page >
    )
}

export default Departments;