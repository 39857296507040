import { AxiosResponse } from "axios";

import api from ".";
import { IDashboardUserCount } from "../types";

export const getUsersCount = async () => {
  const response: AxiosResponse = await api.get("/v1_0/dashboard/users_count");

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {      
      const auxUsersCount: IDashboardUserCount = {
        usersCount: response.data.usersCount,
        guardiansCount: response.data.guardiansCount,
        tutoredsCount: response.data.tutoredsCount,
        tutoredWithActiveGoalsCount: response.data.tutoredWithActiveGoalsCount,
      };

      return auxUsersCount;
    }
  }

  throw new Error("Não foi possível buscar os dados de contagem dos usuários");
};
