import { AxiosResponse } from "axios";

import api from ".";
import { IPosition } from "../types";

export const createPosition = async (
  _departmentId: string,
  _description: string
) => {
  const response: AxiosResponse = await api.post("/v1_0/positions", {
    departmentId: _departmentId,
    description: _description,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const position: IPosition = {
        id: response.data.id,
        departmentId: response.data.departmentId,
        description: response.data.description,
        departmentDescription: response.data.departmentDescription,
        updatedAt: response.data.updatedAt,
        createdAt: response.data.createdAt,
      };

      return position;
    }
  }

  throw new Error("Não foi possível criar um novo cargo");
};

export const getPositions = async () => {
  const response: AxiosResponse = await api.get("/v1_0/positions");

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const positions: IPosition[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        const position: IPosition = {
          id: data.id,
          description: data.description,
          departmentId: data.departmentId,
          departmentDescription: data.departmentDescription,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };

        positions.push(position);
      }

      return positions;
    }
  }

  throw new Error("Não foi possível buscar os cargos");
};
