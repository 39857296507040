import { IApprovalRequest } from "./approvalsRequests";
import { IGoalType } from "./goalsTypes";
import { ITask } from "./tasks";

interface IGoalsStats {
  inProgress: number;
  delayed: number;
}

interface IGoalNew {
  id?: string;
  userId: string;
  guardianId: string;
  name: string;
  description?: string;
  startedAt: string;
  predictedEndType: number;
  predictedEndValue: number;
  goalTypeId: string;
}

interface IGoal {
  id: string;
  userId: string;
  guardianId: string;
  name: string;
  description?: string;
  startedAt: string;
  predictedEndType: number;
  predictedEndValue: number;
  predictedEndTypeDesc: string;
  goalTypeId: string;
  progress: number;
  goalType: IGoalType;
  tasks: ITask[];
  createdAt: string;
  updatedAt: string;
  approvalsRequests: IApprovalRequest[];
}

interface IGoalUserItem {
  id: string;
  name: string;
  goalType: string;
  activeTasks: number;
  deadline: string;
  percentage: number;
}

interface IGoalUser {
  id: string;
  surname: string;
  positions: string;
  departments: string;
  activeGoals: number;
  lastAccess: string;
  goals: IGoalUserItem[];
}

export function isGoalUser(_data: any): _data is IGoalUser {
  return "positions" in _data;
}

interface IGoalContext {
  myGoals: IGoal[];
  loadingMyGoals: boolean;
  fetchMyGoals: (_userId: string) => void;

  goalsByUser: IGoalUser[];
  loadingGoalsByUser: boolean;
  fetchGoalsByUser: () => void;
  searchGoalByUser: string;
  setSearchGoalByUser: (_searchGoalByUser: string) => void;
  filterGoalByUser: string;
  setFilterGoalByUser: (_filterGoalByUser: string) => void;
  paginateGoalByUser: number;
  setPaginateGoalByUser: (_paginate: number) => void;

  stats: IGoalsStats;
  loadingStats: boolean;
  fetchGoalsStats: () => void;

  fetchGoal: (_id: string) => Promise<IGoal>;

  destroyGoal: (_id: string) => Promise<void>;
  updateTask: (_task: ITask) => void;
  activeTasks: (_goal: IGoal) => number;
  updateApprovalRequest: (
    _goalId: string,
    _approvalRequest: IApprovalRequest
  ) => void;
}

export type {
  IGoalsStats,
  IGoalNew,
  IGoal,
  IGoalUserItem,
  IGoalUser,
  IGoalContext,
};
