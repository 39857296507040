import React, { FC, createContext, useContext, useState } from 'react'
import { getUsersCount } from '../services/dashboard';

import { IDashboardUserCount, IDashboardContext } from '../types'

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

export const DashboardProvider: FC<any> = ({ children }) => {
    const [usersCount, setUsersCount] = useState<IDashboardUserCount>({
        usersCount: 0,
        guardiansCount: 0,
        tutoredsCount: 0,
        tutoredWithActiveGoalsCount: 0
    });
    const [loadingUsersCount, setLoadingUsersCount] = useState<boolean>(true);

    const fetchUsersCount = async () => {
        setLoadingUsersCount(true);
        try {
            const auxUsersCount: IDashboardUserCount = await getUsersCount();
            setUsersCount(auxUsersCount);

            setTimeout(() => {
                setLoadingUsersCount(false);
            }, 1000);
        } catch (_err) {
            setLoadingUsersCount(false);
        }
    }

    return (
        <DashboardContext.Provider value={{
            usersCount, loadingUsersCount, fetchUsersCount
        }}>{children}</DashboardContext.Provider>
    )
}

export function useDashboard() {
    const context = useContext(DashboardContext);

    if (!context) {
        throw new Error('useDashboard must be used within an DashboardProvider')
    }

    return context;
}