import { AxiosResponse } from "axios";

import api from ".";
import { createTeamHasSupervisor } from "./teamsHasSupervisors";
import { createUserHasTeam } from "./usersHasTeams";
import { IUserProfile, ITeam, ITeamNew, IGoalsStats, IUser } from "../types";
import { convertApiResponseToIUser } from "./users";

const baseUrl: string = "teams";

export const createTeam = async (payload: ITeamNew) => {
  const response: AxiosResponse = await api.post(`v1_0/${baseUrl}`, {
    description: payload.name,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };
      if (response.data.goalsStats) {
        goalsStats.inProgress = response.data.goalsStats.inProgress;
        goalsStats.delayed = response.data.goalsStats.delayed;
      }

      const team: ITeam = {
        id: response.data.id,
        description: response.data.description,
        avatarUri: response.data.avatarUri,
        supervisors: [],
        members: [],
        updatedAt: response.data.updatedAt,
        createdAt: response.data.createdAt,
        goalsStats,
      };

      await createTeamHasSupervisor(payload.supervisorId, team.id);
      for (let i = 0; i < payload.membersId.length; i++) {
        const userId: string = payload.membersId[i];
        await createUserHasTeam(userId, team.id);
      }

      return team;
    }
  }

  throw new Error("Não foi possível criar uma nova equipe");
};

export const destroyTeam = async (id: string) => {
  const response: AxiosResponse = await api.delete(`v1_0/${baseUrl}/${id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };
      if (response.data.goalsStats) {
        goalsStats.inProgress = response.data.goalsStats.inProgress;
        goalsStats.delayed = response.data.goalsStats.delayed;
      }

      const team: ITeam = {
        id: response.data.id,
        description: response.data.description,
        avatarUri: response.data.avatarUri,
        supervisors: [],
        members: [],
        updatedAt: response.data.updatedAt,
        createdAt: response.data.createdAt,
        goalsStats,
      };
      return team;
    }
  }

  throw new Error("Não foi possível criar uma nova equipe");
};

export const getTeams = async () => {
  const response: AxiosResponse = await api.get(`v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const teams: ITeam[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        const supervisors: IUserProfile[] = [];
        if (data.supervisors) {
          for (let j = 0; j < data.supervisors.length; j++) {
            const team = data.supervisors[j];
            supervisors.push({
              id: team.id,
              userId: team.userId,
              firstName: team.firstName,
              lastName: team.lastName,
              surname: team.surname,
              avatarUri: team.avatarUri,
            });
          }
        }

        const members: IUserProfile[] = [];
        if (data.members) {
          for (let j = 0; j < data.members.length; j++) {
            const team = data.members[j];
            members.push({
              id: team.id,
              userId: team.userId,
              firstName: team.firstName,
              lastName: team.lastName,
              surname: team.surname,
              avatarUri: team.avatarUri,
            });
          }
        }

        let goalsStats: IGoalsStats = {
          inProgress: 0,
          delayed: 0,
        };
        if (data.goalsStats) {
          goalsStats.inProgress = data.goalsStats.inProgress;
          goalsStats.delayed = data.goalsStats.delayed;
        }

        const team: ITeam = {
          id: data.id,
          description: data.description,
          avatarUri: data.avatarUri,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          supervisors,
          members,
          goalsStats,
        };

        teams.push(team);
      }

      return teams;
    }
  }

  throw new Error("Não foi possível buscar as equipes");
};

export const getTeamsBySupervisorId = async (_supervisorId: string) => {
  const response: AxiosResponse = await api.get(`v1_0/${baseUrl}/supervisor/${_supervisorId}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const teams: ITeam[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        const supervisors: IUserProfile[] = [];
        if (data.supervisors) {
          for (let j = 0; j < data.supervisors.length; j++) {
            const team = data.supervisors[j];
            supervisors.push({
              id: team.id,
              userId: team.userId,
              firstName: team.firstName,
              lastName: team.lastName,
              surname: team.surname,
              avatarUri: team.avatarUri,
            });
          }
        }

        const members: IUserProfile[] = [];
        if (data.members) {
          for (let j = 0; j < data.members.length; j++) {
            const team = data.members[j];
            members.push({
              id: team.id,
              userId: team.userId,
              firstName: team.firstName,
              lastName: team.lastName,
              surname: team.surname,
              avatarUri: team.avatarUri,
            });
          }
        }

        let goalsStats: IGoalsStats = {
          inProgress: 0,
          delayed: 0,
        };

        if (data.goalsStats) {
          goalsStats.inProgress = data.goalsStats.inProgress;
          goalsStats.delayed = data.goalsStats.delayed;
        }

        const team: ITeam = {
          id: data.id,
          description: data.description,
          avatarUri: data.avatarUri,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          supervisors,
          members,
          goalsStats,
        };

        teams.push(team);
      }

      return teams;
    }
  }

  throw new Error("Não foi possível buscar as equipes");
};

export const getTeamMembers = async (_teamId: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/teams/members/${_teamId}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const members: IUser[] = [];
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const member = response.data[i];

          members.push(convertApiResponseToIUser(member));
        }
      }

      return members;
    }
  }

  throw new Error("Não foi possível recuperar os membros da equipe");
};

export const updateTeamAvatar = async (_id: string, _base64: string) => {
  const response: AxiosResponse = await api.put(`/v1_0/teams/${_id}/avatar_uri`, {
    avatar_uri: _base64,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const supervisors: IUserProfile[] = [];
      if (response.data.supervisors) {
        for (let j = 0; j < response.data.supervisors.length; j++) {
          const team = response.data.supervisors[j];
          supervisors.push({
            id: team.id,
            userId: team.userId,
            firstName: team.firstName,
            lastName: team.lastName,
            surname: team.surname,
            avatarUri: team.avatarUri,
          });
        }
      }

      const members: IUserProfile[] = [];
      if (response.data.members) {
        for (let j = 0; j < response.data.members.length; j++) {
          const team = response.data.members[j];
          members.push({
            id: team.id,
            userId: team.userId,
            firstName: team.firstName,
            lastName: team.lastName,
            surname: team.surname,
            avatarUri: team.avatarUri,
          });
        }
      }

      let goalsStats: IGoalsStats = {
        inProgress: 0,
        delayed: 0,
      };
      if (response.data.goalsStats) {
        goalsStats.inProgress = response.data.goalsStats.inProgress;
        goalsStats.delayed = response.data.goalsStats.delayed;
      }

      const team: ITeam = {
        id: response.data.id,
        description: response.data.description,
        avatarUri: response.data.avatarUri,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
        supervisors,
        members,
        goalsStats,
      };

      return team;
    }
  }

  throw new Error("Não foi possível atualizar a foto do usuário");
};
