import React, { FC } from 'react'
import { Avatar } from '@mui/material';
import { AccountCircleOutlined as AccountIcon } from '@mui/icons-material';

import Container from '../../../atoms/container';
import Text from '../../../atoms/text';
import { IUserCard } from '../../../../types/components/molecules/users';
import { API_URL } from '../../../../services';

const UserCard: FC<IUserCard> = (params: IUserCard) => {
    const avatarSize = params.avatarSize || 40;

    return (
        <Container inline centered>
            {params.user.profile.avatarUri == '' && <AccountIcon style={{ fontSize: avatarSize, marginRight: 10, color: params.fontColor }} />}
            {params.user.profile.avatarUri != '' && <Avatar src={`${API_URL}/v1_0/users_profiles/images/${params.user.profile.avatarUri}`}
                style={{ marginRight: 10 }}
                sx={{ width: avatarSize, height: avatarSize }} />}

            <Container fluid >
                {params.user && <Text bold size={13} color={params.fontColor}>{params.user.profile.surname ? params.user.profile.surname.trim() : ''}</Text>}
                {params.showPosition && params.position &&
                    <Text size={12} color={params.fontColor}>
                        {`${params.position.description.trim()} - ${params.position.departmentDescription.trim()}`}
                    </Text>}
                {params.isGuardian && <Text size={12} color={params.fontColor}>
                    Tutor
                </Text>}
            </Container>
        </Container>
    )
}

export default UserCard;