import React, { FC } from 'react'
import { IconButton, LinearProgress } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import moment from "moment";

import Container from '../../../atoms/container'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import theme from '../../../theme'
import { useTask } from '../../../../hooks/tasks';
import { IGoalsListItemTask, ITask } from '../../../../types';
import { getProgressColor } from '../../../../utils/colors';
import { BORDER_RADIUS } from '../../../../utils/consts';

const GoalListItemTask: FC<IGoalsListItemTask> = (params: IGoalsListItemTask) => {
    const task: ITask = params.task;

    const { increaseProgress } = useTask()

    return (
        <Container fluid>
            <Container inline style={{ alignItems: 'flex-start' }}>
                <Spacing top={8} />
                <Text bold size={12} style={{ fontWeight: 500 }}>{task.name}</Text>
                <Spacing left={8} />
                {!params.noAction && task.percentage < 100 && <IconButton
                    disabled={params.loading}
                    size="small"
                    onClick={() => increaseProgress(task.id, 25)}
                    style={{ backgroundColor: theme.palette.primary.light, width: 15, height: 15 }}>
                    <AddIcon style={{ fontSize: 15, color: theme.palette.background.paper }} />
                </IconButton>}
                <Spacing left={8} />
                {!params.noAction && task.percentage > 0 && <IconButton
                    disabled={params.loading}
                    size="small"
                    onClick={() => increaseProgress(task.id, -25)}
                    style={{ backgroundColor: theme.palette.primary.light, width: 15, height: 15 }}>
                    <RemoveIcon style={{ fontSize: 15, color: theme.palette.background.paper }} />
                </IconButton>}
            </Container>

            <Container inline style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Text thin size={12}>Andamento</Text>
                <Container style={{ flexGrow: 1 }} />
                <Spacing left={8} />
                <Container fluid width={'85%'}>
                    <LinearProgress
                        variant="determinate"
                        value={task.percentage}
                        style={{
                            height: 6,
                            borderRadius: BORDER_RADIUS,
                            backgroundColor: theme.palette.background.paper,
                        }}
                        color={getProgressColor(task.percentage)} />
                </Container>
                <Container style={{ flexGrow: 1 }} />
                <Spacing left={8} />
                <Container fluid>
                    <Text thin size={12}>Data limite: {moment(task.deadline, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                    <Text thin size={12}>{task.percentage.toFixed(2).replace('.', ',')}% concluído</Text>
                </Container>
            </Container>
            <Spacing top={8} />
        </Container>
    );
}

export default GoalListItemTask;