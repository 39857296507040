import React, { FC } from 'react'

import { ApprovalsRequestsProvider } from './approvalsRequests'
import { ConfirmationProvider } from './confirmation';
import { DashboardProvider } from './dashboard'
import { DepartmentsProvider } from './departments'
import { GoalProvider } from './goals'
import { GoalTypeProvider } from './goalType'
import { InformationProvider } from './information';
import { PositionsProvider } from './positions'
import { PrivilegesProvider } from './privileges'
import { TaskProvider } from './tasks'
import { TeamsProvider } from './teams'
import { UsersProvider } from './users'

const RegistrationsProvider: FC<any> = ({ children }) => {
    return (
        <ApprovalsRequestsProvider>
            <DashboardProvider>
                <DepartmentsProvider>
                    <GoalTypeProvider>
                        <PositionsProvider>
                            <PrivilegesProvider>
                                <TeamsProvider>
                                    <UsersProvider>
                                        {children}
                                    </UsersProvider>
                                </TeamsProvider>
                            </PrivilegesProvider>
                        </PositionsProvider>
                    </GoalTypeProvider>
                </DepartmentsProvider>
            </DashboardProvider>
        </ApprovalsRequestsProvider>
    )
}

const HooksProvider: FC<any> = ({ children }) => {
    return (
        <GoalProvider>
            <TaskProvider>
                <RegistrationsProvider>
                    {children}
                </RegistrationsProvider>
            </TaskProvider>
        </GoalProvider >)
}

export const FeedbackProvider: FC<any> = ({ children }) => {
    return (
        <InformationProvider>
            <ConfirmationProvider>
                {children}
            </ConfirmationProvider>
        </InformationProvider>
    );
}

export default HooksProvider
