import React, { FC } from 'react'
import { IconButton, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { IInput } from '../../../types';

const Input: FC<IInput> = (params: IInput) => {
    return (
        <TextField
            className='text-input'
            id={params.id}
            autoFocus={params.autoFocus}
            required={params.required}
            placeholder={params.placeHolder}
            value={params.value}
            error={params.error}
            disabled={params.disabled}
            onChange={params.onChange}
            multiline={params.multiline}
            maxRows={4}
            variant="standard"
            size='small'
            autoComplete={params.isPassword ? 'new-password' : ''}
            style={{
                backgroundColor: params.backgroundColor || '#FAFAFA',
                border: 'solid 0px transparent',
                borderRadius: 6.91,
                boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                minHeight: params.multiline ? '50px' : 'auto',
                ...params.sx
            }}
            InputProps={{
                disableUnderline: true,
                startAdornment: params.startAdornment,
                endAdornment: params.isPassword ?
                    <IconButton
                        size="small"
                        aria-label="passw-togle-visibility"
                        onClick={params.handleClickShowPassword}
                        onMouseDown={params.handleClickShowPassword}
                        edge="end"
                    >
                        {params.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    : params.endAdornment,
                ...params.inputProps
            }}

            type={!params.isPassword ? 'text' : params.showPassword ? 'text' : 'password'} />
    )
}

export default Input;