import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, IconButton, Grid, TextField, SelectChangeEvent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddCircle as AddCircleIcon, Delete as DeleteIcon } from '@mui/icons-material'
import moment from 'moment'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Confirmation, Container, DatePicker, Image, Information, Input, Page, Select, Spacing, Text, theme, UserCard } from '../../components'
import { useAuth } from '../../hooks/auth';
import { useGoalType } from '../../hooks/goalType';
import { createGoal, getGoalById, updateGoal } from '../../services/goals'
import { createTasks, updateTasks } from '../../services/tasks'
import { IApprovalRequest, IGoal, IGoalNew, IGoalType, ISelectValue, ITask, ITaskNew } from '../../types'
import { BORDER_RADIUS } from '../../utils/consts'
import { viewApprovalRequest } from '../../services/approvalsRequests'
import { useGoal } from '../../hooks/goals'

const Goal: FC = () => {
    const navigate = useNavigate();
    const { id, approvalRequestId } = useParams();
    const { user, guardian, position } = useAuth();
    const { updateApprovalRequest } = useGoal();
    const { goalsTypes, fetchGoalsTypes } = useGoalType();    

    const [goalName, setGoalName] = useState<string>('');
    const [goalNameError, setGoalNameError] = useState<string | null>(null);
    const [goalType, setGoalType] = useState<string>('');
    const [goalTypeError, setGoalTypeError] = useState<string | null>(null);
    const [startedAt, setStartedAt] = useState<Date | null>(null);
    const [startedAtError, setStartedAtError] = useState<string | null>(null);
    const [predictedEndType, setPredictedEndType] = useState<string | number | null>('');
    const [predictedEndValue, setPredictedEndValue] = useState<string | number | null>(null);
    const [predictedEndValueError, setPredictedEndValueError] = useState<string | null>(null);
    const [tasks, setTasks] = useState<ITaskNew[]>([
        {
            id: undefined,
            name: '',
            deadline: null,
            nameError: null,
            deadlineError: null
        }])
    const [loading, setLoading] = useState<boolean>(false)

    const [cancelConfirmationModal, setCancelConfirmationModal] = useState<boolean>(false)
    const [successfullModal, setSuccessfullModal] = useState<boolean>(false)

    useEffect(() => {
        fetchGoalsTypes();

        if (typeof id === 'string')
            loadGoalToEdit(id);
    }, []);


    useEffect(() => {
        if (predictedEndType == '' || predictedEndType == null) {
            setPredictedEndValue('');
        }
    }, [predictedEndType])

    const handleChangeGoalName = (event: React.ChangeEvent<HTMLInputElement>) => setGoalName(event.target.value);
    const handleChangeGoalType = (event: SelectChangeEvent<string | number | null>) => setGoalType(event.target.value as string);
    const handleChangePredictedEndValue = (event: React.ChangeEvent<HTMLInputElement>) => setPredictedEndValue(event.target.value);
    const handleChangePredictedEndType = (event: SelectChangeEvent<string | number | null>) => setPredictedEndType(event.target.value as string);
    const handleChangeTaskName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const auxTasks: ITaskNew[] = tasks;
        auxTasks[index].name = event.target.value;

        setTasks([...auxTasks]);
    };
    const handleChangeDeadline = (newDate: Date, index: number) => {
        const auxTasks: ITaskNew[] = tasks;
        auxTasks[index].deadline = newDate;

        setTasks([...auxTasks]);
    };

    const loadGoalToEdit = async (_id: string) => {
        setLoading(true);
        try {
            const goalToEdit: IGoal = await getGoalById(_id);

            setTimeout(() => {
                setGoalName(goalToEdit.name);
                setGoalType(goalToEdit.goalTypeId);
                setStartedAt(moment(goalToEdit.startedAt).toDate());
                setPredictedEndType(goalToEdit.predictedEndType);

                setPredictedEndValue(goalToEdit.predictedEndValue);
            }, 100);

            setTasks(goalToEdit.tasks.map((item: ITask) => {
                const taskNew: ITaskNew = {
                    id: item.id,
                    goalId: item.goalId,
                    name: item.name,
                    deadline: moment(item.deadline).toDate(),
                    percentage: item.percentage
                }
                return taskNew;
            }));

            setLoading(false)
        }
        catch (_err) {
            setLoading(false)
        }
    }

    const addNewTask = () => {
        const auxTasks: ITaskNew[] = tasks;
        auxTasks.push({
            name: '',
            deadline: null
        })
        setTasks([...auxTasks]);
    }

    const deleteTask = (index: number) => {
        const auxTasks: ITaskNew[] = [];
        for (let i = 0; i < tasks.length; i++) {
            if (i !== index) auxTasks.push(tasks[i])
        }

        setTasks([...auxTasks]);
    }

    function validate() {
        let isValid = true;
        if (goalName == '') {
            setGoalNameError('Insira o título do seu objetivo');
            isValid = false;
        }
        if (goalType == '') {
            setGoalTypeError('Escolha o tipo mais adequado ao seu objetivo');
            isValid = false;
        }
        if (startedAt == null) {
            setStartedAtError('Defina a data de início do seu objetivo');
            isValid = false;
        }
        if (predictedEndType == null || predictedEndValue == '') {
            setPredictedEndValueError('Defina a duração do seu objetivo');
            isValid = false;
        }
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].name == '') {
                tasks[i].nameError = 'Defina a ação';
                isValid = false;
            }
            if (tasks[i].deadline == null) {
                tasks[i].deadlineError = 'Defina a data limite';
                isValid = false;
            }

            setTasks([...tasks]);
        }

        if (isValid) {
            setGoalNameError(null);
            setGoalTypeError(null);
            setStartedAtError(null);
            setPredictedEndValueError(null);
            for (let i = 0; i < tasks.length; i++)
                tasks[i].nameError = tasks[i].deadlineError = null;
            setTasks([...tasks]);
        }
        return isValid;
    }

    const onSaveGoal = () => {
        if (!validate()) return;
        if (!user || !guardian || !startedAt || predictedEndType == null || predictedEndValue == null) return;

        setLoading(true);

        setTimeout(async () => {
            try {
                let payload: IGoalNew = {
                    userId: user.id,
                    guardianId: guardian.id,
                    name: goalName,
                    startedAt: startedAt.toString(),
                    predictedEndType: parseInt(predictedEndType.toString()),
                    predictedEndValue: parseInt(predictedEndValue.toString()),
                    goalTypeId: goalType
                }

                let newGoal: IGoal | null = null
                if (typeof id !== 'string')
                    newGoal = await createGoal(payload)
                else {
                    newGoal = await updateGoal(id, payload);
                    if (approvalRequestId) {
                        const approvalRequest: IApprovalRequest = await viewApprovalRequest(approvalRequestId);
                        updateApprovalRequest(approvalRequestId, approvalRequest);
                    }
                }

                if (newGoal) {
                    for (let i = 0; i < tasks.length; i++) {
                        const taskPayload: ITaskNew = {
                            goalId: newGoal.id,
                            name: tasks[i].name,
                            deadline: tasks[i].deadline
                        };
                        if (typeof tasks[i].id === 'string') {
                            const taskId: string = tasks[i].id!;
                            await updateTasks(taskId, taskPayload)
                        } else
                            await createTasks(taskPayload)
                    }
                }


                setSuccessfullModal(true);
            } catch (_err) {
                console.error(_err);
                setLoading(false);
            }
        }, 1500);
    }

    const onSuccessfullCreated = () => {
        setLoading(false);
        setSuccessfullModal(false)

        navigate('/goals');
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh', maxWidth: '100%' }}
            centered>
            <AppHeaderToolbar />

            <Container
                fluid
                borderRadius={BORDER_RADIUS}
                width='95%'
                hasShadow

                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={5}>
                    <Container inline spacedAround width={'100%'}>
                        {user && <UserCard fontColor={theme.palette.common.black} user={user} showPosition avatarSize={50} position={position} />}
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50} />
                        {guardian && <UserCard fontColor={theme.palette.common.black} user={guardian} isGuardian avatarSize={50} position={position} />}
                    </Container>
                </Container>


                <Container fluid spacedBetween centered veryPadded
                    width={'100%'}
                    height={'100%'}
                    style={{ minHeight: '40vh', minWidth: '80%' }}>
                    <Container style={{ minWidth: '80%' }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Título do objetivo</Text>
                            <Spacing top={5} />
                            <Input
                                id="goalName"
                                autoFocus
                                required
                                value={goalName}
                                onChange={handleChangeGoalName}
                                placeHolder='Insira o título do seu objetivo'
                                error={goalNameError != null}
                                inputProps={{ disableUnderline: true, }} />
                            {goalNameError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{goalNameError}</Text>}
                        </Container>
                        <Spacing top={30} />
                        <Grid container spacing={4} direction="row" justifyContent="space-around" alignItems="center">
                            <Grid item xs={4}>
                                <Container fluid width={'100%'}>
                                    <Text bold>Tipo de plano</Text>
                                    <Spacing top={5} />
                                    <Select
                                        required
                                        value={goalType}
                                        onChange={handleChangeGoalType}
                                        placeHolder='Escolha o tipo mais adequado ao seu objetivo'
                                        items={goalsTypes.map((item: IGoalType) => ({
                                            label: item.description,
                                            value: item.id
                                        }))}
                                        inputProps={{ disableUnderline: true }} />
                                    {goalTypeError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{goalTypeError}</Text>}
                                </Container>
                            </Grid>

                            <Grid item xs={4}>
                                <Container fluid width={'100%'}>
                                    <Text bold>Data de início</Text>
                                    <Spacing top={5} />
                                    <DatePicker
                                        required
                                        value={startedAt}
                                        setValue={setStartedAt}
                                        label="Data de início"
                                        placeHolder="Defina a data de início do seu objetivo" />
                                    {startedAtError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{startedAtError}</Text>}
                                </Container>
                            </Grid>

                            <Grid item xs={4}>
                                <Container fluid width={'100%'}>
                                    <Text bold>Previsão de fim</Text>
                                    <Spacing top={5} />
                                    <Container inline width={'100%'}>
                                        <Container fluid width={'70%'}>
                                            <Input
                                                required
                                                value={predictedEndValue}
                                                onChange={handleChangePredictedEndValue}
                                                placeHolder='Defina a duração do seu objetivo'
                                                disabled={predictedEndType == ''}
                                                inputProps={{ disableUnderline: true }} />
                                            {predictedEndValueError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{predictedEndValueError}</Text>}
                                        </Container>
                                        <Container fluid style={{ minWidth: '30%' }}>
                                            <Select
                                                required
                                                value={predictedEndType}
                                                placeHolder='Duração'
                                                items={predictedEndTypes}
                                                onChange={handleChangePredictedEndType}

                                                backgroundColor={theme.palette.primary.light}
                                                fontColor={theme.palette.background.paper}
                                                inputProps={{ disableUnderline: true }} />
                                        </Container>
                                    </Container>
                                </Container>
                            </Grid>
                        </Grid>
                        <Spacing top={30} />

                        {tasks.map((item: ITaskNew, index: number) => (
                            <Grid key={index} container spacing={4} justifyContent="space-around" alignItems="center">
                                <Grid item xs={8}>
                                    <Container fluid width={'100%'}>
                                        {index == 0 && <Text bold>Ações</Text>}
                                        <Spacing top={index == 0 ? 5 : 20} />
                                        <Input
                                            required
                                            value={item.name}
                                            placeHolder='Insira a ação desejada'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeTaskName(event, index)}
                                            inputProps={{ disableUnderline: true, }} />
                                        {item.nameError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{item.nameError}</Text>}
                                    </Container>
                                </Grid>

                                <Grid item xs={4}>
                                    <Container fluid width={'100%'}>
                                        {index == 0 && <Text bold>Data limite</Text>}
                                        <Spacing top={index == 0 ? 5 : 20} />
                                        <Container inline width="auto" spacedBetween>
                                            <DatePicker
                                                required
                                                value={item.deadline}
                                                setValue={(newDate) => newDate && handleChangeDeadline(newDate, index)}
                                                label="Data limite"
                                                placeHolder="Data limite para conclusão de tarefa" />
                                            <Spacing left={10} />
                                            <IconButton color="error" size="small" onClick={() => deleteTask(index)}>
                                                <DeleteIcon fontSize="small" style={{ fontSize: theme.palette.background.paper }} />
                                            </IconButton>
                                        </Container>
                                        {item.deadlineError && <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{item.deadlineError}</Text>}
                                    </Container>
                                </Grid>
                            </Grid>))}

                        <Spacing top={20} />
                        <Button
                            variant="text"
                            startIcon={<AddCircleIcon style={{ color: theme.palette.primary.light }} />}
                            onClick={() => addNewTask()}
                            style={{ backgroundColor: 'transparent' }}>
                            <Text size={12} bold color={theme.palette.primary.light}> Adicionar ação</Text>
                        </Button>

                        <Spacing top={30} />
                        <Container inline spacedEvenly veryPadded centered>
                            <Button variant="outlined" color="primary" size="small" style={{ borderColor: theme.palette.primary.light, width: 200, height: 35 }}
                                onClick={() => setCancelConfirmationModal(true)}>
                                <Text bold size={12} color={theme.palette.primary.light}>Cancelar</Text>
                            </Button>

                            <LoadingButton
                                variant="contained"
                                size="small"
                                loading={loading}
                                disabled={loading}
                                style={{ backgroundColor: loading ? theme.palette.grayLight.main : theme.palette.primary.light, width: 200, height: 35 }}
                                onClick={() => onSaveGoal()}>
                                <Text bold size={12} color={theme.palette.background.paper}>Concluir</Text>
                            </LoadingButton>
                        </Container>
                    </Container>
                </Container >
            </Container>

            <Confirmation
                open={cancelConfirmationModal}
                title="Cancelar Operação"
                message="Ao cancelar a operação, as ações já realizadas não serão salvas."
                confirmMessage='Deseja confirmar o cancelamento?'
                onClose={() => setCancelConfirmationModal(false)}
                onCancel={() => setCancelConfirmationModal(false)}
                cancelLabel="Cancelar"
                onConfirm={() => {
                    setCancelConfirmationModal(false);
                    navigate('/goals');
                }}
                confirmLabel="Concluir" />
            <Information
                open={successfullModal}
                message='Informações salvas com sucesso!'
                onClose={() => setSuccessfullModal(false)}
                onConfirm={() => onSuccessfullCreated()} />
        </Page >
    )
}

export default Goal;

const predictedEndTypes: ISelectValue[] = [
    {
        label: 'Dias',
        value: '0'
    }, {
        label: 'Semanas',
        value: '1'
    }, {
        label: 'Meses',
        value: '2'
    }];