import { stringify } from 'querystring';
import React, { FC, createContext, useState, useContext } from 'react'

import { IGoalType, IGoalTypeContext } from '../types'
import { getGoalsTypes } from '../services/goalsTypes';

const GoalTypeContext = createContext<IGoalTypeContext>({} as IGoalTypeContext);


export const GoalTypeProvider: FC<any> = ({ children }) => {
    const [goalsTypes, setGoalsTypes] = useState<IGoalType[]>([]);

    const fetchGoalsTypes = async () => {
        const auxGoalType: IGoalType[] = await getGoalsTypes();
        setGoalsTypes(auxGoalType)
    }

    return (
        <GoalTypeContext.Provider value={{ goalsTypes, fetchGoalsTypes }}>{children}</GoalTypeContext.Provider>
    );
}

export function useGoalType() {
    const context = useContext(GoalTypeContext);

    if (!context) {
        throw new Error('useGoalType must be used within an GoalTypeProvider')
    }

    return context;
}