import React, { FC, useEffect, useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { Search as SearchIcon, Report as ReportIcon } from '@mui/icons-material'
import moment from 'moment'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Container, GoalUserListDetail, Image, Input, Page, Spacing, Text, theme, List } from '../../components'
import { useAuth } from '../../hooks/auth';
import { useConfirmation } from '../../hooks/confirmation'
import { useGoal } from '../../hooks/goals';
import { useInformation } from '../../hooks/information'
import { useUsers } from '../../hooks/users';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IApprovalRequest, ISelectValue, IGoalUser, IUser, IListHeader, ITeam } from '../../types'
import { isGoalUser } from '../../types/hooks/goals'
import { downloadCSVFromJson } from '../../utils/converters'
import { normalizeString } from '../../utils/stringFormatters'

const PER_ROWS = 15;

const Goals: FC = () => {
    const { user } = useAuth();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const {
        goalsByUser, loadingGoalsByUser, fetchGoalsByUser,
        searchGoalByUser, setSearchGoalByUser,
        filterGoalByUser, setFilterGoalByUser,
        paginateGoalByUser, setPaginateGoalByUser } = useGoal();
    const { showInformation } = useInformation();
    const { users, fetchUsers, sendNotification } = useUsers();

    const [filteredGoals, setFilteredGoals] = useState<IGoalUser[]>([]);

    useEffect(() => {
        fetchUsers();
        fetchGoalsByUser();
    }, [])

    useEffect(() => {
        filterRequests()
    }, [goalsByUser])

    useEffect(() => {
        filterRequests(searchGoalByUser);
    }, [filterGoalByUser, paginateGoalByUser, searchGoalByUser])

    const filterRequests = (_search: string = '') => {
        setFilteredGoals([])
        let auxGoalsByUser = [...goalsByUser];

        setTimeout(() => {
            if (_search != '') {
                auxGoalsByUser = auxGoalsByUser.filter((item: IGoalUser) =>
                    (normalizeString(item.surname).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (normalizeString(item.positions).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (normalizeString(item.departments).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredGoals([...auxGoalsByUser]);
        }, 0)

    }

    const perPageToNumber = () => {
        return PER_ROWS;
    }

    function getInitialIndexPaginate() {
        const perPage: number = perPageToNumber();
        return paginateGoalByUser * perPage;
    }

    const convertDataToItem = (_data: IGoalUser) => {
        let surname: string = 'Desconhecido';
        let position: string = 'Desconhecido';
        let department: string = 'Desconhecido';
        let activeGoals: string = 'Desconhecido';
        let lastAccess: string = 'Nenhum acesso';

        if (_data) {
            surname = _data.surname;
            position = _data.positions;
            department = _data.departments;
            if (_data.activeGoals == 0)
                activeGoals = 'Nenhum objetivo ativo';
            else if (_data.activeGoals == 1)
                activeGoals = `${_data.activeGoals} objetivo ativo`;
            else if (_data.activeGoals > 1)
                activeGoals = `${_data.activeGoals} objetivos ativos`;
            if (_data.lastAccess && _data.lastAccess != 'Nenhum acesso')
                lastAccess = moment(_data.lastAccess).format('DD/MM/YYYY HH:mm:ss').toString();
        }

        return { surname, position, department, activeGoals, lastAccess }
    }

    const headers: IListHeader[] = [
        { label: "Nome", fieldName: "surname", xs: 3, isString: true, sortable: true },
        { label: "Cargo", fieldName: "position", xs: 2, isString: true, sortable: true },
        { label: "Setor", fieldName: "department", xs: 2, isString: true, sortable: true },
        { label: "Objetivos ativos", fieldName: "activeGoals", xs: 2, isString: true, sortable: true },
        { label: "Último acesso", fieldName: "lastAccess", xs: 2, isString: true, sortable: true },
        {
            label: "Opções",
            xs: 1,
            isString: true,
            showActionIfFieldIsNull: ' ',
            actions: (_id: string) => [
                <IconButton key={0} size="small" color="warning" style={{ height: '18px', backgroundColor: 'transparent' }}
                    onClick={() => sendNotificationOnClick(_id)}>
                    <ReportIcon sx={{ color: theme.palette.warningDark.main }} />
                </IconButton>
            ],
        },
    ];

    const sendNotificationOnClick = async (_id: string) => {
        if (user) {
            showConfirmation(
                'Após enviar a notificação, o usuário receberá um email alertando-o de seu infrequente acesso no sistema.',
                'Enviar notificação', undefined, undefined,
                async () => {
                    if (user) {
                        const selectedUser: IUser | undefined = users.find((item: IUser) => item.id == _id);
                        if (selectedUser && selectedUser.profile) {
                            try {
                                const surname: string = selectedUser.profile.surname ? selectedUser.profile.surname : ''
                                const subject: string = 'Inatividade do uso do PMP';
                                const message: string = `<p>Olá ${surname != '' ? ', ' + surname : ''}</p>
                                    <p>Verificamos a sua infrequência ao utilizar o PMP. Pedimos que verifique os seus objetivos.</p>
                                    <p>É de muita importância para  Pix Force que todos os seus colaboradores estejam em dia com os planos.</p>
                                    <p>Att, RH.</p>`;

                                await sendNotification(_id, subject, message);
                                hideConfirmation();

                                showInformation('Notificação enviada com sucesso!');
                            } catch (err) {

                            }
                        }
                    }
                }, undefined);
        } else {
            showInformation('Ocorreu um erro ao selecionar o objetivo geral.');
        }
    }

    const listDetail = (_data: IUser | IApprovalRequest | ITeam | IGoalUser) => {
        if (!isGoalUser(_data)) return null;

        return <GoalUserListDetail goalUser={_data} />
    }


    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>Andamentos gerais dos objetivos</Text>
                    <Spacing top={10} />
                    <Text size={18} center>Você pode acompanhar o andamento dos objetivos de cada funcionário individualmente.</Text>
                    <Text size={18}>Utilize a barra de buscas e os filtros caso necessário. </Text>
                </Container>

                <Container inline color={theme.palette.tertiary.main}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Container inline horizontalCentered sx={{ paddingTop: PADDING / 2, paddingRight: VERY_PADDING, paddingBottom: PADDING / 2 }}>
                        <Text bold size={13} color={theme.palette.background.paper}>Exportar para:</Text>
                        <Spacing left={10} />
                        {/* <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}><Text bold size={13} color={theme.palette.background.paper}>PDF</Text></Button>
                        <Spacing left={5} /> */}
                        <Button size="small" variant="outlined" sx={{ borderColor: theme.palette.background.paper, height: 25 }}
                            onClick={() => downloadCSVFromJson('objetivos.csv', goalsByUser)}><Text bold size={13} color={theme.palette.background.paper}>Excel</Text></Button>
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}>
                    <Container inline spacedBetween flexStart width="100%">
                        <Container inline horizontalCentered width="100%" >
                            <Text bold size={16}>Pesquisar: </Text>
                            <Spacing left={PADDING} />
                            <Input
                                id="searchGoalByUser"
                                autoFocus
                                value={searchGoalByUser}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchGoalByUser(event.target.value)}
                                placeHolder='Digite o nome, cargo ou setor do usuário'
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{ width: '50%', height: '25px', }} />
                        </Container>
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50}
                            color='transparent' />
                        <Container inline horizontalCentered width="100%" flexEnd ></Container>
                        {/* <Container inline horizontalCentered width="100%" flexEnd >
                            <Text bold size={16}>Filtros</Text>
                            <Spacing left={PADDING} />
                            <Select
                                value={filterGoalByUser}

                                onChange={(event:
                                    SelectChangeEvent<string | number | null>) => setFilterGoalByUser(event.target.value as string)}
                                items={filterStatusItems.map((item: ISelectValue) => ({
                                    label: item.label,
                                    value: item.value
                                }))}
                                backgroundColor={theme.palette.background.default}
                                sx={{ maxHeight: '38px' }} />
                        </Container> */}
                    </Container>

                    <List
                        headers={headers}
                        data={filteredGoals}
                        loading={loadingGoalsByUser}
                        total={goalsByUser.length}
                        perPage={PER_ROWS}
                        paginate={paginateGoalByUser}
                        onPreviousPage={() => setPaginateGoalByUser(paginateGoalByUser - 1)}
                        onNextPage={() => setPaginateGoalByUser(paginateGoalByUser + 1)}
                        dataToItem={(_data: IUser | IApprovalRequest | ITeam | IGoalUser) => {
                            if (isGoalUser(_data))
                                return convertDataToItem(_data)
                            return undefined
                        }}
                        detail={listDetail}
                        onRefresh={() => fetchGoalsByUser()}
                    />
                </Container>
            </Container>


        </Page >
    )
}

export default Goals;