import React, { FC, useEffect, useState } from 'react'
import { IconButton, SelectChangeEvent } from '@mui/material'
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

import '../classes.css'
import Autocomplete from '../../../atoms/autocomplete'
import Container from '../../../atoms/container'
import Modal from '../../../atoms/modal'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import Input from '../../../atoms/input'
import Select from '../../../atoms/select'
import { useAuth } from '../../../../hooks/auth'
import { useDepartments } from '../../../../hooks/departments'
import { usePrivileges } from '../../../../hooks/privileges'
import { usePositions } from '../../../../hooks/positions'
import { useTeams } from '../../../../hooks/teams'
import { useUsers } from '../../../../hooks/users'
import theme from '../../../theme'
import { IDepartment, IPosition, IPrivilege, IUser, IUserNew, IUserNewModal } from '../../../../types'
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts'

const UsersNewModal: FC<IUserNewModal> = (params: IUserNewModal) => {
    const isEdit = params.userToEdit != undefined;

    const { user, fetchUserData } = useAuth();
    const { createNewUser, editUser,
        createLinkWithPosition, createLinkWithTeam, createLinkWithPrivilege, createLinkWithGuardian,
        users, fetchUsers } = useUsers();
    const { departments, fetchDepartments } = useDepartments();
    const { positions, fetchPositions, createNewPosition } = usePositions();
    const { privileges, fetchPrivileges } = usePrivileges();
    const { teams, fetchTeams } = useTeams();

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [position, setPosition] = useState<string>('');
    const [positionDescription, setPositionDescription] = useState<string>('');
    const [defaultPosition, setDefaultPosition] = useState<string | undefined>(undefined);
    const [positionError, setPositionError] = useState<string | null>(null);
    const [department, setDepartment] = useState<string>('');
    const [departmentError, setDepartmentError] = useState<string | null>(null);
    const [team, setTeam] = useState<string>('');
    const [teamError, setTeamError] = useState<string | null>(null);
    const [guardianId, setGuardianId] = useState<string>('');
    const [guardianIdError, setGuardianIdError] = useState<string | null>(null);
    const [guardianDefault, setGuardianDefault] = useState<string | undefined>(undefined);
    const [privilege, setPrivilege] = useState<string>('');
    const [privilegeError, setPrivilegeError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (params.open == true) {
            fetchDepartments();
            fetchPositions();
            fetchPrivileges();
            fetchTeams();
            fetchUsers();
        }
    }, [params.open]);

    useEffect(() => {
        if (params.userToEdit) {
            setName(params.userToEdit.profile.firstName + ' ' + params.userToEdit.profile.lastName);
            setEmail(params.userToEdit.email);
            if (params.userToEdit.position) {
                setDefaultPosition(params.userToEdit.position.description);
                setPositionDescription(params.userToEdit.position.description);
                setDepartment(params.userToEdit.position.departmentId);
            }
            if (params.userToEdit.teams && params.userToEdit.teams[0])
                setTeam(params.userToEdit.teams[0].id);
            if (params.userToEdit.privilege)
                setPrivilege(params.userToEdit.privilege.id);
            if (params.userToEdit.guardianId) {
                const guardianId: string = params.userToEdit.guardianId
                setGuardianId(guardianId);

                const guardian: IUser | undefined = users.find((item: IUser) => item.id == guardianId);
                if (guardian && guardian.profile)
                    setGuardianDefault(guardian.profile.surname);
            }
        }
    }, [params.userToEdit]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setError(null);
    }

    const handleChangePosition = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setPosition((event as any).target.value);
            setPositionDescription('');
        } else {
            if ((event as any).id) {
                setPosition((event as any).id);
            }
            if ((event as any).label) {
                setPositionDescription((event as any).label);
            }
        }
        setPositionError(null);
        setError(null);
    }

    const handleChangeDepartment = (event: SelectChangeEvent<string | number | null>) => {
        setDepartment(event.target.value as string);
        setDepartmentError(null);
        setError(null);
    }

    const handleChangeTeam = (event: SelectChangeEvent<string | number | null>) => {
        setTeam(event.target.value as string);
        setTeamError(null);
        setError(null);
    }

    const handleChangeGuardianId = (event: React.ChangeEvent<any | HTMLInputElement> | string) => {
        if (typeof event === 'string')
            setGuardianId(event);
        else if ((event as any).id) {
            setGuardianId((event as any).id);
        }
        setGuardianIdError(null);
        setError(null);
    }

    const handleChangePrivilege = (event: SelectChangeEvent<string | number | null>) => {
        setPrivilege(event.target.value as string);
        setPrivilegeError(null);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Insira o nome completo do usuário');
            isValid = false;
        }
        if (email == '') {
            setEmailError('Insira o e-mail de acesso do usuário');
            isValid = false;
        }
        if ((!isEdit && position == '') || (isEdit && positionDescription == '')) {
            setPositionError('Insira o cargo de ocupação do usuário');
            isValid = false;
        }
        if (department == '') {
            setDepartmentError('Selecione o setor de atuação do usuário');
            isValid = false;
        }
        // if (team == '') {
        //     setTeamError('Escolha uma equipe para inserir o usuário');
        //     isValid = false;
        // }
        if (privilege == '') {
            setTeamError('Escolha um privilégio para o usuário');
            isValid = false;
        }

        if (isValid) {
            setNameError(null);
            setEmailError(null);
            setPositionError(null);
            setDepartmentError(null);
            setTeamError(null);
            setPrivilegeError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let userId: string | undefined = undefined;

            if (isEdit && params.userToEdit) {
                const firstName: string = name.split(' ')[0];
                const names: string[] = name.split(' ');
                const lastName: string = names.splice(1, names.length).join(' ');

                const payload: IUserNew = {
                    userId: params.userToEdit.id,
                    userProfileId: params.userToEdit.profile.id,
                    firstName,
                    lastName,
                    surname: name,
                    email,
                    password
                }
                const editedUser: IUser = await editUser(params.userToEdit.id, params.userToEdit.profile.id, payload);
                userId = editedUser.id;
            }
            else {
                const newUser: IUser = await createNewUser(name, email, password);
                userId = newUser.id;
            }

            // creates a new position if not exists
            let positionId: string | undefined = undefined;
            if (positionDescription)
                positionId = position;
            else {
                const newPosition: IPosition = await createNewPosition(department, position)
                positionId = newPosition.id;
            }

            if (userId) {
                if (!isEdit || (positionId && params.userToEdit?.position?.description != position))
                    await createLinkWithPosition(userId, positionId);
                // if (!isEdit || (params.userToEdit?.teams && params.userToEdit?.teams[0]?.id != team))
                //     await createLinkWithTeam(userId, team);
                if (!isEdit || (params.userToEdit?.privilege && params.userToEdit?.privilege.id != privilege))
                    await createLinkWithPrivilege(userId, privilege);
                if (guardianId && (!isEdit || (!params.userToEdit?.guardianId || (params.userToEdit?.guardianId && params.userToEdit?.guardianId != guardianId)))) {
                    await createLinkWithGuardian(userId, guardianId);

                    if (params.userToEdit && user && params.userToEdit.id == user.id) {
                        await fetchUserData(user);
                    }
                }
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit)
                informationMessage = 'O novo usuário foi cadastrado com sucesso.' + (password === '' ? ' Login e senha serão enviados ao e-mail inserido durante o cadastro.' : '');

            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setPassword('');
        setPosition('');
        setPositionDescription('');
        setDefaultPosition(undefined);
        setPositionError(null);
        setDepartment('');
        setDepartmentError(null);
        setTeam('');
        setTeamError(null);
        setGuardianId('');
        setGuardianIdError(null);
        setGuardianDefault(undefined);
        setPrivilege('');
        setPrivilegeError(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid color={theme.palette.background.default} hasShadow borderRadius={BORDER_RADIUS} centered
                sx={{ minWidth: '40%' }} >
                <Container inline color={theme.palette.grayLight.light} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container style={{ flexGrow: 1 }} />
                    <PersonAddAlt1Icon color="secondary" />
                    <Spacing left={PADDING} />
                    <Text size={16} bold color={theme.palette.primary.light}>{!isEdit ? 'Cadastrar novo usuário' : 'Editar usuário'}</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <IconButton size="small" style={{}} onClick={close}>
                        <CloseIcon fontSize="small" style={{ color: theme.palette.primary.light }} />
                    </IconButton>
                </Container>

                <Text size={16} semiBold>{!isEdit ? 'Insira os dados do novo usuário' : 'Edite os dados do usuário se precisar'}</Text>

                <Container className="user-new-modal ss-container" fluid spacedBetween centered veryPadded
                    width={'100%'}
                    height={'100%'}>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Nome</Text>
                            <Spacing top={5} />
                            <Input
                                id="name"
                                autoFocus
                                required
                                value={name}
                                onChange={handleChangeName}
                                placeHolder='Insira o nome completo do usuário'
                                error={nameError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {nameError && <Text size={13} color={theme.palette.error.main} >{nameError}</Text>}
                        </Container>
                    </Container>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>E-mail</Text>
                            <Spacing top={5} />
                            <Input
                                id="email"
                                required
                                value={email}
                                onChange={handleChangeEmail}
                                placeHolder='Insira o e-mail de acesso do usuário'
                                error={emailError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                        </Container>
                    </Container>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Senha</Text>
                            <Spacing top={5} />
                            <Input
                                id="passw"
                                required
                                value={password}
                                autoComplete={false}
                                onChange={handleChangePassword}
                                placeHolder='Insira a senha de acesso do usuário'
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                isPassword
                                showPassword={showPassword}
                                handleClickShowPassword={() => setShowPassword(!showPassword)}
                                disabled={loading} />
                            {params.userToEdit != undefined && <Text size={13} color={theme.palette.error.main} style={{ paddingTop: PADDING / 2 }}>Editando a senha, o usuário perderá o acesso.</Text>}
                            {params.userToEdit == undefined && password == '' && <Text size={13} color={theme.palette.info.main} style={{ paddingTop: PADDING / 2 }}>Será enviada uma senha aleatória para o e-mail {email == '' ? 'informado' : email}</Text>}
                        </Container>
                    </Container>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Cargo</Text>
                            <Spacing top={5} />
                            <Autocomplete
                                id="position"
                                required
                                options={positions.map(item => {
                                    return {
                                        label: item.description,
                                        id: item.id
                                    }
                                }) as any[]}
                                value={position}
                                defaultValue={positionDescription}
                                onChange={handleChangePosition}
                                placeHolder='Insira o cargo de ocupação do usuário'
                                error={positionError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {positionError && <Text size={13} color={theme.palette.error.main} >{positionError}</Text>}
                        </Container>
                    </Container>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Setor</Text>
                            <Spacing top={5} />
                            <Select
                                required
                                value={department}
                                onChange={handleChangeDepartment}
                                placeHolder='Selecione o setor de atuação do usuário'
                                items={departments.map((item: IDepartment) => ({
                                    label: item.description,
                                    value: item.id
                                }))}
                                inputProps={{ disableUnderline: true }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {departmentError && <Text size={13} color={theme.palette.error.main} >{departmentError}</Text>}
                        </Container>
                    </Container>
                    {/* <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Equipe</Text>
                            <Spacing top={5} />
                            <Select
                                required
                                value={team}
                                onChange={handleChangeTeam}
                                placeHolder='Escolha uma equipe para inserir o usuário'
                                items={teams.map((item: ITeam) => ({
                                    label: item.description,
                                    value: item.id
                                }))}
                                inputProps={{ disableUnderline: true }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading} />
                            {teamError && <Text size={13} color={theme.palette.error.main} >{teamError}</Text>}
                        </Container>
                    </Container> */}
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Tutor</Text>
                            <Spacing top={5} />
                            <Autocomplete
                                id="guardian"
                                options={users.map(item => {
                                    return {
                                        label: item.profile.surname,
                                        id: item.id
                                    }
                                }) as any[]}
                                value={guardianId}
                                defaultValue={guardianDefault}
                                onChange={handleChangeGuardianId}
                                placeHolder='Pesquise e selecione o tutor do usuário'
                                error={guardianIdError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading}
                                requiredExists />
                            {guardianIdError && <Text size={13} color={theme.palette.error.main} >{guardianIdError}</Text>}
                        </Container>
                    </Container>
                    {privileges.find((item: IPrivilege) => item.id == privilege)?.description != 'SUPER_ADMIN' &&
                        <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                            <Container fluid width={'100%'}>
                                <Text bold>Privilégio</Text>
                                <Spacing top={5} />
                                <Select
                                    required
                                    value={privilege}
                                    onChange={handleChangePrivilege}
                                    placeHolder='Escolha um privilégio para o usuário'
                                    items={privileges.filter((item: IPrivilege) => item.description != 'SUPER_ADMIN').map((item: IPrivilege) => ({
                                        label: item.description,
                                        value: item.id
                                    }))}
                                    inputProps={{ disableUnderline: true }}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {privilegeError && <Text size={13} color={theme.palette.error.main} >{privilegeError}</Text>}
                            </Container>
                        </Container>}
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.primary.light, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Enviar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default UsersNewModal;