import React, { FC, createContext, useContext, useState } from 'react'

import { getApprovalsRequests, updateApprovalRequest as updateApprovalRequestService } from '../services/approvalsRequests';
import { IApprovalRequestNew, IApprovalRequest, IApprovalRequestContext } from '../types'

const ApprovalsRequestsContext = createContext<IApprovalRequestContext>({} as IApprovalRequestContext);

export const ApprovalsRequestsProvider: FC<any> = ({ children }) => {
    const [requests, setRequests] = useState<IApprovalRequest[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchRequest, setSearchRequest] = useState<string>('');
    const [filterStatus, setFilterStatus] = useState<string>('2');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchRequests = async () => {
        setLoading(true);
        try {
            const requests: IApprovalRequest[] = await getApprovalsRequests();
            setRequests([...requests]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const updateApprovalRequest = async (_id: string, _approved: boolean, _responsibleId: string) => {
        try {
            setLoading(true);
            const payload: IApprovalRequestNew = {
                approved: _approved,
                responsibleId: _responsibleId
            }
            const updatedRequest: IApprovalRequest = await updateApprovalRequestService(_id, payload);
            const auxRequests: IApprovalRequest[] = requests;
            for (let i = 0; i < auxRequests.length; i++)
                if (auxRequests[i].id == _id) {
                    auxRequests[i] = updatedRequest;
                }

            setTimeout(() => {
                setRequests([...auxRequests])
                setLoading(false);
            }, 1000);
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <ApprovalsRequestsContext.Provider value={{
            requests, loading, fetchRequests, searchRequest, setSearchRequest, filterStatus, setFilterStatus, paginate, setPaginate, updateApprovalRequest
        }}>{children}</ApprovalsRequestsContext.Provider>
    )
}

export function useApprovalsRequests() {
    const context = useContext(ApprovalsRequestsContext);

    if (!context) {
        throw new Error('useApprovalsRequests must be used within an ApprovalsRequestsProvider')
    }

    return context;
}