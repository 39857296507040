import React, { FC, useState } from 'react'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from "date-fns/locale";
import moment from 'moment'

import Theme from '../../theme'
import { IDatePicker } from '../../../types';

const DatePicker: FC<IDatePicker> = (params: IDatePicker) => {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <MuiDatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label=""
                value={params.value}
                onChange={(newValue) => params.setValue(newValue)}
                sx={{
                    border: 'solid 0px transparent',
                    borderRadius: 6.91,
                    boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
                    '& fieldset': {
                        border: "0px solid transparent",
                    },
                }}
            // sx={{
            //     width: '100%',
            //     height: "24px",
            //     backgroundColor: '#FAFAFA',
            //     border: 'solid 0px transparent',
            //     borderRadius: 6.91,
            //     boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
            //     padding: '11px',
            //     borderBottom: 'solid 0px transparent',
            //     '& fieldset': {
            //         display: "flex",
            //         justifyContent: "center",
            //         border: "0px solid transparent",
            //         padding: 1
            //     },
            //     '& fieldset.Mui-focused': {
            //         border: "3px solid red",
            //     },
            // }}
            // textField={(_params: any) => {
            //     let auxParams = _params
            //     if (auxParams.inputProps) {
            //         auxParams.inputProps.label = ""
            //         auxParams.inputProps.placeholder = params.placeHolder
            //         auxParams.inputProps.style = { height: 13, textDecoration: 'none' }
            //     }

            //     return (
            //         <TextField
            //             // autoFocus={params.autoFocus}
            //             // required={params.required}
            //             placeholder={params.placeHolder}
            //             value={params.value ? moment(params.value).format('DD/MM/YYYY').toString() : ''}
            //             required={params.required}
            //             variant="standard"
            //             size='small'
            //             style={{
            //                 width: '100%',
            //                 backgroundColor: '#FAFAFA',
            //                 border: 'solid 0px transparent',
            //                 borderRadius: 6.91,
            //                 boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
            //                 padding: '11px',
            //                 borderBottom: 'solid 0px transparent'
            //             }}
            //             InputProps={{
            //                 disableUnderline: true,
            //             }}
            //             {...auxParams}
            //             {...params.register}
            //             sx={{
            //                 svg: { color: Theme.palette.primary.light },
            //                 input: { textDecoration: 'none' }
            //             }}
            //             readOnly
            //             onClick={() => setOpen(true)} />
            //     )
            // }}
            />
        </LocalizationProvider>
    )
}

export default DatePicker