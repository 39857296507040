import { AxiosResponse } from "axios";

import api from ".";
import { IUserNew, IUser, ITeam, IRoute, IUserProfile } from "../types";

export const createUserProfile = async (_payload: IUserNew) => {
  const response: AxiosResponse = await api.post("/v1_0/users_profiles", {
    userId: _payload.userId,
    firstName: _payload.firstName,
    lastName: _payload.lastName,
    surname: _payload.surname,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let userProfile: IUserProfile = {
        id: response.data.id,
        userId: response.data.userId,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        surname: response.data.surname,
        avatarUri: response.data.avatarUri,
      };

      return userProfile;
    }
  }

  throw new Error("Não foi possível criar um perfil de usuário");
};

export const updateUserProfile = async (
  _id: string,
  _payload: IUserNew | IUserProfile
) => {
  const response: AxiosResponse = await api.put(`/v1_0/users_profiles/${_id}`, {
    userId: _payload.userId,
    firstName: _payload.firstName,
    lastName: _payload.lastName,
    surname: _payload.surname,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let userProfile: IUserProfile = {
        id: response.data.id,
        userId: response.data.userId,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        surname: response.data.surname,
        avatarUri: response.data.avatarUri,
      };

      return userProfile;
    }
  }

  throw new Error("Não foi possível editar um perfil de usuário");
};

export const updateUserProfileAvatar = async (_id: string, _base64: string) => {
  const response: AxiosResponse = await api.put(
    `/v1_0/users_profiles/${_id}/avatar_uri`,
    {
      avatar_uri: _base64,
    }
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let userProfile: IUserProfile = {
        id: response.data.id,
        userId: response.data.userId,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        surname: response.data.surname,
        avatarUri: response.data.avatarUri,
      };

      return userProfile;
    }
  }

  throw new Error("Não foi possível atualizar a foto do usuário");
};
