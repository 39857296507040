import React, { FC, useEffect, useState } from 'react'
import { Avatar, Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { AccountCircleOutlined as AccountIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { AppHeaderToolbar, ChangePasswordModal, ConfirmPasswordModal, Container, Input, Page, Spacing, Text, theme } from '../../components'
import { useAuth } from '../../hooks/auth';
import { API_URL } from '../../services';
import { updateUserProfile as updateUserProfileService, updateUserProfileAvatar } from '../../services/usersProfiles';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IUserProfile } from '../../types'
import { convertBase64 } from '../../utils/converters';

const Goal: FC = () => {
    const avatarSize = 96;

    const { user, updateUserProfile } = useAuth();
    const [surname, setSurname] = useState<string>('');
    const [surnameError, setSurnameError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean>(false);
    const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] = useState<boolean>(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (user && user.profile && user.profile.surname)
            setSurname(user.profile.surname);
    }, []);

    useEffect(() => {
        if (isPasswordCorrect) {
            setInterval(() => {
                setIsPasswordCorrect(false);
            }, 300 * 1000);
        }
    }, [isPasswordCorrect]);

    const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSurname(event.target.value);
        setSurnameError(null);
    }

    const onSelectAvatar = async (event: any) => {
        event.persist();

        const file = event.target.files[0];
        const avatarBase64 = await convertBase64(file);
        if (typeof avatarBase64 === 'string') {
            const base64: string = avatarBase64.substring(avatarBase64.indexOf('base64') + 7, avatarBase64.length);
            saveProfileAvatar(base64);
        }
    }

    const saveProfileAvatar = async (_base64: string) => {
        if (user) {
            try {
                setLoading(true);

                const payload: IUserProfile = {
                    id: user?.profile.id,
                    surname: surname
                }
                const profileUpdated = await updateUserProfileAvatar(payload.id, _base64);

                setTimeout(() => {
                    updateUserProfile(profileUpdated);
                    setLoading(false);
                }, 1000);
            } catch (err) {
                setLoading(false);
            }
        }
    }

    const removeProfileAvatar = async () => {
        if (user) {
            try {
                setLoading(true);

                const profileUpdated = await updateUserProfileAvatar(user.profile.id, '');

                setTimeout(() => {
                    updateUserProfile(profileUpdated);
                    setLoading(false);
                }, 1000);
            } catch (err) {
                setLoading(false);
            }
        }
    }

    const saveProfileSettings = async () => {
        if (user) {
            try {
                setLoading(true);

                const payload: IUserProfile = {
                    id: user?.profile.id,
                    surname: surname
                }
                const profileUpdated = await updateUserProfileService(payload.id, payload);

                setTimeout(() => {
                    updateUserProfile(profileUpdated);
                    setLoading(false);
                }, 1000);
            } catch (err) {
                setLoading(false);
            }
        }
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid veryPadded>
                    <Text extraBold size={18}>Imagem de perfil</Text>
                    <Spacing top={10} />
                    <Container
                        inline
                        borderRadius={BORDER_RADIUS / 2}
                        width='50%'
                        sx={{ border: `solid 1px ${theme.palette.common.black}`, minWidth: 700 }}
                        veryPadded>
                        {user && user.profile.avatarUri == '' && <AccountIcon style={{ fontSize: avatarSize, marginRight: 10, color: theme.palette.common.black }} />}
                        {user && user.profile.avatarUri != '' && <Avatar src={`${API_URL}/v1_0/users_profiles/images/${user.profile.avatarUri}`}
                            style={{ marginRight: 10 }}
                            sx={{ width: avatarSize, height: avatarSize }} />}
                        <Container fluid sx={{ justifyContent: 'center', paddingLeft: PADDING }}>
                            <Container inline>
                                {/* <label htmlFor="upload-photo"> */}
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    component="label">
                                    <Text size={13} color={theme.palette.background.paper}>Atualizar imagem de perfil</Text>
                                    <input
                                        type='file'
                                        id='upload-photo'
                                        name='photo'
                                        accept='image/png, image/jpeg'
                                        onChange={onSelectAvatar}
                                        hidden
                                    />

                                </LoadingButton>

                                <IconButton size="small" color="error" onClick={() => removeProfileAvatar()}>
                                    <DeleteIcon sx={{ width: 21, height: 21 }} />
                                </IconButton>
                            </Container>
                            <Spacing top={PADDING / 2} />
                            <Text size={14}>A imagem deve estar no formato PNG ou JPEG e não pode ter mais do que 10 MB.</Text>
                        </Container>
                    </Container>
                </Container>

                <Container fluid veryPadded>
                    <Text extraBold size={18}>Configurações de perfil</Text>
                    <Text thin size={13}>Alterar detalhes de identificação da sua conta</Text>
                    <Spacing top={10} />
                    <Container
                        inline
                        borderRadius={BORDER_RADIUS / 2}
                        width='50%'
                        sx={{ border: `solid 1px ${theme.palette.common.black}`, minWidth: 700 }}
                        veryPadded>
                        <Container fluid width={'100%'}>
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <Text bold style={{ minWidth: '150px' }}>Nome de exibição</Text>
                                </Grid>
                                <Grid item xs={9}>
                                    <Container fluid width={'100%'}>
                                        <Input
                                            id="name"
                                            autoFocus
                                            required
                                            value={surname}
                                            onChange={handleChangeSurname}
                                            placeHolder='Insira o nome completo do usuário'
                                            error={surnameError != null}
                                            inputProps={{ disableUnderline: true, }}
                                            backgroundColor={theme.palette.grayLight.light}
                                            disabled={loading} />
                                        {surnameError && <Text size={13} color={theme.palette.error.main} >{surnameError}</Text>}
                                    </Container>
                                </Grid>
                            </Grid>
                            <Container fluid sx={{ paddingTop: VERY_PADDING }} flexEnd>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={surnameError != null || loading}
                                    disabled={loading}
                                    onClick={() => saveProfileSettings()}>
                                    <Text size={13} color={theme.palette.background.default}>Salvar alterações</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>

                <Container fluid veryPadded>
                    <Text extraBold size={18}>Segurança</Text>
                    <Text thin size={13}>Mantenha sua conta segura</Text>
                    <Spacing top={10} />
                    <Container
                        inline
                        borderRadius={BORDER_RADIUS / 2}
                        width='50%'
                        sx={{ border: `solid 1px ${theme.palette.common.black}`, minWidth: 700 }}
                        veryPadded>
                        <Container fluid width={'100%'}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Text bold>Senha</Text>
                                </Grid>
                                <Grid item xs={9}>
                                    <Text><Text color={theme.palette.primary.light} style={{ cursor: 'pointer' }} onClick={() => setIsConfirmPasswordModalOpen(true)}>Altere sua senha.</Text> Aumente sua segurança com uma senha forte.</Text>
                                </Grid>
                            </Grid>
                        </Container>
                    </Container>
                </Container>
            </Container>

            <ConfirmPasswordModal
                isOpened={isConfirmPasswordModalOpen}
                onClose={() => setIsConfirmPasswordModalOpen(false)}
                onConfirm={() => {
                    setIsConfirmPasswordModalOpen(false);
                    setIsPasswordCorrect(true);
                    setIsChangePasswordModalOpen(true);
                }}
            />
            <ChangePasswordModal isOpened={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)} />
        </Page >
    )
}

export default Goal;