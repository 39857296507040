import { AxiosResponse } from "axios";

import api from ".";
import { IDepartment, IDepartmentNew } from "../types";

const baseUrl: string = "departments";

export const createDepartment = async (_department: IDepartmentNew) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    description: _department.description,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;
      const description: IDepartment = {
        id: id,
        description: response.data.description,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };

      return description;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um setor");
};

export const updateDepartment = async (_id: string, _department: IDepartmentNew) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    description: _department.description,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;
      const department: IDepartment = {
        id: id,
        description: response.data.description,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
      };

      return department;
    }
  }

  throw new Error("Não foi possível editar um setor");
};

export const destroyDepartment = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um setor");
};

export const getDepartments = async () => {
  const response: AxiosResponse = await api.get("/v1_0/departments");

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const departments: IDepartment[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        const department: IDepartment = {
          id: data.id,
          description: data.description,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };

        departments.push(department);
      }

      return departments;
    }
  }

  throw new Error("Não foi possível buscar os setores");
};

export const getDepartment = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/users/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IDepartment = convertApiResponseToIDepartment(response.data);
      return user;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const convertApiResponseToIDepartment = (data: any) => {
  const department: IDepartment = {
    id: data.id,
    description: data.description,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };

  return department;
};
