import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';

import { AppHeader } from '../components'
import HooksProvider from '../hooks'
import { useAuth } from '../hooks/auth';
import { ApprovalsRequests, Goals, GoalDetailed, Home, MyGoals, MyGoalsNew, Profile, Splash, Teams, TeamDetailed, Users, UsersNew, Departments } from '../pages'
import { IRoute } from '../types';

const AuthRoutes: React.FC = () => {
    const { user, privilege, fetchUserData, loadingUserData, } = useAuth();

    useEffect(() => {
        if (user) fetchUserData(user);

        setTimeout(() => {
            checkIfIsReady();
        }, 5000);
    }, []);

    const checkIfIsReady = () => {
        if (loadingUserData) {
            // logout();
            // navigate('/');
        }
    }

    if (loadingUserData) return <Splash />

    return (
        <HooksProvider>
            {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('@super_admin_header') >= 0 && <AppHeader isAdmin isHumanResources isUser />}
            {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('@admin_header') >= 0 && <AppHeader isAdmin />}
            {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('@hr_header') >= 0 && <AppHeader isHumanResources />}
            {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('@user_header') >= 0 && <AppHeader isUser />}

            <Routes>
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/departments') >= 0 && <Route path="/departments" element={<Departments />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/departments/new') >= 0 && <Route path="/departments/new" element={<UsersNew />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/departments/new/:id') >= 0 && <Route path="/departments/new/:id" element={<UsersNew />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/goals') >= 0 && <Route path="/goals" element={<Goals />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/goals/:id') >= 0 && <Route path="/goals/:id" element={<GoalDetailed />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals') >= 0 && <Route path="/my-goals" element={<MyGoals />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals/new') >= 0 && <Route path="/my-goals/new" element={<MyGoalsNew />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals/new/:id') >= 0 && <Route path="/my-goals/new/:id" element={<MyGoalsNew />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/my-goals/new/:id/:approvalRequestId') >= 0 && <Route path="/my-goals/new/:id/:approvalRequestId" element={<MyGoalsNew />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/profile') >= 0 && <Route path="/profile" element={<Profile />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/requests') >= 0 && <Route path="/requests" element={<ApprovalsRequests />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams') >= 0 && <Route path="/teams" element={<Teams />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/teams/:id') >= 0 && <Route path="/teams/:id" element={<TeamDetailed />} />}

                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/users') >= 0 && <Route path="/users" element={<Users />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/users/new') >= 0 && <Route path="/users/new" element={<UsersNew />} />}
                {privilege && privilege.routes && privilege.routes.map((item: IRoute) => item.path).indexOf('/users/new/:id') >= 0 && <Route path="/users/new/:id" element={<UsersNew />} />}

                <Route path="*" element={<Home />} />
            </Routes>
        </HooksProvider>
    )
}

export default AuthRoutes;