import { AxiosResponse } from "axios";

import api from "./";
import { IGoal, IApprovalRequestNew, IApprovalRequest, IUser } from "../types";
import { convertApiResponseToIUser } from "./users";
import { convertApiResponseToIGoal } from "./goals";

const baseUrl: string = "approvals_requests";

export const createApprovalRequest = async (payload: IApprovalRequestNew) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    userId: payload.userId,
    request: payload.request,
    type: payload.type,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const approvalRequest: IApprovalRequest = convertApiResponseToIApprovalRequest(response.data);

        await api.post(`/v1_0/goals_has_approvals_requests`, {
          goalId: payload.goalId,
          approvalRequestId: approvalRequest.id,
        });

        return approvalRequest;
      }
    }
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export const updateApprovalRequest = async (_requestId: string, payload: IApprovalRequestNew) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_requestId}`, payload);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const approvalRequest: IApprovalRequest = convertApiResponseToIApprovalRequest(response.data);

        return approvalRequest;
      }
    }
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export const viewApprovalRequest = async (_id: string) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    viewed: true,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data) {
        const approvalRequest: IApprovalRequest = convertApiResponseToIApprovalRequest(response.data);

        return approvalRequest;
      }
    }
  }

  throw new Error("Não foi possível buscar os objetivos.");
};

export const getApprovalsRequests = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const requests: IApprovalRequest[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        let user: IUser | undefined = undefined;
        if (data.user) user = convertApiResponseToIUser(data.user);
        let goal: IGoal | undefined = undefined;
        if (data.goal) goal = convertApiResponseToIGoal(data.goal);
        
        const request: IApprovalRequest = {
          id: data.id,
          userId: data.userId,
          responsibleId: data.responsibleId,
          request: data.request,
          type: data.type,
          approved: data.approved,
          reply: data.reply,
          viewed: data.viewed,
          typeDesc: data.typeDesc,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          user,
          goal,
        };
        requests.push(request);
      }

      return requests;
    }
  }
  throw new Error("Não foi possível buscar as solicitações");
};

const convertApiResponseToIApprovalRequest = (_data: any) => {
  const approvalRequest: IApprovalRequest = {
    id: _data.id,
    userId: _data.userId,
    responsibleId: _data.responsibleId,
    request: _data.request,
    type: _data.type,
    approved: _data.approved,
    reply: _data.reply,
    viewed: _data.viewed,
    typeDesc: _data.typeDesc,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };

  return approvalRequest;
};
