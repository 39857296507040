import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CircularProgress } from '@mui/material'

import logoDetail from '../../assets/logo_detail.png'
import { AppHeaderToolbar, Confirmation, Container, GoalsList, Image, Information, Input, NewGoalTips, Page, Spacing, Text, theme, UserCard } from '../../components'
import { useGoal } from '../../hooks/goals';
import { useAuth } from '../../hooks/auth';
import { BORDER_RADIUS } from '../../utils/consts'
import { IApprovalRequest, IApprovalRequestNew, IGoal } from '../../types'
import { createApprovalRequest } from '../../services/approvalsRequests'

const Goal: FC = () => {
    const { myGoals, fetchMyGoals, destroyGoal, updateApprovalRequest, loadingMyGoals } = useGoal();
    const { user, guardian, position } = useAuth();
    const navigate = useNavigate();
    const [openTipsModal, setOpenTipsModal] = useState<boolean>(false);

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);
    const [deleteGoalId, setDeleteGoalId] = useState<string | null>(null);

    const [approvalRequestModal, setApprovalRequestModal] = useState<boolean>(false);
    const [approvalRequestGoalId, setApprovalRequestGoalId] = useState<string | null>(null);
    const [request, setRequest] = useState<string>('');
    const [approvalRequestType, setApprovalRequestType] = useState<number | null>(null);

    const [informationModalMessage, setInformationModalMessage] = useState<string>('')
    const [informationModal, setInformationModal] = useState<boolean>(false)

    const [disabled, setDisabled] = useState<boolean>(false);


    useEffect(() => {
        if (user) fetchMyGoals(user.id);
    }, [])

    useEffect(() => {
        if (!deleteConfirmationModal)
            setDeleteGoalId(null);
    }, [deleteConfirmationModal])

    useEffect(() => {
        if (!informationModal)
            setInformationModalMessage('');
    }, [informationModal])

    const createNewGoal = () => {
        navigate('/my-goals/new');
    }

    const editGoal = (_goalId: string) => {
        const goal = myGoals.find((item: IGoal) => item.id == _goalId);
        if (goal) {
            const goalHasApprovalRequest: IApprovalRequest | null = goal.approvalsRequests.find((item: IApprovalRequest) => item.type === 0) || null;
            if (goalHasApprovalRequest) {
                if (goalHasApprovalRequest.responsibleId != undefined && goalHasApprovalRequest.approved == false) {
                    setInformationModalMessage('Sua solicitação foi recusada.');
                    setInformationModal(true);
                    return;
                }
                else if (goalHasApprovalRequest.approved) {
                    navigate(`/my-goals/new/${_goalId}/${goalHasApprovalRequest.id}`);
                    return;
                }
                else {
                    setInformationModalMessage('Existe uma justificativa solicitada.');
                    setInformationModal(true);
                    return;
                }
            }
        }
        setApprovalRequestGoalId(_goalId);
        setApprovalRequestType(0);
        setApprovalRequestModal(true);
    }

    const openDeleteConfirmationModal = (_goalId: string) => {
        const goal = myGoals.find((item: IGoal) => item.id == _goalId);
        if (goal) {
            const goalHasApprovalRequest: IApprovalRequest | null = goal.approvalsRequests.find((item: IApprovalRequest) => item.type == 1) || null;
            if (goalHasApprovalRequest) {
                if (goalHasApprovalRequest.responsibleId != undefined && goalHasApprovalRequest.approved == false) {
                    setInformationModalMessage('Sua solicitação foi recusada.');
                    setInformationModal(true);
                    return;
                }
                else if (goalHasApprovalRequest.approved) {
                    setDeleteConfirmationModal(true);
                    setDeleteGoalId(_goalId);
                    return;
                }
                else {
                    setInformationModalMessage('Existe uma justificativa solicitada.');
                    setInformationModal(true);
                    return;
                }
            }
        }

        setApprovalRequestGoalId(_goalId);
        setApprovalRequestType(1);
        setApprovalRequestModal(true);
    }

    const sendApprovalRequest = async () => {
        try {
            setDisabled(true);

            if (user && approvalRequestGoalId && approvalRequestType != null) {
                const payload: IApprovalRequestNew = {
                    userId: user.id,
                    goalId: approvalRequestGoalId,
                    type: approvalRequestType,
                    request
                }
                const approvalRequest: IApprovalRequest = await createApprovalRequest(payload);
                updateApprovalRequest(approvalRequestGoalId, approvalRequest);
            }

            setApprovalRequestGoalId(null);
            setApprovalRequestType(null);
            setApprovalRequestModal(false);
            setRequest('');

            setInformationModalMessage('Justificativa enviada com sucesso!');
            setInformationModal(true);
            setTimeout(() => {
                setDisabled(false);
            }, 2000);
        } catch (_err) {
            setDisabled(false);
            setDeleteConfirmationModal(false);
        }
    }

    const deleteGoal = async () => {
        try {
            setDisabled(true);
            if (typeof deleteGoalId === 'string')
                await destroyGoal(deleteGoalId);

            setDeleteConfirmationModal(false);
            setTimeout(() => {
                setDisabled(false);
            }, 2000);
        } catch (_err) {
            setDisabled(false);
            setDeleteConfirmationModal(false);
        }
    }

    return (
        <Page
            width='auto'
            height='auto'
            veryPadded
            color={theme.palette.primary.main}
            style={{ minHeight: '95.7vh' }}>
            <AppHeaderToolbar />

            <Container
                fluid
                width='100%'
                hasShadow
                borderRadius={BORDER_RADIUS}
                color={theme.palette.background.paper}
                style={{ minHeight: '80vh' }}>
                <Container fluid centered veryPadded color={theme.palette.grayLight.main} borderTopRadius={10}>
                    <Text bold size={25}>PMP - Plano de Metas e Performance</Text>
                    <Spacing top={10} />
                    <Text size={18} center>O PMP é utilizado para atingir metas individuais dentro da Pix Force e de desenvolvimento profissional.</Text>
                    <Text size={18} center>Direcionado para a área de atuação, estabelecendo objetivos de curto, médio e longo prazo.
                    </Text>

                    <Spacing top={30} />

                    <Container inline spacedAround width={'100%'}>
                        {user && <UserCard fontColor={theme.palette.common.black} user={user} showPosition avatarSize={50} position={position} />}
                        <Image
                            source={logoDetail}
                            height={50}
                            width={50} />
                        {guardian && <UserCard fontColor={theme.palette.common.black} user={guardian} isGuardian avatarSize={50} position={position} />}
                        {!guardian && <Text bold>Tutor não encontrado</Text>}
                    </Container>
                </Container>
                <Container fluid spacedBetween centered padded height={'100%'}
                    style={{ minHeight: '40vh' }}>
                    <Text bold size={25}>Objetivos</Text>

                    {loadingMyGoals && <Container fluid centered padded>
                        <CircularProgress size={25} />
                    </Container>}
                    {!loadingMyGoals && myGoals.length == 0 && <Container fluid centered width={350}>
                        <Text bold size={25} center>Você ainda não tem nenhum objetivo criado!</Text>

                        <Text size={15} color={theme.palette.primary.light}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => setOpenTipsModal(true)}>Dicas: como montar meu PMP</Text>
                    </Container>}
                    {!loadingMyGoals && myGoals.length > 0 && <GoalsList goals={myGoals} disabled={disabled} loading={loadingMyGoals}
                        onEditGoal={(_goalId) => editGoal(_goalId)}
                        onOpenDeleteConfirmationModal={(_goalId) => openDeleteConfirmationModal(_goalId)} />}

                    <Container fluid centered>
                        <Button
                            disabled={disabled || guardian == null}
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: (disabled || guardian == null) ? theme.palette.gray.main : theme.palette.primary.light, width: 200 }}
                            onClick={() => {
                                setOpenTipsModal(false);
                                createNewGoal();
                            }} >
                            <Text size={13} color={theme.palette.background.default}>Criar novo objetivo</Text>
                        </Button>
                        {guardian == null && <>
                            <Spacing top={10} />
                            <Text>Para criar um objetivo, solicite um tutor.</Text>
                        </>}
                    </Container>
                </Container>
            </Container>

            <NewGoalTips open={openTipsModal} onClose={() => setOpenTipsModal(false)}
                onCreateNewGoal={createNewGoal} disabled={guardian == null} />
            <Confirmation
                open={approvalRequestModal}
                title={approvalRequestType == 0 ? "Editar objetivo" : "Excluir objetivo"}
                message="Solicitação sujeita à aprovação."
                confirmMessage={`Justifique o motivo pelo qual você deseja ${approvalRequestType == 0 ? 'editar' : 'excluir'} este objetivo?`}
                onClose={() => setApprovalRequestModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setApprovalRequestModal(false)}
                onConfirm={() => sendApprovalRequest()}
                confirmLabel="Confirma">
                <Container fluid width={'100%'} >
                    <Input
                        autoFocus
                        multiline
                        placeHolder=''
                        value={request}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRequest(event.target.value)}
                        inputProps={{ disableUnderline: true, }} />
                </Container>
            </Confirmation>
            <Confirmation
                open={deleteConfirmationModal}
                title={"Excluir objetivo"}
                message="Ao excluir o objetivo não será possível recuperá-lo."
                confirmMessage={`Deseja realmente confirmar a exclusão?`}
                onClose={() => setDeleteConfirmationModal(false)}
                cancelLabel="Cancelar"
                onCancel={() => setDeleteConfirmationModal(false)}
                onConfirm={() => deleteGoal()}
                confirmLabel="Confirma" />
            <Information
                open={informationModal}
                message={informationModalMessage}
                onClose={() => setInformationModal(false)}
                onConfirm={() => setInformationModal(false)} />
        </Page >
    )
}

export default Goal;