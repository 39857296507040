import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';

import theme from '../../../theme';
import Container from '../../../atoms/container';
import Input from '../../../atoms/input';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import { useAuth } from '../../../../hooks/auth';
import { useInformation } from '../../../../hooks/information';
import { updatePassword } from '../../../../services/auth';
import { IChangePasswordModal } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import { LoadingButton } from '@mui/lab';

const ChangePaswordModal: FC<IChangePasswordModal> = ({ isOpened, onClose }) => {
    const { user } = useAuth();
    const { hideInformation, showInformation } = useInformation();

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [confirmationPassword, setConfirmationPassword] = useState<string>('');
    const [showConfirmationPassword, setShowConfirmationPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isOpened) {
            setCurrentPassword('');
        }
    }, [isOpened]);

    const handleUpdatePassword = async () => {
        try {
            if (user) {
                setLoading(true);
                const isValid = await updatePassword(user.email, currentPassword, newPassword);
                if (isValid) {
                    showInformation('Senha alterada com sucesso. Por favor, efetue o login novamente.', undefined, undefined, () => {
                        hideInformation();

                        localStorage.clear();
                        window.location.href = '/';
                        window.open('/');
                    });
                } else {
                    showInformation('Não foi possível atualizar a senha. Tente novamente mais tarde');
                }
                setLoading(false);
            } else throw new Error('Não foi possível atualizar a senha');
        } catch (_err: any) {
            let message = _err;
            if (typeof message !== 'string') {
                if (_err.response.data.message) {
                    message = _err.response.data.message;
                } else {
                    message = 'Não foi possível atualizar a senha';
                }
            }
            showInformation(message);
            setLoading(false);
        }
    };

    return (
        <Modal isOpened={isOpened} onClose={onClose}>
            <Container fluid color={theme.palette.background.default} hasShadow borderRadius={BORDER_RADIUS} centered
                style={{ maxWidth: 500 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Alterar senha</Text>
                    </Container>
                </Container>

                <Container fluid spacedAround veryPadded centered>
                    <Spacing top={15} />
                    <Container fluid flexStart width="100%">
                        <Text light>{user && user.email}</Text>
                    </Container>
                    <Spacing top={5} />
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Aviso:</span> ao alterar a senha, você será deslogado por medida de segurança.
                    </Text>
                </Container>

                <Grid container alignItems='center' justifyContent="center">
                    <Grid item xs={3} >
                        <Container inline>
                            <Spacing left={VERY_PADDING} />
                            <Text size={14}>Senha antiga:</Text>
                        </Container>
                    </Grid>
                    <Grid item xs={9}>
                        <Container fluid width="80%">
                            <Input
                                id="curr-passw"
                                required
                                value={currentPassword}
                                autoComplete={false}
                                onChange={(value: any) => setCurrentPassword(value.target.value)}
                                placeHolder='Digite sua senha anterior'
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                isPassword
                                showPassword={showCurrentPassword}
                                handleClickShowPassword={() => setShowCurrentPassword(!showCurrentPassword)} />
                        </Container>
                    </Grid>
                </Grid>
                <Spacing top={VERY_PADDING} />
                <Grid container alignItems='center' justifyContent="center">
                    <Grid item xs={3} >
                        <Container inline>
                            <Spacing left={VERY_PADDING} />
                            <Text size={14}>Senha:</Text>
                        </Container>
                    </Grid>
                    <Grid item xs={9}>
                        <Container fluid width="80%">
                            <Input
                                id="new-passw"
                                required
                                value={newPassword}
                                autoComplete={false}
                                onChange={(value: any) => setNewPassword(value.target.value)}
                                placeHolder="Digite sua nova senha"
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                isPassword
                                showPassword={showNewPassword}
                                handleClickShowPassword={() => setShowNewPassword(!showNewPassword)} />
                        </Container>
                    </Grid>
                </Grid>
                <Spacing top={VERY_PADDING} />
                <Grid container alignItems='center' justifyContent="center">
                    <Grid item xs={3} >
                        <Container inline>
                            <Spacing left={VERY_PADDING} />
                            <Text size={14}>Confirmação de senha:</Text>
                        </Container>
                    </Grid>
                    <Grid item xs={9}>
                        <Container fluid width="80%">
                            <Input
                                id="confirm-passw"
                                required
                                value={confirmationPassword}
                                autoComplete={false}
                                onChange={(value: any) => setConfirmationPassword(value.target.value)}
                                placeHolder="Confirme a sua senha"
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                isPassword
                                showPassword={showConfirmationPassword}
                                handleClickShowPassword={() => setShowConfirmationPassword(!showConfirmationPassword)} />
                        </Container>
                    </Grid>
                </Grid>
                <Spacing top={PADDING} />
                {newPassword != confirmationPassword && <Text size={12} color={theme.palette.error.main}>As senhas não conferem</Text>}

                <Spacing top={VERY_PADDING} />
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={onClose}>
                        <Text bold size={12} color={theme.palette.primary.light}>Cancelar</Text>
                    </LoadingButton>
                    <Spacing left={30} />
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={loading}
                        disabled={newPassword == '' || confirmationPassword == '' || newPassword != confirmationPassword}
                        style={{ backgroundColor: loading || newPassword == '' || confirmationPassword == '' || newPassword != confirmationPassword ? theme.palette.grayLight.main : theme.palette.primary.light, width: 150, height: 35 }}
                        onClick={handleUpdatePassword}>
                        <Text bold size={12} color={theme.palette.background.paper}>Confirmar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    );
};

export default ChangePaswordModal;
