import React, { FC } from 'react'

import Container from '../../../atoms/container'
import GoalsListItem from '../listItem'
import { IGoal, IGoalsList } from '../../../../types'

const GoalsList: FC<IGoalsList> = (params: IGoalsList) => {
    return (
        <Container fluid veryPadded width='90%' height='auto'>
            {params.goals.map((item: IGoal, index: number) => (
                <GoalsListItem
                    key={index}
                    goal={item}
                    loading={params.loading}
                    disabled={params.disabled}
                    onEditGoal={(_goalId) => params.onEditGoal(_goalId)}
                    onOpenDeleteConfirmationModal={params.onOpenDeleteConfirmationModal} />
            ))}
        </Container>
    )
}

export default GoalsList;