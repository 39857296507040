import styled from "styled-components";

import { IContainer } from "../../../types";
import { PADDING, VERY_PADDING } from "../../../utils/consts";

const fluid = "display: flex; flex-direction: column;";
const inline = "display: flex; flex-direction: row;";
const flexStart = "align-items: flex-start; justify-content: flex-start";
const flexEnd = "align-items: flex-end; justify-content: flex-end";
const centered = "align-items: center; justify-content: center";

export const ContainerStyled = styled.div`
  ${(props: IContainer) => props.fluid && fluid};
  ${(props: IContainer) => props.inline && inline};

  height: ${(props: IContainer) =>
    typeof props.height === "number" ? `${props.height}px` : props.height};
  width: ${(props: IContainer) =>
    typeof props.width === "number" ? `${props.width}px` : props.width};

  ${(props: IContainer) => props.color && `background: ${props.color}`};

  ${(props: IContainer) => props.flexStart && flexStart};
  ${(props: IContainer) => props.flexEnd && flexEnd};
  ${(props: IContainer) => props.centered && centered};
  ${(props: IContainer) => props.horizontalCentered && "align-items: center"};
  ${(props: IContainer) => props.verticalCentered && "justify-content: center"};

  ${(props: IContainer) =>
    props.spacedAround && "justify-content: space-around"};
  ${(props: IContainer) =>
    props.spacedBetween && "justify-content: space-between"};
  ${(props: IContainer) =>
    props.spacedEvenly && "justify-content: space-evenly"};

  ${(props: IContainer) =>
    props.borderRadius && `border-radius: ${props.borderRadius}px`};
  ${(props: IContainer) =>
    props.borderTopRadius &&
    `border-top-left-radius: ${props.borderTopRadius}px; border-top-right-radius: ${props.borderTopRadius}px;`};
  ${(props: IContainer) =>
    props.borderBottomRadius &&
    `border-bottom-left-radius: ${props.borderBottomRadius}px; border-bottom-right-radius: ${props.borderBottomRadius}px;`};

  ${(props: IContainer) => props.padded && `padding: ${PADDING}px`};
  ${(props: IContainer) => props.veryPadded && `padding: ${VERY_PADDING}px`};
  ${(props: IContainer) => props.verticalPadded && `padding: ${PADDING}px 0`};
  ${(props: IContainer) =>
    props.veryVerticalPadded && `padding: ${VERY_PADDING}px 0`};
  ${(props: IContainer) => props.horizontalPadded && `padding: 0 ${PADDING}px`};
  ${(props: IContainer) =>
    props.veryHorizontalPadded && `padding: 0 ${VERY_PADDING}px`};

  box-shadow: ${(props: IContainer) =>
    props.hasShadow && "rgba(0, 0, 0, 0.15) 0px 3px 8px;"};
`;
