import { IGoalsStats } from "./goals";
import { IUser, IUserProfile } from "./users";

interface ITeamNew {
  name: string;
  supervisorId: string;
  membersId: string[];
}

interface ITeam {
  id: string;
  description: string;
  updatedAt?: string;
  createdAt?: string;
  supervisors: IUserProfile[];
  members: IUserProfile[];
  avatarUri?: string;
  goalsStats: IGoalsStats;
}

export function isTeam(_data: any): _data is ITeam {
  return "supervisors" in _data;
}

interface ITeamContext {
  teams: ITeam[];
  fetchTeams: () => void;
  loading: boolean;

  members: IUser[];
  loadingMembers: boolean;
  fetchTeamMembers: (_id: string) => void;

  createNewTeam: (_name: string, _supervisorId: string, membersId: string[]) => Promise<ITeam>;
  deleteTeam: (_id: string) => Promise<ITeam>;

  searchTeam: string;
  setSearchTeam: (_value: string) => void;
  teamsPerRow: string;
  setTeamsPerRow: (_value: string) => void;
  paginate: number;
  setPaginate: (_value: number) => void;

  requestNewMember: (_userSurname: string, _teamName: string, _memberSurname: string[], _position: string[], _department: string[]) => void;
}

export type { ITeamNew, ITeam, ITeamContext };
