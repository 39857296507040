import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, Collapse, Grid, IconButton } from '@mui/material'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, PersonAddAlt1 as PersonAddAlt1Icon, TagFaces as TagFacesIcon, } from '@mui/icons-material'

import './classes.css'
import { colors } from './consts'
import UserCardBasic from '../../users/cardBasic'
import theme from '../../../theme'
import Container from '../../../atoms/container'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import { ITeamListItem } from '../../../../types/components/molecules/teams'
import { IUserProfile } from '../../../../types/hooks/users'
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts'
import { GoalsActive } from '../../..'
import { API_URL } from '../../../../services'

const TeamListItem: FC<ITeamListItem> = (props: ITeamListItem) => {
    const [openDetail, setOpenDetail] = useState<boolean>(false);

    return (
        <Container fluid width={'100%'}>
            <Container inline width='100%' spacedBetween sx={{ cursor: 'pointer' }} onClick={() => setOpenDetail(!openDetail)}>
                <Container inline>
                    {!props.team.avatarUri && <TagFacesIcon style={{ fontSize: 60, marginRight: PADDING, color: colors[colors.length % props.index + 1 || 0] }} />}
                    {props.team.avatarUri && props.team.avatarUri != '' &&
                        <Avatar
                            src={`${API_URL}/v1_0/teams/images/${props.team.avatarUri}`}
                            style={{ marginRight: PADDING }}
                            sx={{ width: 60, height: 60 }}
                        />}
                    <Container fluid verticalCentered>
                        <Text size={16} bold>{props.team.description}</Text>
                        {props.team.members.length == 0 && <Text size={12} thin>Equipe vazia</Text>}
                        {props.team.members.length > 0 && <Text size={12} thin>{props.team.members.length} membros</Text>}
                    </Container>
                </Container>
                <Container fluid centered flexEnd padded>
                    {props.team.supervisors.length > 0 && <Text size={14} bold >Supervisor: {props.team.supervisors[0].surname}</Text>}
                    <Container inline flexEnd horizontalCentered width='100%'>
                        <Text size={12} thin>{!openDetail ? 'Expandir' : 'Contrair'}</Text>
                        <IconButton size="small" color="primary" onClick={() => setOpenDetail(!openDetail)}>
                            {!openDetail && <ExpandMoreIcon fontSize="small" />}
                            {openDetail && <ExpandLessIcon fontSize="small" />}
                        </IconButton>
                    </Container>
                </Container>
            </Container>

            <Collapse in={openDetail} timeout="auto" unmountOnExit>
                <Container fluid flexStart width="auto" color={theme.palette.grayLight.light} borderRadius={BORDER_RADIUS}>
                    <Container fluid padded>
                        <Link to={`/teams/${props.team.id}`}>
                            <Text size={14} bold color={theme.palette.info.light} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Detalhes da equipe</Text>
                        </Link>
                    </Container>

                    {props.team.supervisors.length == 0 &&
                        <Container inline horizontalCentered width="100%" flexStart padded>
                            <Text size={14} bold>Esta equipe ainda não possui supervisor</Text>
                        </Container>}

                    <Container fluid width="100%">

                        <Container inline width="100%" spacedBetween>
                            {props.team.supervisors.length == 0 && <Container inline horizontalCentered width="100%" flexStart padded />}
                            {props.team.supervisors.length > 0 && <Container inline horizontalCentered width="100%" flexStart padded>
                                {props.team.supervisors.length > 0 &&
                                    <UserCardBasic
                                        fontColor={theme.palette.common.black}
                                        avatarSize={60}
                                        user={{
                                            ...props.team.supervisors[0],
                                            surname: 'Supervisor: ' + props.team.supervisors[0].surname
                                        }} />
                                }
                            </Container>}
                            <Container inline horizontalCentered width="100%" flexEnd padded>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: theme.palette.tertiary.main }}
                                    startIcon={<PersonAddAlt1Icon />}
                                    onClick={() => props.onAddNewMember(props.team.id)}>
                                    <Text size={13} color={theme.palette.background.paper}>Adicionar membro</Text>
                                </Button>
                                <Spacing left={PADDING} />
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: theme.palette.tertiary.main }}
                                    onClick={() => props.onDeleteTeam(props.team.id)}>
                                    <Text size={13} color={theme.palette.background.paper}>Apagar equipe</Text>
                                </Button>
                            </Container>
                        </Container>

                        <Spacing top={PADDING} />
                        <Container inline padded >
                            <GoalsActive count={props.team.goalsStats.inProgress} />
                            <Spacing left={VERY_PADDING} />
                            <GoalsActive count={props.team.goalsStats.delayed} delayed />
                        </Container>

                        <Spacing top={PADDING} />
                        {props.team.members.length > 0 && <Text size={14} medium style={{ paddingLeft: PADDING }}>Membros (Clique em um membro para mais detalhes e opções):</Text>}

                        <Spacing top={PADDING} />
                        <Container inline width="100%" padded >
                            {props.team.members.length == 0 &&
                                <Container fluid width="100%">
                                    <Text size={14} medium>Esta equipe não possui nenhum membro ainda.</Text>
                                </Container>}
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                {props.team.members.map((item: IUserProfile, index: number) => (
                                    <Grid key={index} item xs={2} style={{ paddingTop: VERY_PADDING }}>
                                        <Container inline>
                                            <UserCardBasic
                                                fontColor={theme.palette.common.black}
                                                avatarSize={60}
                                                user={item}
                                                vertical
                                                isFirstName />
                                            <Spacing right={VERY_PADDING} />
                                        </Container>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </Container>
                </Container>
            </Collapse>
        </Container>
    );
}

export default TeamListItem;