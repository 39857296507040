import React, { FC, createContext, useCallback, useState, useContext } from 'react'

import api from '../services'
import { IUser, IAuthContext, IPosition, IPrivilege, IUserProfile, ITeam } from '../types'
import { REDSOFT_TOKEN, REDSOFT_USER } from '../plugins/localStorage.consts'
import {
    getGuardian, getPosition, getPrivilege, recoveryPassword,
    updatePasswordWithToken, fetchPasswordWithToken
} from '../services/auth';
import { createSession } from '../services/sessions';
import { getTeamsBySupervisorId } from '../services/teams';
import { IPasswordRecovery } from '../types/hooks/auth';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);


export const AuthProvider: FC<any> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [privilege, setPrivilege] = useState<IPrivilege | null>(null);
    const [position, setPosition] = useState<IPosition | null>(null);
    const [guardian, setGuardian] = useState<IUser | null>(null);
    const [supervisedTeams, setSupervisedTeams] = useState<ITeam[]>([]);
    const [token, setToken] = useState<string>('');
    const [loadingUserData, setLoadingUserData] = useState<boolean>(true);

    const clearSession = () => {
        setUser(null);
        setPrivilege(null);
        setPosition(null);
        setGuardian(null);
        setToken('');

        localStorage.clear();
    }

    const login = (user: IUser, token: string) => {
        localStorage.setItem(REDSOFT_USER, JSON.stringify(user))
        localStorage.setItem(REDSOFT_TOKEN, token)

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setUser(user);
        setToken(token);

        fetchUserData(user);
    }

    const logout = useCallback(async () => {
        clearSession()
        // navigate('/');
    }, []);

    const fetchUserData = async (_user: IUser | null) => {
        if (_user) {
            setLoadingUserData(true);
            let auxUser = _user;

            const userId = auxUser.id;
            const token = localStorage.getItem(REDSOFT_TOKEN)
            if (token)
                await createSession(userId, token);

            const promisePrivilege = new Promise(async function (resolve, reject) {
                try {
                    const privilege: IPrivilege = await getPrivilege(userId);
                    setPrivilege(privilege);
                    resolve(privilege);
                } catch (_err) {
                    resolve(false);
                }
            });
            const promisePosition = new Promise(async function (resolve, reject) {
                try {
                    const position: IPosition = await getPosition(userId);
                    setPosition(position);
                    resolve(position);
                } catch (_err) {
                    resolve(false);
                }
            });
            const promiseGuardian = new Promise(async function (resolve, reject) {
                try {
                    if (user) {
                        const guardian: IUser = await getGuardian(user.id);
                        setGuardian(guardian);
                        resolve(guardian);
                    }
                } catch (_err) {
                    resolve(true);
                }
            });
            const promiseSupervisedTeams = new Promise(async function (resolve, reject) {
                try {
                    if (user) {
                        const supervisedTeams: ITeam[] = await getTeamsBySupervisorId(user.id);
                        setSupervisedTeams(supervisedTeams);
                        resolve(supervisedTeams);
                    }
                } catch (_err) {
                    resolve(true);
                }
            })

            Promise.race([promisePrivilege, promisePosition, promiseGuardian, promiseSupervisedTeams])
                .then(function (res) {
                    setUser({ ...auxUser });

                    setTimeout(() => {
                        setLoadingUserData(false);
                    }, 100);
                });
        }
    }

    const updateUserProfile = (_profile: IUserProfile) => {
        let auxUser = user;
        if (auxUser) {
            auxUser.profile = _profile;

            setUser({ ...auxUser });
            localStorage.setItem(REDSOFT_USER, JSON.stringify(auxUser))
        }
    }

    const requestRecoveryPassword = async (_email: string) => {
        try {
            await recoveryPassword(_email);
        } catch (_err) {
            throw _err;
        }
    };

    const updateRecoveryPassword = async (_token: string, _newPassword: string) => {
        try {
            await updatePasswordWithToken(_token, _newPassword);
        } catch (_err) {
            throw _err;
        }
    }

    const fetchRecoveryPassword = async (_token: string) => {
        try {
            const data: IPasswordRecovery | undefined = await fetchPasswordWithToken(_token);
            if (data)
                return data;
            return undefined;
        } catch (_err) {
            throw _err;
        }
    }


    return (
        <AuthContext.Provider value={{
            token,
            user, guardian, supervisedTeams, position, privilege,
            login, logout,
            fetchUserData, loadingUserData, updateUserProfile,
            requestRecoveryPassword, updateRecoveryPassword, fetchRecoveryPassword
        }}>{children}</AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context;
}