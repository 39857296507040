import { stringify } from 'querystring';
import React, { FC, createContext, useState, useContext } from 'react'

import { IApprovalRequest, IGoal, IGoalContext, IGoalsStats, IGoalUser, ITask } from '../types'
import { deleteGoal, getGoalsStats, getGoalsByUserId, getGoalsByUser, getGoalById } from '../services/goals';


const GoalContext = createContext<IGoalContext>({} as IGoalContext);

export const GoalProvider: FC<any> = ({ children }) => {
    const [myGoals, setMyGoals] = useState<IGoal[]>([]);
    const [loadingMyGoals, setLoadingMyGoals] = useState<boolean>(true);
    const [stats, setStats] = useState<IGoalsStats>({
        inProgress: 0,
        delayed: 0
    });
    const [loadingStats, setLoadingStats] = useState<boolean>(true);

    const [goalsByUser, setGoalsByUser] = useState<IGoalUser[]>([]);
    const [loadingGoalsByUser, setLoadingGoalsByUser] = useState<boolean>(true);
    const [searchGoalByUser, setSearchGoalByUser] = useState<string>('')
    const [filterGoalByUser, setFilterGoalByUser] = useState<string>('2');
    const [paginateGoalByUser, setPaginateGoalByUser] = useState<number>(0);

    const fetchMyGoals = async (_userId: string) => {
        setLoadingMyGoals(true);
        try {
            const auxGoals: IGoal[] = await getGoalsByUserId(_userId);
            setMyGoals(auxGoals);

            setTimeout(() => {
                setLoadingMyGoals(false);
            }, 1000);

        } catch (_err) {
            setLoadingMyGoals(false);
        }
    }

    const fetchGoalsByUser = async () => {
        setLoadingGoalsByUser(true);
        try {
            const auxGoals: IGoalUser[] = await getGoalsByUser();
            setGoalsByUser(auxGoals);

            setTimeout(() => {
                setLoadingGoalsByUser(false);
            }, 1000);

        } catch (_err) {
            setLoadingGoalsByUser(false);
        }
    }

    const fetchGoalsStats = async () => {
        setLoadingStats(true);
        try {
            const auxStats: IGoalsStats = await getGoalsStats();
            setStats(auxStats);

            setTimeout(() => {
                setLoadingStats(false);
            }, 1000);

        } catch (_err) {
            setLoadingStats(false);
        }
    }

    const fetchGoal = async (_id: string) => {
        const goal = await getGoalById(_id);
        return goal;
    }

    const updateTask = (_task: ITask) => {
        const auxGoals: IGoal[] = myGoals;
        for (let i = 0; i < auxGoals.length; i++) {
            let progress = 0.0;
            for (let j = 0; j < auxGoals[i].tasks.length; j++) {
                if (auxGoals[i].tasks[j].id == _task.id)
                    auxGoals[i].tasks[j] = _task;
                progress += auxGoals[i].tasks[j].percentage / auxGoals[i].tasks.length;
            }

            auxGoals[i].progress = progress;
        }
        setMyGoals([...auxGoals])
    }

    const activeTasks = (_goal: IGoal) => {
        const auxActiveGoals: number = _goal.tasks.filter((item: ITask) => item.percentage < 100).length;
        return auxActiveGoals;
    }

    const updateApprovalRequest = (_goalId: string, _approvalRequest: IApprovalRequest) => {
        const auxGoals: IGoal[] = myGoals;
        for (let i = 0; i < auxGoals.length; i++)
            if (auxGoals[i].id == _goalId) {
                const auxApprovalsRequests: IApprovalRequest[] = [];
                for (let j = 0; j < auxGoals[i].approvalsRequests.length; j++) {
                    if (auxGoals[i].approvalsRequests[j].id !== _approvalRequest.id)
                        auxApprovalsRequests.push(auxGoals[i].approvalsRequests[j]);
                }
                auxApprovalsRequests.push(_approvalRequest);
                auxGoals[i].approvalsRequests = auxApprovalsRequests;
            }

        setMyGoals([...auxGoals])
    }

    const destroyGoal = async (_id: string) => {
        const deletedGoal = await deleteGoal(_id);

        const auxGoals: IGoal[] = [];
        for (let i = 0; i < myGoals.length; i++)
            if (myGoals[i].id !== _id)
                auxGoals.push(myGoals[i]);
        setMyGoals([...auxGoals]);
    }

    return (
        <GoalContext.Provider value={{
            myGoals, fetchMyGoals, loadingMyGoals,
            stats, fetchGoalsStats, loadingStats,

            goalsByUser, fetchGoalsByUser, loadingGoalsByUser,
            searchGoalByUser, setSearchGoalByUser, filterGoalByUser, setFilterGoalByUser,
            paginateGoalByUser, setPaginateGoalByUser,

            fetchGoal,

            destroyGoal, updateTask, activeTasks, updateApprovalRequest
        }}>{children}</GoalContext.Provider>
    );
}

export function useGoal() {
    const context = useContext(GoalContext);

    if (!context) {
        throw new Error('useGoal must be used within an GoalProvider')
    }

    return context;
}