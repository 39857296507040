import React, { FC } from 'react'
import { Button, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import Container from '../../atoms/container'
import Modal from '../../atoms/modal'
import Spacing from '../../atoms/spacing'
import Text from '../../atoms/text'
import Theme from '../../theme'
import { useAuth } from '../../../hooks/auth'
import { INewGoalTips } from '../../../types/components/molecules/modals'
import { BORDER_RADIUS } from '../../../utils/consts'

const NewGoalTips: FC<INewGoalTips> = (params: INewGoalTips) => {
    const { guardian } = useAuth();

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid color={Theme.palette.background.paper} hasShadow borderRadius={BORDER_RADIUS}>
                <Container inline color={Theme.palette.grayLight.main} centered>
                    <Container style={{ flexGrow: 1 }} />
                    <Text bold color={Theme.palette.primary.light}>Como montar seu Plano de Metas e Performance</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <IconButton size="small" style={{}} onClick={params.onClose}>
                        <CloseIcon fontSize="small" style={{ color: Theme.palette.primary.light }} />
                    </IconButton>
                </Container>

                <Container fluid centered veryPadded>
                    <Text bold>Veja a seguir dicas para criar o Plano ideal para você.</Text>
                    <Spacing top={10} />

                    <ul>
                        <li><Text>Descreva três atividades principais na Pix Force por ordem de prioridade;</Text></li>
                        <li><Text>Estabeleça uma meta para cada atividade;</Text></li>
                        <li><Text>Defina 3 objetivos com base nas metas, utilizando a metodologia SMART:</Text></li>
                        <Container fluid centered>
                            <Text color={Theme.palette.primary.light}>E<Text bold color={Theme.palette.primary.light}>S</Text>PECÍFICO - <Text bold color={Theme.palette.primary.light}>M</Text>ENSURÁVEL - <Text bold color={Theme.palette.primary.light}>A</Text>TINGÍVEL - <Text bold color={Theme.palette.primary.light}>R</Text>ELEVANTE - <Text bold color={Theme.palette.primary.light}>T</Text>EMPORAL</Text>
                        </Container>
                        <li><Text>Alinhe seus objetivos com as metas da Pix Force;</Text></li>
                        <li><Text>Entre na plataforma e preencha os dados de um novo plano de acordo com o elaborado;</Text></li>
                        <li><Text>Determine ações necessárias para cumprir cada objetivo, definindo um prazo de conclusão.</Text></li>
                    </ul>

                    <Spacing top={13} />
                    <Text bold>Agora você está pronto para criar seus objetivos,</Text>
                    <Text bold>vamos lá?</Text>
                </Container>

                {guardian == null &&
                    <Container fluid centered>
                        <Spacing top={10} />
                        <Text>Para criar um objetivo, solicite um tutor.</Text>
                    </Container>
                }

                <Container inline spacedAround veryPadded>
                    <Button variant="outlined" color="primary" size="small" style={{ borderColor: Theme.palette.primary.light, width: 200, height: 35 }}
                        onClick={params.onClose}>
                        <Text bold size={12} color={Theme.palette.primary.light}>Cancelar</Text>
                    </Button>
                    <Button disabled={params.disabled} variant="contained" size="small" style={{ backgroundColor: params.disabled ? Theme.palette.gray.main : Theme.palette.primary.light, width: 200, height: 35 }}
                        onClick={() => {
                            params.onClose();
                            params.onCreateNewGoal();
                        }}>
                        <Text size={12} color={Theme.palette.background.paper}>Criar novo objetivo</Text>
                    </Button>
                </Container>
            </Container>
        </Modal>
    )
}

export default NewGoalTips;