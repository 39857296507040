import React, { FC, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Close as CloseIcon, Delete as DeleteIcon, PersonAddAlt1 as PersonAddAlt1Icon, Remove as RemoveIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'

import Autocomplete from '../../../atoms/autocomplete'
import Container from '../../../atoms/container'
import Modal from '../../../atoms/modal'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import Input from '../../../atoms/input'
import { useAuth } from '../../../../hooks/auth'
import { useTeams } from '../../../../hooks/teams'
import { useUsers } from '../../../../hooks/users'
import theme from '../../../theme'
import { ITeam, ITeamNewModal, IUser, IUserProfile } from '../../../../types'
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts'
import { createUserHasTeam, deleteUserHasTeam } from '../../../../services/usersHasTeams'
import { convertBase64 } from '../../../../utils/converters'
import { updateTeamAvatar } from '../../../../services/teams'
import { createTeamHasSupervisor } from '../../../../services/teamsHasSupervisors'

const UsersNewModal: FC<ITeamNewModal> = (params: ITeamNewModal) => {
    const isEdit = params.teamToEdit != undefined;

    const { user } = useAuth();
    const { fetchTeams, createNewTeam, requestNewMember } = useTeams();
    const { users, fetchUsers } = useUsers();

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [supervisorId, setSupervisorId] = useState<string>('');
    const [supervisorIdError, setSupervisorIdError] = useState<string | null>(null);
    const [supervisorDefault, setSupervisorDefault] = useState<string | undefined>(undefined);
    const [memberId, setMemberId] = useState<string>('');
    const [membersId, setMembersId] = useState<string[]>([]);
    const [memberIdError, setMemberIdError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [avatarFilename, setAvatarFilename] = useState<string | null>(null);
    const [avatarSize, setAvatarSize] = useState<number | null>(null);
    const [avatarBase64, setAvatarBase64] = useState<string | null>(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (users.length > 0 && params.teamToEdit) {
            setName(params.teamToEdit.description);
            if (params.teamToEdit.supervisors.length > 0) {
                const surname: string | undefined = params.teamToEdit.supervisors[0].surname || undefined;
                const userId: string | undefined = params.teamToEdit.supervisors[0].userId;

                if (userId) setSupervisorDefault(surname);
            }

            if (!params.requestMember) {
                const auxMembersId: string[] = [];
                for (let i = 0; i < params.teamToEdit.members.length; i++) {
                    const userId: string | undefined = params.teamToEdit.members[i].userId;
                    if (userId != undefined)
                        auxMembersId.push(userId);
                }
                setMembersId(auxMembersId);
            }
        }
    }, [users, params.teamToEdit]);

    const onSelectAvatar = async (event: any) => {
        event.persist();

        const file = event.target.files[0];
        const avatarBase64 = await convertBase64(file);
        if (typeof avatarBase64 === 'string') {
            setAvatarFilename(file.name);
            setAvatarSize(file.size);
            const base64: string = avatarBase64.substring(avatarBase64.indexOf('base64') + 7, avatarBase64.length);
            setAvatarBase64(base64);
        }
    }

    const removeAvatar = () => {
        setAvatarBase64('');
        setAvatarFilename(null);
        setAvatarSize(null);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }

    const handleChangeSupervisorId = (event: React.ChangeEvent<any | HTMLInputElement> | string) => {
        if (typeof event === 'string')
            setSupervisorId(event);
        else if ((event as any).id) {
            setSupervisorId((event as any).id);
        }
        setSupervisorIdError(null);
        setError(null);
    }

    const handleChangeMemberId = (event: React.ChangeEvent<any | HTMLInputElement> | string) => {
        if (typeof event === 'string')
            setSupervisorId(event);
        else if ((event as any).id) {
            const auxMemberId = (event as any).id;
            const auxMembersId = membersId;
            if (!auxMembersId.find((item: string) => item == auxMemberId)) {
                auxMembersId.push(auxMemberId);
                setMembersId([...auxMembersId]);
            }
        }

        setSupervisorIdError(null);
        setError(null);
    }

    const removeMember = (_memberId: string) => {
        let auxMembersId: string[] = [];
        for (let i = 0; i < membersId.length; i++) {
            if (membersId[i] != _memberId)
                auxMembersId.push(membersId[i]);
        }
        setMembersId([...auxMembersId]);
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Insira o nome da equipe');
            isValid = false;
        }
        if (supervisorId == '' && params.teamToEdit == undefined) {
            setSupervisorIdError('Selecione o supervisor da equipe');
            isValid = false;
        }

        if (isValid) {
            setNameError(null);
            setSupervisorIdError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let teamId: string | undefined = undefined;

            if (params.requestMember) {
                let userSurname: string = 'Desconhecido';
                let teamName: string = 'Desconhecido';

                if (user && user.profile && user.profile.surname) userSurname = user.profile.surname;
                if (params.teamToEdit) teamName = params.teamToEdit.description;

                let memberNames: string[] = [];
                let positions: string[] = [];
                let departments: string[] = [];
                for (let i = 0; i < membersId.length; i++) {
                    const auxUser: IUser | undefined = users.find((item: IUser) => item.id == membersId[i]);
                    if (auxUser) {
                        if (auxUser && auxUser.profile && auxUser.profile.surname) memberNames.push(auxUser.profile.surname);
                        if (auxUser && auxUser.position) {
                            positions.push(auxUser.position.description);
                            departments.push(auxUser.position.departmentDescription);
                        }
                    }
                }
                await requestNewMember(userSurname, teamName, memberNames, positions, departments);
            }
            else if (isEdit && params.teamToEdit) {
                teamId = params.teamToEdit.id;
                for (let i = 0; i < params.teamToEdit.members.length; i++) {
                    const userId: string | undefined = params.teamToEdit.members[i].userId;
                    if (userId)
                        await deleteUserHasTeam(teamId, userId);
                }

                for (let i = 0; i < membersId.length; i++) {
                    const userId: string = membersId[i];
                    await createUserHasTeam(userId, params.teamToEdit.id);
                }

                if (supervisorId != '') {
                    await createTeamHasSupervisor(supervisorId, teamId);
                }
            }
            else {
                const newTeam: ITeam = await createNewTeam(name, supervisorId, membersId);
                teamId = newTeam.id;
            }

            if (teamId && avatarBase64 != null)
                await updateTeamAvatar(teamId, avatarBase64);

            fetchTeams();
            fetchUsers();

            const informationTitle: string = !params.requestMember ? 'Cadastro efetuado' : '';
            let informationMessage: string = !params.requestMember ? 'Informações alteradas com sucesso.' : 'Solicitação enviada com sucesso.';

            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        if (!params.requestMember) {
            setName('');
            setNameError(null);
            setSupervisorId('');
            setSupervisorIdError(null);
        }
        setMemberId('');
        setMembersId([]);
        setAvatarFilename(null);
        setAvatarSize(null);
        setAvatarBase64(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid color={theme.palette.background.default} hasShadow borderRadius={BORDER_RADIUS} centered
                sx={{ minWidth: '40%' }} >
                <Container inline color={theme.palette.grayLight.light} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container style={{ flexGrow: 1 }} />
                    <PersonAddAlt1Icon color="secondary" />
                    <Spacing left={PADDING} />
                    <Text size={16} bold color={theme.palette.primary.light}>{!isEdit ? 'Cadastrar nova equipe' : !params.requestMember ? 'Editar equipe' : 'Novo mebro'}</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <IconButton size="small" style={{}} onClick={close}>
                        <CloseIcon fontSize="small" style={{ color: theme.palette.primary.light }} />
                    </IconButton>
                </Container>

                <Text size={16} semiBold>{!isEdit ? 'Insira os dados da nova equipe' : !params.requestMember ? 'Edite os dados da equipe se precisar' : 'Solicite a inclusão do novo membro'}</Text>

                <Container className="user-new-modal ss-container" fluid spacedBetween centered veryPadded
                    width={'100%'}
                    height={'100%'}>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Nome</Text>
                            <Spacing top={5} />
                            <Input
                                id="name"
                                autoFocus
                                required
                                value={name}
                                onChange={handleChangeName}
                                placeHolder='Insira o nome da equipe'
                                error={nameError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading || params.teamToEdit != undefined || params.requestMember} />
                            {nameError && <Text size={13} color={theme.palette.error.main} >{nameError}</Text>}
                        </Container>
                    </Container>
                    {!params.requestMember && <Container inline width="100%" flexStart sx={{ paddingTop: PADDING }} horizontalCentered>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            loading={loading}
                            disabled={loading}
                            component="label"
                            style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.primary.light }}>
                            <Text size={13} color={theme.palette.background.paper} style={{}}>Atualizar imagem da equipe</Text>
                            <input
                                type='file'
                                id='upload-photo'
                                name='photo'
                                accept='image/png, image/jpeg'
                                onChange={onSelectAvatar}
                                hidden
                            />

                        </LoadingButton>

                        <IconButton size="small" color="error" onClick={() => removeAvatar()}>
                            <DeleteIcon sx={{ width: 21, height: 21 }} />
                        </IconButton>
                    </Container>}

                    {!params.requestMember && avatarFilename && <Container fluid flexStart width="100%">
                        <Text size={13} thin style={{ width: '80%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Arquivo selecionado: {avatarFilename}</Text>
                        {avatarSize && <Text size={13} thin style={{ width: '80%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Tamanho: {avatarSize} bytes</Text>}
                    </Container>}
                    <Spacing bottom={PADDING} />

                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Supervisor</Text>
                            <Spacing top={5} />
                            <Autocomplete
                                id="supervisor"
                                required
                                options={users.map(item => {
                                    return {
                                        label: item.profile.surname,
                                        id: item.id
                                    }
                                }) as any[]}
                                value={supervisorId}
                                defaultValue={supervisorDefault}
                                onChange={handleChangeSupervisorId}
                                placeHolder='Pesquise e selecione o supervisor da equipe'
                                error={supervisorIdError != null}
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading || params.requestMember}
                                autoFocus={supervisorId == ''}
                                requiredExists />
                            {supervisorIdError && <Text size={13} color={theme.palette.error.main} >{supervisorIdError}</Text>}
                        </Container>
                    </Container>
                    <Container sx={{ minWidth: '100%', paddingBottom: PADDING }}>
                        <Container fluid width={'100%'}>
                            <Text bold>Adicionar membro</Text>
                            <Spacing top={5} />
                            <Autocomplete
                                id="member"
                                required
                                options={!params.requestMember ? users.map(item => {
                                    return {
                                        label: item.profile.surname,
                                        id: item.id
                                    }
                                }) as any[] :
                                    users.filter((item: IUser) => {
                                        if (params.teamToEdit && params.teamToEdit.members.find((subItem: IUserProfile) => subItem.userId == item.id))
                                            return false;
                                        return true;
                                    }).map(item => {
                                        return {
                                            label: item.profile.surname,
                                            id: item.id
                                        }
                                    }) as any[]}
                                value={memberId}
                                onChange={handleChangeMemberId}
                                placeHolder='Pesquise e selecione o membro desejado'
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                disabled={loading}
                                requiredExists
                                autoFocus={supervisorId != '' && params.teamToEdit != undefined} />
                            {memberIdError && <Text size={13} color={theme.palette.error.main} >{memberIdError}</Text>}

                            {membersId.length > 0 && <Spacing top={VERY_PADDING} />}
                            {membersId.length > 0 && <Container fluid sx={{ maxHeight: 100, overflowY: 'auto', overflowX: 'hidden' }}
                                hasShadow color={theme.palette.grayLight.main} borderRadius={BORDER_RADIUS} padded>
                                {membersId.map((item: string, index: number) => {
                                    if (users.length == 0) return null;

                                    return (
                                        <Container key={index} inline width="100%" spacedBetween>
                                            <Text medium>{users.find((user: IUser) => user.id == item)?.profile.surname}</Text>
                                            <Container centered onClick={() => removeMember(item)} sx={{ cursor: 'pointer' }}>
                                                <Text size={12} medium color={theme.palette.error.main}>Remover</Text>
                                                <IconButton size="small" color="error">
                                                    <RemoveIcon fontSize="small" style={{ width: 15 }} />
                                                </IconButton>
                                            </Container>
                                        </Container>);
                                })}
                            </Container>}
                        </Container>
                    </Container>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.primary.light, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Enviar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default UsersNewModal;