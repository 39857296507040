import { IGoal } from "./goals";
import { IUser } from "./users";

interface IApprovalRequestNew {
  goalId?: string;
  userId?: string;
  request?: string;
  type?: number;
  responsibleId?: string;
  approved?: boolean;
}

interface IApprovalRequest {
  id: string;
  userId: string;
  responsibleId: string | null;
  request: string;
  type: number;
  approved: boolean;
  reply?: string;
  viewed: boolean;
  typeDesc: string;
  createdAt: string;
  updatedAt: string;

  user?: IUser;
  goal?: IGoal;
}

export function isApprovalRequest(_data: any): _data is IApprovalRequest {
  return 'request' in _data;
}

interface IApprovalRequestContext {
  requests: IApprovalRequest[];
  fetchRequests: () => void;
  loading: boolean;

  searchRequest: string;
  setSearchRequest: (_value: string) => void;
  filterStatus: string;
  setFilterStatus: (_value: string) => void;
  paginate: number;
  setPaginate: (_value: number) => void;

  updateApprovalRequest: (_id: string, _approved: boolean, _responsibleId: string) => void;
}

export type { IApprovalRequestNew, IApprovalRequest, IApprovalRequestContext };
