import React, { FC } from 'react'
import { Select as MuiSelect, MenuItem } from '@mui/material'

import Text from '../text'
import { ISelect, ISelectValue } from '../../../types'
import theme from '../../theme'

const Select: FC<ISelect> = (params: ISelect) => {
    return (
        <MuiSelect
            className='text-input'
            autoFocus={params.autoFocus}
            required={params.required}
            placeholder={params.placeHolder}
            value={params.value}
            onChange={params.onChange}
            disabled={params.disabled}
            disableUnderline
            displayEmpty
            variant="standard"
            size='small'
            style={{
                backgroundColor: params.backgroundColor || '#FAFAFA',
                border: 'solid 0px transparent',
                borderRadius: 6.91,
                boxShadow: '0px 4.60714px 4.60714px rgba(0, 0, 0, 0.25)',
            }}
            inputProps={params.inputProps}
            renderValue={params.value !== "" ? undefined : () => <Text color={params.fontColor || theme.palette.gray.main}>{params.placeHolder}</Text>}
            sx={{ ...params.sx }}
        >
            {params.items.map((item: ISelectValue, index: number) => (
                <MenuItem key={index} value={item.value} color={params.fontColor || undefined}
                    style={{ backgroundColor: params.backgroundColor || '#FAFAFA' }}>
                    <Text color={params.fontColor || undefined}>{item.label}</Text>
                </MenuItem>))}
        </MuiSelect>
    )
}

export default Select