import React from 'react'
import { Routes, Route } from 'react-router-dom';

import { ForgotPassword, Login, RecoveryPassword } from '../pages'

const AuthRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/password-recovery/:token" element={<RecoveryPassword />} />

            <Route path="*" element={<Login />} />
        </Routes>
    )
}

export default AuthRoutes;