import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import theme from '../../../theme';
import Modal from '../../../atoms/modal';
import Container from '../../../atoms/container';
import Text from '../../../atoms/text';
import Spacing from '../../../atoms/spacing';
import Input from '../../../atoms/input';
import { useAuth } from '../../../../hooks/auth';
import { checkPassword } from '../../../../services/auth';
import { IConfirmPasswordModal } from '../../../../types';
import { formatOnlyFirstName } from '../../../../utils/formatters';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';

const ConfirmPasswordModal: FC<IConfirmPasswordModal> = ({ isOpened, onClose, onConfirm }) => {
    const { user } = useAuth();
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    if (!user) return null;

    useEffect(() => {
        if (isOpened) {
            setCurrentPassword('');
        }
    }, [isOpened]);

    async function verifyPassword() {
        if (user) {
            try {
                const validPassword = await checkPassword(user.email, currentPassword);
                return validPassword;
            } catch (_err: any) {
                let message = _err;
                if (typeof message !== 'string') {
                    if (_err.response.data.message) {
                        message = _err.response.data.message;
                    } else {
                        message = 'Não foi possível verificar a senha';
                    }
                }
            }
        }
        return false;
    }

    const confirmPassword = async () => {
        setLoading(true);
        if (await verifyPassword()) onConfirm();
        setLoading(false);
    };

    return (
        <Modal isOpened={isOpened} onClose={onClose}>
            <Container fluid color={theme.palette.background.default} hasShadow borderRadius={BORDER_RADIUS} centered
                style={{ maxWidth: 500 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Confirme sua senha</Text>
                    </Container>
                </Container>

                <Container fluid spacedAround veryPadded centered>
                    <Spacing top={15} />
                    <Container fluid flexStart width="100%">
                        <Text light>{user.email}</Text>
                    </Container>
                    <Spacing top={5} />
                    <Text>
                        Para sua segurança, <span style={{ fontWeight: 'bold' }}>{user.profile.surname ? formatOnlyFirstName(user.profile.surname) : 'Desconhecido'}</span>, insira sua
                        senha para continuar.
                    </Text>
                </Container>


                <Grid container alignItems='center' justifyContent="center">
                    <Grid item xs={3} >
                        <Container inline>
                            <Spacing left={VERY_PADDING} />
                            <Text size={14}>Senha:</Text>
                        </Container>
                    </Grid>
                    <Grid item xs={9}>
                        <Container fluid width="80%">
                            <Input
                                id="passw"
                                required
                                autoFocus
                                value={currentPassword}
                                autoComplete={false}
                                onChange={(value: any) => setCurrentPassword(value.target.value)}
                                placeHolder='Insira a senha de acesso do usuário'
                                inputProps={{ disableUnderline: true, }}
                                backgroundColor={theme.palette.grayLight.light}
                                isPassword
                                showPassword={showPassword}
                                handleClickShowPassword={() => setShowPassword(!showPassword)} />
                        </Container>
                    </Grid>
                </Grid>


                <Spacing top={10} />
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={onClose}>
                        <Text bold size={12} color={theme.palette.primary.light}>Cancelar</Text>
                    </LoadingButton>
                    <Spacing left={30} />
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={loading}
                        disabled={loading}
                        style={{ backgroundColor: loading ? theme.palette.grayLight.main : theme.palette.primary.light, width: 150, height: 35 }}
                        onClick={confirmPassword}>
                        <Text bold size={12} color={theme.palette.background.paper}>Confirmar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    );
};

export default ConfirmPasswordModal;
